<h3 *ngIf="title" class="outline-select-header">
  {{ title }} <span class="hint">{{ hint }}</span>
</h3>
<div class="outline-select">
  <button
    [class.select-input-margin]="!noMargin"
    #selectBtn
    (focusout)="onFocusOutEvent()"
    (click)="handleClick(selectBtn)"
    class="outline-select-btn"
    [class.select-input-curved]="curvedStyle"
    [class.error]="!control.valid && submitted"
  >
    <span
      *ngIf="icon"
      style="color: #3b3b3b"
      class="select-input-prefix-icon material-icons-outlined"
    >
      {{ icon }}
    </span>
    <span *ngIf="!placeholder" class="selected-display">{{
      selectedOption?.display
    }}</span>
    <span
      class="selected-display"
      style="color: #3b3b3b"
      *ngIf="placeholder && !selectedOption?.display"
      >{{ placeholder }}</span
    >
    <span
      class="selected-display"
      *ngIf="placeholder && selectedOption?.display"
      >{{ selectedOption?.display }}</span
    >
    <span style="color: #9e9e9e" class="arrow material-icons-outlined">
      expand_less
    </span>
  </button>
  <ul
    [class.vis-hidden]="!opened"
    [class.drop-up]="dropUp"
    [class.drop-up-one-item]="dropUp && options.length === 1"
    class="outline-select-content"
    [class.set-height]="options.length > 3"
  >
    <li
      [class.clear-selection-item]="opt.onClick"
      [value]="opt.value"
      *ngFor="let opt of options"
      (mousedown)="emitSelect(opt)"
    >
      <a>{{ opt.display }}</a>
    </li>
    <li
      (mousedown)="clear()"
      *ngIf="clearable && selectedOption?.display"
      class="clear-selection-item"
    >
      <a>Clear</a>
    </li>
  </ul>
</div>
<p
  class="error-text"
  *ngIf="!control.valid && submitted && control.hasError('required')"
>
  This field is required
</p>
