import { CandidateProfile } from "src/app/candidate/models/candidate-profile.model";
import { RecruiterProfile } from "src/app/public/models/recruiter-jobs.model";
import { Job } from "src/app/recruiter/dashboard/pages/jobs/models/job.model";

export enum NotificationTypes {
  invite = "I",
  question = "Q",
  reply = "R",
  apply = "A",
}

export interface IRecruiterNotification {
  id: string;
  created_dt: string;
  is_seen: string;
  message: string;
  related_job: Job;
  related_app: string;
  seen_dt: string;
  title: string;
  type: string;
  from_candidate: CandidateProfile;
}

export interface ICandidateNotification {
  id: string;
  created_dt: string;
  is_seen: string;
  message: string;
  related_job: Job;
  related_app: string;
  seen_dt: string;
  title: string;
  type: string;
  from_recruiter: RecruiterProfile;
}
