import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeadlessLayoutComponent } from "./headless-layout.component";
import { RouterModule } from "@angular/router";
import { HeaderModule } from "../header/header.module";

@NgModule({
  declarations: [HeadlessLayoutComponent],
  imports: [CommonModule, RouterModule, HeaderModule],
  exports: [HeadlessLayoutComponent],
})
export class HeadlessLayoutModule {}
