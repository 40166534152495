import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { VideoForm } from "../../dashboard/pages/videos/forms/video-input-form";
import { VideoPlaylist } from "../../../core/models/video-api-payloads/video-playlist.model";
import {
  CandidateVideo,
  VideoPayload,
} from "../../../core/models/video-api-payloads/video.model";
import { PaginatedResponse } from "src/app/core/models/paginated-payload.model";
import { encodeParam } from "src/app/core/utils/paramEncoder";

@Injectable({
  providedIn: "root",
})
export class VideoService {
  baseUrl = environment.baseUrl + "candidate/api";
  constructor(private http: HttpClient) {}

  getVideos(params?: HttpParams) {
    if (params) {
      return this.http.get<PaginatedResponse<VideoPayload>>(
        `${this.baseUrl}/video`,
        {
          params: params,
        }
      );
    } else {
      return this.http.get<PaginatedResponse<VideoPayload>>(
        `${this.baseUrl}/video`
      );
    }
  }

  getPaginatedVideos(url: string) {
    return this.http.get<PaginatedResponse<VideoPayload>>(url);
  }

  getPlaylists(params?: HttpParams) {
    if (params) {
      return this.http.get<VideoPlaylist[]>(`${this.baseUrl}/playlist`, {
        params: params,
      });
    } else {
      return this.http.get<VideoPlaylist[]>(`${this.baseUrl}/playlist`);
    }
  }

  sharePlaylist(payload: object) {
    return this.http.post<VideoPlaylist>(
      `${this.baseUrl}/playlist/share/`,
      payload
    );
  }

  addEmailToSharedPlaylist(payload: object) {
    return this.http.put<VideoPlaylist>(
      `${this.baseUrl}/playlist/add_email_to_allow_list/`,
      payload
    );
  }

  deleteSharePlaylist(payload: object) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: payload,
    };
    return this.http.delete<VideoPlaylist>(
      `${this.baseUrl}/playlist/stop_sharing/`,
      options
    );
  }

  searchVideos(searchTerm: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("search", searchTerm);

    return this.http.get<PaginatedResponse<VideoPayload>>(
      `${this.baseUrl}/video`,
      {
        params: queryParams,
      }
    );
  }

  getVideo(pk: string) {
    return this.http.get<VideoPayload>(`${this.baseUrl}/video/${pk}`);
  }

  getPlaylist(pk: string) {
    return this.http.get<VideoPlaylist>(`${this.baseUrl}/playlist/${pk}`);
  }

  getPublicPlaylist(id: string, email?: string) {
    if (email) {
      let params = new HttpParams();
      params = params.set("payload", encodeParam(email));
      return this.http.get<VideoPlaylist>(
        `${this.baseUrl}/playlist/public/${id}`,
        { params }
      );
    } else {
      return this.http.get<VideoPlaylist>(
        `${this.baseUrl}/playlist/public/${id}`
      );
    }
  }

  postVideo(payload: VideoForm, id: string) {
    let formData = new FormData();
    let vidName = `${payload.getVideoTitle().value}_${
      payload.getVideo().value.name
    }`;
    let thumbnailName = `${payload.getVideoTitle().value}_${
      payload.getThumbnail().value.name
    }`;

    formData.append("video", payload.getVideo().value, vidName);
    formData.append("thumbnail", payload.getThumbnail().value, thumbnailName);
    formData.append("candidate_video", id);

    return this.http.post<CandidateVideo>(
      `${this.baseUrl}/upload-video/`,
      formData
    );
  }

  deleteVideo(id: string) {
    return this.http.delete(`${this.baseUrl}/video/${id}/`);
  }

  postVideoMetaData(payload: Object) {
    return this.http.post<VideoPayload>(`${this.baseUrl}/video/`, payload);
  }

  updateVideoMetaData(payload: Object, pk: string) {
    return this.http.put<VideoPayload>(`${this.baseUrl}/video/${pk}/`, payload);
  }

  createPlaylist(payload: Object) {
    return this.http.post<VideoPlaylist>(`${this.baseUrl}/playlist/`, payload);
  }

  updatePlaylist(payload: Object, id: string) {
    return this.http.put<VideoPlaylist>(
      `${this.baseUrl}/playlist/${id}/`,
      payload
    );
  }

  deletePlaylist(id: string) {
    return this.http.delete(`${this.baseUrl}/playlist/${id}/`);
  }

  addVideoToPlaylist(payload: Object) {
    return this.http.post<VideoPlaylist>(
      `${this.baseUrl}/playlist/addVideo/`,
      payload
    );
  }

  addVideosToPlaylist(payload: Object) {
    return this.http.post<VideoPlaylist>(
      `${this.baseUrl}/playlist/addVideos/`,
      payload
    );
  }

  removeVideoFromPlaylist(payload: Object) {
    return this.http.post<VideoPlaylist>(
      `${this.baseUrl}/playlist/removeVideo/`,
      payload
    );
  }

  makePlaylistIntro(payload: Object) {
    return this.http.post<VideoPlaylist>(
      `${this.baseUrl}/playlist/setIntro/`,
      payload
    );
  }
}
