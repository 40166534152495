import { Pipe, PipeTransform } from "@angular/core";
import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

@Pipe({
  name: "timeSince",
})
export class TimeSincePipe implements PipeTransform {
  transform(value: string | number | undefined): string {
    return dayjs(value).fromNow();
  }
}
