<div class="question-modal-container">
  <div class="modal-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 mat-dialog-title>Give feedback</h2>
    <mat-icon class="close-x" [mat-dialog-close]="false">close</mat-icon>
  </div>
  <div class="qa-modal-body">
    <p style="font-weight: 300; margin: 0px">
      Please give us your valuable feedback.
    </p>
    <app-outline-input-box
      [submitted]="false"
      [largeText]="true"
      placeholder="Enter your feedback here..."
      [control]="feedback"
    ></app-outline-input-box>

    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        (click)="postFeedback()"
        [disabled]="feedback.invalid"
        class="btn-md tw-btn-primary"
      >
        <mat-icon *ngIf="isSubmitting"
          ><mat-spinner id="spinner" diameter="20"> </mat-spinner
        ></mat-icon>
        Send
      </button>
    </div>
  </div>
</div>
