import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-main-content-header",
  templateUrl: "./main-content-header.component.html",
  styleUrls: ["./main-content-header.component.scss"],
})
export class MainContentHeaderComponent implements OnInit {
  @Output() sideNavToggle = new EventEmitter();
  @Input() header: string = "";

  constructor() {}

  ngOnInit(): void {}

  public onMenuClick = () => {
    this.sideNavToggle.emit();
  };
}
