<div class="page-content">
  <div class="notifications" *ngIf="recruiterNotificationCheck">
    <div *ngFor="let notification of recruiterNotifications">
      <div
        *ngIf="
          notification.type != notificationTypes.reply;
          else replyNotification
        "
        class="notification"
        [class.read]="notification.is_seen"
        [class.unread]="!notification.is_seen"
        (click)="
          markRecruiterNotificationRead(
            notification.id,
            notification.is_seen,
            notification
          )
        "
      >
        <span
          class="dot"
          [class.transparent]="notification.is_seen"
          [class.blue-dot]="!notification.is_seen"
        >
        </span>
        <img
          [src]="notification.from_candidate.profile_picture"
          alt=""
          class="avatar"
        />
        <div class="details">
          <p class="notification-content">
            <strong>{{ notification.from_candidate.name }}</strong> applied for
            <strong>{{ notification.related_job.job_title }}</strong> role in
            <strong>{{ notification.related_job.company }}</strong>
          </p>
          <button class="tw-btn-secondary btn-sm" (click)="gotoMyApplications()">
            View Applicants
          </button>
        </div>
        <div class="details2">
          <span class="notification-time">{{
            notification.created_dt | timeSince
          }}</span>
          <!-- <button mat-icon-button class="ellipsis" [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button> -->
          <span
            class="readButton"
            *ngIf="!notification.is_seen"
            (click)="
              markRecruiterNotificationRead(
                notification.id,
                notification.is_seen,
                notification
              )
            "
          >
            Mark as read
          </span>
          <!-- <span class="unreadButton" *ngIf="notification.is_seen">Mark as Unread</span> -->
        </div>
      </div>
      <ng-template #replyNotification>
        <div
          class="notification"
          [class.read]="notification.is_seen"
          [class.unread]="!notification.is_seen"
          (click)="
            markRecruiterNotificationRead(
              notification.id,
              notification.is_seen,
              notification
            )
          "
        >
          <span
            class="dot"
            [class.transparent]="notification.is_seen"
            [class.blue-dot]="!notification.is_seen"
          >
          </span>
          <img
            [src]="notification.from_candidate.profile_picture"
            alt=""
            class="avatar"
          />
          <div class="details">
            <p class="notification-content">
              <strong>{{ notification.from_candidate.name }}</strong> replied to
              your question!
            </p>
            <button
              class="tw-btn-secondary btn-sm"
              (click)="goToRQA(notification.related_app)"
            >
              Go to Q/A Portal
            </button>
          </div>
          <div class="details2">
            <span class="notification-time">{{
              notification.created_dt | timeSince
            }}</span>
            <!-- <button mat-icon-button class="ellipsis" [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button> -->
            <span
              class="readButton"
              *ngIf="!notification.is_seen"
              (click)="
                markRecruiterNotificationRead(
                  notification.id,
                  notification.is_seen,
                  notification
                )
              "
            >
              Mark as read
            </span>
            <!-- <span class="unreadButton" *ngIf="notification.is_seen">Mark as Unread</span> -->
          </div>
        </div>
      </ng-template>
      <!-- <mat-menu #menu="matMenu">
                <button mat-menu-item>View Notification
                </button>
                <button mat-menu-item *ngIf="!notification.is_seen"
                    (click)="markRecruiterNotificationRead(notification.id, notification.is_seen, notification)">Mark as Read</button>
                <button mat-menu-item *ngIf="notification.is_seen"
                    (click)="markRecruiterNotificationRead(notification.id, notification.is_seen, notification)">Mark as Unread</button>
                <button mat-menu-item>Delete Notification</button>
            </mat-menu> -->
    </div>
  </div>

  <div class="notifications" *ngIf="candidateNotificationCheck">
    <div *ngFor="let notification of candidateNotifications">
      <div
        *ngIf="
          notification.type != notificationTypes.question;
          else questionNotification
        "
        class="notification"
        [class.read]="notification.is_seen"
        [class.unread]="!notification.is_seen"
        (click)="
          markCandidateNotificationRead(
            notification.id,
            notification.is_seen,
            notification
          )
        "
      >
        <span
          class="dot"
          [class.transparent]="notification.is_seen"
          [class.blue-dot]="!notification.is_seen"
        >
        </span>
        <img
          [src]="notification.from_recruiter.profile_picture"
          alt=""
          class="avatar"
        />
        <div class="details">
          <p class="notification-content">
            <strong>{{ notification.from_recruiter.name }}</strong> invited you
            to apply for
            <strong>{{ notification.related_job.job_title }}</strong> at
            <strong>{{ notification.related_job.company }}</strong>
          </p>
          <button
            mat-stroked-button
            style="
              color: #23a8e1;
              border-radius: 107px;
              border: 1px solid var(--blue, #23a8e1);
              font-size: 13px;
            "
            (click)="gotoJob(notification.related_job)"
          >
            View Job
          </button>
        </div>
        <div class="details2">
          <span class="notification-time">{{
            notification.created_dt | timeSince
          }}</span>
          <!-- <button mat-icon-button class="ellipsis" [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button> -->
          <span
            class="readButton"
            *ngIf="!notification.is_seen"
            (click)="
              markCandidateNotificationRead(
                notification.id,
                notification.is_seen,
                notification
              )
            "
          >
            Mark as read
          </span>
          <!-- <span class="unreadButton" *ngIf="notification.is_seen">Mark as Unread</span> -->
        </div>
      </div>
      <ng-template #questionNotification>
        <div
          class="notification"
          [class.read]="notification.is_seen"
          [class.unread]="!notification.is_seen"
          (click)="
            markCandidateNotificationRead(
              notification.id,
              notification.is_seen,
              notification
            )
          "
        >
          <span
            class="dot"
            [class.transparent]="notification.is_seen"
            [class.blue-dot]="!notification.is_seen"
          >
          </span>
          <img
            [src]="notification.from_recruiter.profile_picture"
            alt=""
            class="avatar"
          />
          <div class="details">
            <p class="notification-content">
              <strong>{{ notification.from_recruiter.name }}</strong> asked you
              a question!
            </p>
            <button
              class="tw-btn-secondary btn-sm"
              (click)="goToQA(notification.related_app)"
            >
              Go to Q/A Portal
            </button>
          </div>
          <div class="details2">
            <span class="notification-time">{{
              notification.created_dt | timeSince
            }}</span>
            <!-- <button mat-icon-button class="ellipsis" [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu">
                        <mat-icon>more_horiz</mat-icon>
                    </button> -->
            <span
              class="readButton"
              *ngIf="!notification.is_seen"
              (click)="
                markCandidateNotificationRead(
                  notification.id,
                  notification.is_seen,
                  notification
                )
              "
            >
              Mark as read
            </span>
            <!-- <span class="unreadButton" *ngIf="notification.is_seen">Mark as Unread</span> -->
          </div>
        </div>
      </ng-template>
      <!-- <mat-menu #menu="matMenu">
                <button mat-menu-item>View Notification</button>
                <button mat-menu-item *ngIf="!notification.is_seen"
                    (click)="markCandidateNotificationRead(notification.id, notification.is_seen, notification)">Mark as Read</button>
                <button mat-menu-item *ngIf="notification.is_seen">Mark as Unread</button>
                <button mat-menu-item>Delete Notification</button>
            </mat-menu> -->
    </div>
  </div>
</div>
