import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, tap } from "rxjs";
import { AuthService } from "../../services/auth/auth.service";
import { LocalstorageService } from "../../services/local-storage/local-storage.service";
import { NotificationService } from "../../services/notification/notification.service";
import { STORAGE_ITEM } from "../../utils/local-storage.utils";
import { ROUTER_UTILS } from "../../utils/router.utils";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private location: Location,
    private notificationService: NotificationService,
    private localStorage: LocalstorageService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.isAuthenticated$.pipe(
      tap((loggedIn) => {
        if (!loggedIn) {
          this.authService.logout();
          this.notificationService.showNotification("Please Login");
          setTimeout(() => {
            this.location.back();
          }, 3000);
        } else {
          let role = this.authService.getUserRole();
          if (route.url[0].path != role) {
            if (role == this.authService.roles.candidate) {
              this.router.navigate([
                ROUTER_UTILS.candidate.root,
                ROUTER_UTILS.candidate.dashboard.home.root,
              ]);
            } else {
              this.router.navigate([
                ROUTER_UTILS.recruiter.root,
                ROUTER_UTILS.recruiter.dashboard.home.root,
              ]);
            }
          }
        }
      })
    );
  }
}
