import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
  selector: "[appVerticalScrollTracker]",
})
export class VerticalScrollTrackerDirective {
  @Output() scrolled = new EventEmitter();

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      this.scrolled.emit();
    }
  }
}
