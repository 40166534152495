<div class="page">
  <app-breadcrumb [showBackButton]="true" [items]="breadcrumb"></app-breadcrumb>
  <div *ngIf="!loading" class="applicant-profile-container">
    <div fxFlex="70" class="applicant-profile-card">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center">
          <img
            style="width: 60px; height: 60px; border-radius: 50%"
            [src]="applicant.profile_picture"
            alt=""
          />
          <div>
            <h4 style="margin-bottom: 5px">{{ applicant.name }}</h4>
            <span style="pointer-events: none" class="header-subtitle">
              Member since {{ applicant.created_dt | date }}
            </span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <!-- <button class="tw-btn white-btn">
            <span class="material-icons-outlined"> file_download </span>Download
            resume
          </button> -->
        </div>
      </div>
      <br />
      <mat-divider
        style="margin-left: -1rem; width: calc(100% + 2rem)"
      ></mat-divider>
      <br />
      <div>
        <video
          autoplay
          controls
          [src]="selectedVideo?.candidate_video?.video"
        ></video>

        <div fxLayout="row" fxLayoutGap="10px" style="overflow-x: scroll">
          <div class="video-card" *ngFor="let vid of applicant?.videos">
            <img
              (click)="select(vid)"
              style="width: 200px; height: auto; border-radius: 4px"
              [src]="vid.candidate_video.thumbnail"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="30" class="applicant-profile-sidebar">
      <div style="padding: 1rem">
        <span class="field-header">Overview</span>
      </div>
      <div style="padding: 1rem">
        <div *ngIf="applicant.industry" fxLayout="column">
          <span style="pointer-events: none" class="header-subtitle"
            >Field</span
          >
          <h3>{{ applicant.industry }}</h3>
        </div>
        <div *ngIf="applicant.experience" fxLayout="column">
          <span style="pointer-events: none" class="header-subtitle"
            >Experience</span
          >
          <h3>{{ applicant.experience }}</h3>
        </div>
        <div
          *ngIf="
            (applicant.city && applicant.city != 'null') ||
            (applicant.state && applicant.state != 'null') ||
            (applicant.country && applicant.country != 'null')
          "
          fxLayout="column"
        >
          <span style="pointer-events: none" class="header-subtitle"
            >Location</span
          >
          <h3>
            <span *ngIf="applicant.city && applicant.city != 'null'">
              {{ applicant.city }},
            </span>
            <span *ngIf="applicant.state && applicant.state != 'null'"
              >{{ applicant.state }},</span
            >
            <span *ngIf="applicant.country && applicant.country != 'null'">{{
              applicant.country
            }}</span>
          </h3>
        </div>
        <div *ngIf="applicant.languages" fxLayout="column">
          <span style="pointer-events: none" class="header-subtitle"
            >Language</span
          >
          <h3>{{ applicant.languages }}</h3>
        </div>
      </div>
      <mat-divider *ngIf="applicant.profile_summary"></mat-divider>
      <div *ngIf="applicant.profile_summary" style="padding: 1rem">
        <div fxLayout="column">
          <span style="pointer-events: none" class="header-subtitle"
            >Profile Summary</span
          >
          <h3>
            {{ applicant.profile_summary }}
          </h3>
        </div>
      </div>
      <div style="padding: 1rem">
        <!-- <div fxLayout="row" fxLayoutAlign="space-between">
          <button class="tw-btn full-width">Ask a question</button>
        </div> -->
      </div>
    </div>
  </div>
</div>
