<div class="qa-prompt-container">
  <h2 fxLayout="row" fxLayoutAlign="start center" class="qa-prompt-header">
    <span class="material-icons-outlined">error_outline</span>
    Important notice
  </h2>
  <!-- <mat-divider></mat-divider> -->
  <div class="qa-prompt-body">
    <ul>
      <li>
        The question is hidden at this stage. Once you see the question, you
        will have to submit an answer within the time limit.
      </li>
      <li>
        You will have {{ data.question.prep_time }} minutes to prepare yourself.
      </li>
      <li>
        You will have {{ data.question.num_tries_max }} attempts to answer the
        question.
      </li>
      <li>
        You video will be limited to
        {{ data.question.response_time_limit }} minutes, you will see a timer
        once you start recording.
      </li>
    </ul>

    <br />

    <div
      class="footer"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="end center"
    >
      <button mat-dialog-close class="btn-md tw-btn-secondary">Go Back</button>
      <button (click)="onCommit()" class="btn-md tw-btn-primary">
        Continue
      </button>
    </div>
  </div>
</div>
