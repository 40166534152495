import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { VideoPayload } from "src/app/core/models/video-api-payloads/video.model";
import { AddToPlaylistComponent } from "./dialog-components/add-to-playlist/add-to-playlist.component";
import { AddVideoToPlaylistComponent } from "./dialog-components/add-video-to-playlist/add-video-to-playlist.component";
import { IdleTimeoutComponent } from "./dialog-components/idle-timeout/idle-timeout.component";
import { QuestionComponent } from "./dialog-components/question/question.component";
import { ShareVideoComponent } from "./dialog-components/share-video/share-video.component";
import { VideoComponent } from "./dialog-components/video/video.component";
import { SideNavItem } from "../../models/sideNavItem";
import { FullscreenMenuComponent } from "./dialog-components/fullscreen-menu/fullscreen-menu.component";
import { QaConfirmationPromptComponent } from "./dialog-components/qa-confirmation-prompt/qa-confirmation-prompt.component";
import { Question } from "src/app/core/models/question.model";
import { IVideoObject } from "src/app/candidate/dashboard/pages/application-manager/pages/video-reply/video-reply.component";
import { QaVideoSelectComponent } from "./dialog-components/qa-video-select/qa-video-select.component";
import {
  IConfirmationPrompt,
  ConfirmationPromptComponent,
} from "./dialog-components/confirmation-prompt/confirmation-prompt.component";
import { Job } from "src/app/recruiter/dashboard/pages/jobs/models/job.model";
import { InviteApplicantComponent } from "./dialog-components/invite-applicant/invite-applicant.component";
import { MessageRecruiterComponent } from "./dialog-components/message-recruiter/message-recruiter.component";
import { FeedbackComponent } from "./dialog-components/feedback/feedback.component";
import { SharePlaylistComponent } from "./dialog-components/share-playlist/share-playlist.component";
import { VideoPlaylist } from "src/app/core/models/video-api-payloads/video-playlist.model";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  openIdleTimeOutDialog() {
    const dialogRef = this.dialog.open(IdleTimeoutComponent, {
      width: "50%",
      // data: { name: this.name, animal: this.animal },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openAddToPlaylistDialog(id?: string) {
    const dialogRef = this.dialog.open(AddToPlaylistComponent, {
      data: { id: id },
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  openShareVideoDialog(url: string) {
    const dialogRef = this.dialog.open(ShareVideoComponent, {
      data: { url: url },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openQuestionDialog() {
    const dialogRef = this.dialog.open(QuestionComponent, {
      width: "50%",
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  openMessageRecruiterDialog() {
    const dialogRef = this.dialog.open(MessageRecruiterComponent, {
      width: "50%",
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  openAddVideosToPlaylistDialog(id: string, selectedVids: VideoPayload[]) {
    const dialogRef = this.dialog.open(AddVideoToPlaylistComponent, {
      data: { id: id, videos: selectedVids },
      width: "100%",
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  openVideoDialog(videoUrl: string) {
    const dialogRef = this.dialog.open(VideoComponent, {
      data: { video: videoUrl },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openFullScreenMenu(items: SideNavItem[]) {
    const dialogRef = this.dialog.open(FullscreenMenuComponent, {
      data: {
        items: items,
      },
      width: "100vh",
      height: "100vh",
      maxWidth: "100vh",
      minHeight: "-webkit-fill-available;",
      hasBackdrop: false,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  openQAConfirmationPrompt(data: Question) {
    const dialogRef = this.dialog.open(QaConfirmationPromptComponent, {
      data: {
        question: data,
      },
      hasBackdrop: true,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  openFeedbackPrompt() {
    const dialogRef = this.dialog.open(FeedbackComponent, {
      width: "50%",
      hasBackdrop: true,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  openVideoSelectPrompt(data: IVideoObject[]) {
    const dialogRef = this.dialog.open(QaVideoSelectComponent, {
      data: {
        answers: data,
      },
      hasBackdrop: true,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  openConfirmationDialog(prompt: IConfirmationPrompt) {
    const dialogRef = this.dialog.open(ConfirmationPromptComponent, {
      data: {
        prompt: prompt,
      },

      width: "500px",
      height: "fit-content",
      autoFocus: false,
      hasBackdrop: true,
    });

    return dialogRef.afterClosed();
  }

  openInviteApplicantDialog(jobLink: URL) {
    const dialogRef = this.dialog.open(InviteApplicantComponent, {
      data: {
        job: jobLink,
      },
      hasBackdrop: true,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }

  openSharePlaylistDialog(playlist: VideoPlaylist) {
    const dialogRef = this.dialog.open(SharePlaylistComponent, {
      data: {
        playlist: playlist,
      },
      hasBackdrop: true,
      autoFocus: false,
    });

    return dialogRef.afterClosed();
  }
}
