import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ValidateUrl } from "../invite-applicant/invite-applicant.component";
import { VideoService } from "src/app/candidate/services/video/video.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { VideoPlaylist } from "src/app/core/models/video-api-payloads/video-playlist.model";
import { NotificationService } from "src/app/core/services/notification/notification.service";
import { constructSharePlaylistUrl } from "src/app/core/utils/router.utils";
import { Clipboard } from "@angular/cdk/clipboard";
import { encodeParam } from "src/app/core/utils/paramEncoder";

@Component({
  selector: "app-share-playlist",
  templateUrl: "./share-playlist.component.html",
  styleUrls: ["./share-playlist.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SharePlaylistComponent implements OnInit {
  shareForm: FormGroup;
  shareModeControl: FormControl;
  emailControl: FormControl;
  linkControl: FormControl = new FormControl("");
  isShared: boolean = false;
  sharedEmails: string[] = [];

  constructor(
    private videoService: VideoService,
    @Inject(MAT_DIALOG_DATA) public data: { playlist: VideoPlaylist },
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<SharePlaylistComponent>,
    private clipboard: Clipboard
  ) {
    this.shareModeControl = new FormControl("select");
    this.emailControl = new FormControl(
      "",
      Validators.compose([Validators.required, ValidateUrl])
    );
    this.shareForm = new FormGroup({
      shareMode: this.shareModeControl,
      email: this.emailControl,
    });
  }

  ngOnInit(): void {
    if (this.data.playlist.share_status) {
      if (this.data.playlist.share_status.mode === "public") {
        this.linkControl.setValue(this.getLink());
      }
      this.isShared = true;
    }
    this.shareModeControl.setValue(
      this.data.playlist.share_status?.mode || "public"
    );
    this.sharedEmails = this.data.playlist.share_status?.allow_list || [];
  }

  addEmail(): void {
    if (this.emailControl.valid) {
      if (this.isShared && this.data.playlist.share_status?.mode === "select") {
        let payload = {
          playlistId: this.data.playlist.id,
          mode: this.shareModeControl.value,
          email: this.emailControl.value,
        };
        this.videoService.addEmailToSharedPlaylist(payload).subscribe((res) => {
          this.sharedEmails.push(this.emailControl.value);
          this.emailControl.setValue("");
          this.notificationService.showNotification(
            "email added to shared list!"
          );
        });
      } else {
        this.sharedEmails.push(this.emailControl.value);
        this.emailControl.setValue("");
      }
    }
  }

  revoke(email: string): void {
    if (this.isShared) {
      let copy = this.sharedEmails.filter((e) => e != email);
      let payload = {
        playlistId: this.data.playlist.id,
        mode: this.shareModeControl.value,
        allowList: copy,
      };
      this.videoService.sharePlaylist(payload).subscribe((res) => {
        this.sharedEmails = this.sharedEmails.filter((e) => e != email);
        if (this.sharedEmails.length) {
          this.notificationService.showNotification(
            "Email removed from shared list!"
          );
        } else {
          this.notificationService.showNotification(
            "Playlist is no longer being shared!"
          );
          this.isShared = false;
        }
      });
    } else {
      this.sharedEmails = this.sharedEmails.filter((e) => e != email);
    }
  }

  share(): void {
    let payload = {
      playlistId: this.data.playlist.id,
      mode: this.shareModeControl.value,
      allowList:
        this.shareModeControl.value === "public" ? [] : this.sharedEmails,
    };
    this.videoService.sharePlaylist(payload).subscribe((res) => {
      this.notificationService.showNotification("Playlist shared!");
      this.linkControl.setValue(this.getLink());
      if (this.shareModeControl.value === "public") {
        this.sharedEmails = [];
      } else {
        this.linkControl.setValue("");
      }
      this.isShared = true;
    });
  }

  getLink(): string {
    let payload = {
      id: this.data.playlist.id,
    };
    return `${
      location.origin
    }/${constructSharePlaylistUrl()}?payload=${encodeParam(
      JSON.stringify(payload)
    )}`;
  }

  stopSharing(): void {
    let payload = {
      playlistId: this.data.playlist.id,
    };
    this.videoService.deleteSharePlaylist(payload).subscribe((res) => {
      this.notificationService.showNotification(
        "Playlist is no longer being shared!"
      );
      this.isShared = false;
      this.sharedEmails = [];
      this.linkControl.setValue("");
    });
  }

  copyLink() {
    this.clipboard.copy(this.getLink());
    this.notificationService.showNotification("copied!");
  }
}
