import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { ROUTER_UTILS } from "src/app/core/utils/router.utils";
import {
  ICandidateNotification,
  IRecruiterNotification,
  NotificationTypes,
} from "./notification.model";
import { UserNotificationsService } from "src/app/core/services/user-notification/user-notifications.service";
import { Router } from "@angular/router";
import { PaginatedResponse } from "src/app/core/models/paginated-payload.model";
import { encodeParam } from "src/app/core/utils/paramEncoder";
import { Job } from "src/app/recruiter/dashboard/pages/jobs/models/job.model";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsComponent {
  @Input() notificationsRecruiter!: IRecruiterNotification[];
  @Input() notificationsCandidate!: ICandidateNotification[];
  @Output() readCandidateNotification =
    new EventEmitter<ICandidateNotification>();
  @Output() readRecruiterNotification =
    new EventEmitter<IRecruiterNotification>();

  recruiterNotifications: IRecruiterNotification[] = [];
  candidateNotifications: ICandidateNotification[] = [];
  recruiterNotificationCheck = false;
  candidateNotificationCheck = false;
  notificationTypes = NotificationTypes;
  constructor(
    private notificationsService: UserNotificationsService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.recruiterNotifications = this.notificationsRecruiter;
    this.candidateNotifications = this.notificationsCandidate;

    if (this.recruiterNotifications) {
      this.recruiterNotificationCheck = true;
    }

    if (this.candidateNotifications) {
      this.candidateNotificationCheck = true;
    }
  }

  markRecruiterNotificationRead(
    id,
    status,
    notification: IRecruiterNotification
  ) {
    this.readRecruiterNotification.emit(notification);
  }

  markCandidateNotificationRead(
    id,
    status,
    notification: ICandidateNotification
  ) {
    this.readCandidateNotification.emit(notification);
  }

  gotoMyApplications() {
    this.router.navigate([
      ROUTER_UTILS.recruiter.root,
      ROUTER_UTILS.recruiter.dashboard.applicationManager.root,
      // ROUTER_UTILS.recruiter.dashboard.applicationManager.applicantList,
    ]);
  }

  gotoJob(job: Job) {
    this.router.navigate(
      [
        ROUTER_UTILS.candidate.root,
        ROUTER_UTILS.candidate.dashboard.jobs.root,
        ROUTER_UTILS.candidate.dashboard.jobs.jobDetail,
      ],
      {
        queryParams: { id: encodeParam(job.id.toString()) },
      }
    );
  }

  goToQA(app: string) {
    this.router.navigate(
      [
        ROUTER_UTILS.candidate.root,
        ROUTER_UTILS.candidate.dashboard.applicationManager.root,
        ROUTER_UTILS.candidate.dashboard.applicationManager.QAPortal,
      ],
      {
        queryParams: {
          id: encodeParam(app),
        },
      }
    );
  }

  goToRQA(app: string) {
    this.router.navigate(
      [
        ROUTER_UTILS.recruiter.root,
        ROUTER_UTILS.recruiter.dashboard.applicationManager.root,
        ROUTER_UTILS.recruiter.dashboard.applicationManager.QAPortal,
      ],
      {
        queryParams: {
          id: encodeParam(app),
        },
      }
    );
  }
}
