<nav aria-label="Breadcrumb" class="breadcrumb">
  <span
    (click)="back()"
    *ngIf="showBackButton"
    class="material-icons-outlined back-btn"
  >
    arrow_back
  </span>
  <ul>
    <li
      fxLayout="row"
      fxLayoutAlign="start center"
      *ngFor="let item of items; let i = index"
    >
      <a (click)="route(item)">{{ item.display }}</a>

      <span *ngIf="i !== items.length - 1" class="material-icons-outlined">
        chevron_right
      </span>
    </li>
  </ul>
</nav>
