import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { IVideoObject } from "src/app/candidate/dashboard/pages/application-manager/pages/video-reply/video-reply.component";
import { Question } from "src/app/core/models/question.model";

@Component({
  selector: "app-qa-video-select",
  templateUrl: "./qa-video-select.component.html",
  styleUrls: ["./qa-video-select.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QaVideoSelectComponent {
  selectedInd = -1;
  videoUrls: SafeUrl[] = [];
  constructor(
    public dialogRef: MatDialogRef<IVideoObject>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { answers: IVideoObject[] }
  ) {
    this.data.answers.map((answer) => {
      this.videoUrls.push(this.getVideoUrl(answer));
    });
  }

  onSelect(selectedAnswer: IVideoObject) {
    this.dialogRef.close(selectedAnswer);
  }

  public getImgUrl(answer: IVideoObject) {
    let objectURL = URL.createObjectURL(answer.thumbnail as unknown as Blob);
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  public getVideoUrl(answer: IVideoObject) {
    let objectURL = URL.createObjectURL(answer.video as unknown as Blob);
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  select(index: number) {
    if (this.selectedInd === index) {
      this.selectedInd = -1;
    } else {
      this.selectedInd = index;
    }
  }
  confirm() {
    this.dialogRef.close(this.selectedInd);
  }
}
