<div
  fxLayout="row wrap"
  fxLayout.lt-sm="column"
  fxLayoutGap="20px"
  fxLayoutAlign="flex-start"
>
  <ng-container *ngFor="let item of items">
    <div
      fxFlex="0 1 calc(33.3% - 20px)"
      fxFlex.lt-md="0 1 calc(50% - 20px)"
      fxFlex.lt-sm="100%"
    >
      <app-video-tile
        (videosUpdated)="updateVideos()"
        [item]="item"
      ></app-video-tile>
    </div>
  </ng-container>
</div>
