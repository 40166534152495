<div class="page">
  <app-breadcrumb [showBackButton]="true" [items]="breadcrumb"></app-breadcrumb>
  <div class="recruiter-profile-header">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div class="recruiter-profile-img-container">
        <img *ngIf="!loading" [src]="recruiter?.profile_picture" alt="" />
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px">
          <span class="recruiter-name">{{ recruiter?.name }}</span>
          <span class="recruiter-title">{{ recruiter?.company }}</span>
        </div>
      </div>
      <div style="display: flex; align-items: center; padding: 1.5rem">
        <button
          (click)="messageRecruiter()"
          *ngIf="!loading"
          class="tw-btn-secondary"
        >
          Message recruiter
        </button>
      </div>
    </div>

    <div class="recruiter-profile-header-grid">
      <ng-container *ngIf="!loading; else headerSkeleton"
        ><div>{{ postedJobsString }}</div>
        <div>
          {{ recruiter?.interviewee_count }}
          {{ candidateString }}
          Interviewing
        </div></ng-container
      >
      <ng-template #headerSkeleton
        ><div>
          <p class="text-skeleton"></p>
        </div>
        <div>
          <p class="text-skeleton"></p></div
      ></ng-template>
    </div>
  </div>
  <br />
  <app-job-search-field [form]="searchForm"></app-job-search-field>
  <div *ngIf="loading || jobs!.length; else noJobs">
    <div *ngIf="!loading; else loader" class="job-grid">
      <ng-container *ngIf="!searching; else loader">
        <app-job-card
          *ngFor="let job of jobs"
          [candidateMode]="true"
          [job]="job"
        ></app-job-card>
      </ng-container>
    </div>
    <mat-paginator
      class="paginator"
      [length]="count"
      [pageIndex]="pageIndex"
      [pageSize]="6"
      [hidePageSize]="true"
      aria-label="Select page"
      (page)="onPaginateChange($event)"
    >
    </mat-paginator>
  </div>

  <ng-template #loader>
    <app-job-grid-skeleton></app-job-grid-skeleton
  ></ng-template>

  <ng-template #noJobs>
    <app-empty-state
      [imgSource]="'../../../../../../../assets/img/jobs-empty-state.svg'"
      [heading]="'No Jobs posted yet'"
      [text]="'Try changing the filters or search parameters'"
    ></app-empty-state>
  </ng-template>
</div>
