import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { contsructURL } from "src/app/core/utils/router.utils";
import { SideNavItem } from "../../models/sideNavItem";
import { DialogService } from "../../services/dialog/dialog.service";

@Component({
  selector: "app-side-nav-item-list",
  templateUrl: "./side-nav-item-list.component.html",
  styleUrls: ["./side-nav-item-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SideNavItemListComponent implements AfterViewInit {
  @Input() sideNavController: any;
  @Input() isTab: boolean = false;
  @Input() btns: SideNavItem[] = [];

  selectedTab?: SideNavItem;
  iconFamily = {
    normal: "material-icons",
    outlined: "material-icons-outlined",
  };

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService
  ) {}
  ngAfterViewInit(): void {
    this.setActivatedRoute();
    this.router.events.subscribe(() => {
      this.setActivatedRoute();
    });
  }

  setActivatedRoute() {
    this.selectedTab = this.btns.find((btn) => {
      let urlWithoutParams = this.router.url.split("?")[0];
      if (contsructURL(btn.route).includes(urlWithoutParams.split("/")[2])) {
        return btn;
      } else {
        return null;
      }
    });

    this.cdr.detectChanges();
  }

  route(btn: SideNavItem, event: any) {
    this.router.navigate(btn.route);
    this.selectedTab = btn;
    this.cdr.detectChanges();
  }

  onFeedback(): void {
    this.dialogService.openFeedbackPrompt().subscribe();
  }
}
