<div [class.drawer-bg]="drawerService.opened | async" class="drawer">
  <div
    class="drawer-content"
    *ngIf="drawerService.opened | async"
    [@slideInOut]
  >
    <div class="drawer-header">
      <span>{{ header }}</span>
      <mat-icon
        (click)="drawerService.opened.next(false)"
        fontSet="material-icons"
        >close</mat-icon
      >
    </div>
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>
</div>
