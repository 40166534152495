import { Injectable, TemplateRef } from "@angular/core";
import { Subject } from "rxjs";

export interface IDrawerConfig {
  template: TemplateRef<any>;
  header: string;
}

@Injectable({
  providedIn: "root",
})
export class DrawerService {
  public config: Subject<IDrawerConfig> = new Subject();
  public opened: Subject<boolean> = new Subject();
  constructor() {}

  public show(config: IDrawerConfig): void {
    this.config.next(config);
    this.opened.next(true);
  }

  public close(): void {
    this.opened.next(false);
  }
}
