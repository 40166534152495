<div
  fxLayout="row wrap"
  fxLayout.lt-sm="column"
  fxLayoutAlign="flex-start"
  fxLayoutGap="20px"
>
  <ng-container *ngFor="let i of [].constructor(6)">
    <div
      fxFlex="0 1 calc(33.3% - 20px)"
      fxFlex.lt-md="0 1 calc(50% - 20px)"
      fxFlex.lt-sm="100%"
    >
      <app-tile-skeleton></app-tile-skeleton>
    </div>
  </ng-container>
</div>
