import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ROUTER_UTILS } from "../../utils/router.utils";
@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar, private router: Router) {}

  showNotification(message: string) {
    this.snackBar.open(message, "X", {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: "snackbar",
    });
  }

  customActionNotification(message: string, action: string) {
    return this.snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: "custom-error-snackbar",
    });
  }

  showErrorNotification(message: string) {
    this.snackBar.open(message, "X", {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: "error-snackbar",
    });
  }
}
