import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CandidateProfile } from "src/app/candidate/models/candidate-profile.model";
import { MessagingService } from "src/app/core/services/messaging/messaging.service";
import { NotificationService } from "src/app/core/services/notification/notification.service";
import { RecruiterProfile } from "src/app/recruiter/models/recruiter-profile.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FeedbackComponent implements OnInit {
  feedback: FormControl = new FormControl(
    "",
    Validators.compose([Validators.required])
  );
  isRecruiter: boolean = false;
  isCandidate: boolean = false;
  profile?: CandidateProfile | RecruiterProfile;
  isSubmitting: boolean = false;
  constructor(
    private http: HttpClient,
    private router: Router,
    private messagingService: MessagingService,
    public dialogRef: MatDialogRef<FeedbackComponent>,
    private notificationService: NotificationService
  ) {}
  ngOnInit(): void {
    if (this.router.url.includes("candidate")) {
      this.isCandidate = true;
      this.messagingService.getCandidateProfile().subscribe((res) => {
        this.profile = res;
      });
    } else {
      this.isRecruiter = true;
      this.messagingService.getRecruiterProfile().subscribe((res) => {
        this.profile = res;
      });
    }
  }

  postFeedback() {
    this.isSubmitting = true;
    this.http
      .post(environment.baseUrl + "auth/feedback/", {
        feedback: this.feedback.value,
        ...(this.isRecruiter && { user: this.profile?.id }),
        ...(this.isCandidate && { candidate: this.profile?.id }),
      })
      .subscribe({
        next: () => {
          this.notificationService.showNotification("Feedback sent");
          this.dialogRef.close();
        },
        error: () => {
          this.notificationService.showErrorNotification(
            "Please try again later."
          );
          this.dialogRef.close();
        },
      });
  }
}
