<div class="outline-field">
  <h3>Tags</h3>

  <span class="subtitle"
    >Tags make it easier for recruiters to find your videos!
  </span>

  <input
    [(ngModel)]="tag"
    class="outline-input"
    (keydown.enter)="add($event)"
    type="text"
    placeholder=""
  />
  <div
    fxLayout="row"
    style="width: 100%; overflow-x: scroll; overflow-y: hidden"
    fxLayoutGap="10px"
  >
    <mat-chip-list *ngFor="let tag of tags">
      <mat-chip (removed)="remove(tag)">
        {{ tag.name }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip></mat-chip-list
    >
  </div>
</div>
