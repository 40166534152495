import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor } from "./interceptors/jwt.interceptor";
import { ServerErrorInterceptor } from "./interceptors/server-error.interceptor";
import { CandidateCallbackComponent } from "./auth0-callbacks/candidate-callback/candidate-callback.component";
import { RecruiterCallbackComponent } from "./auth0-callbacks/recruiter-callback/recruiter-callback.component";

@NgModule({
  declarations: [CandidateCallbackComponent, RecruiterCallbackComponent],
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        "Core Module is already loaded, Import it in AppModule only"
      );
    }
  }
}
