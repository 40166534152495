import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  ICandidateNotification,
  IRecruiterNotification,
} from "src/app/shared/components/notifications/notification.model";
import { PaginatedResponse } from "../../models/paginated-payload.model";
import { BehaviorSubject, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserNotificationsService {
  private recruiterApiBaseUrl = `${environment.baseUrl}recruiter/api/`;
  private candidateApiBaseUrl = `${environment.baseUrl}candidate/api/`;
  public unseenCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  constructor(private http: HttpClient) {}

  getRecruiterNotifications() {
    return this.http
      .get<PaginatedResponse<IRecruiterNotification>>(
        this.recruiterApiBaseUrl + "notification"
      )
      .pipe(
        tap((res) => {
          this.unseenCount$.next(res.unseen_count as number);
        })
      );
  }

  getCandidateNotifications() {
    return this.http
      .get<PaginatedResponse<ICandidateNotification>>(
        this.candidateApiBaseUrl + "notification"
      )
      .pipe(
        tap((res) => {
          this.unseenCount$.next(res.unseen_count as number);
        })
      );
  }

  seenRecruiterNotification(id: string, notification: IRecruiterNotification) {
    return this.http.put<IRecruiterNotification>(
      this.recruiterApiBaseUrl + "notification/" + id + "/",
      notification
    );
  }

  seenCandidateNotification(id: string, notification: ICandidateNotification) {
    return this.http.put<ICandidateNotification>(
      this.candidateApiBaseUrl + "notification/" + id + "/",
      notification
    );
  }

  notificationRecruiterPaginator(url: string) {
    return this.http.get<PaginatedResponse<IRecruiterNotification>>(url);
  }

  notificationCandidatePaginator(url: string) {
    return this.http.get<PaginatedResponse<ICandidateNotification>>(url);
  }
}
