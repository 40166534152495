import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { VideoPlaylist } from "src/app/core/models/video-api-payloads/video-playlist.model";
import { MessagingService } from "src/app/core/services/messaging/messaging.service";
import { NotificationService } from "src/app/core/services/notification/notification.service";
import { VideoService } from "src/app/candidate/services/video/video.service";
import { DialogService } from "../../dialog.service";
import { Router } from "@angular/router";
import { ROUTER_UTILS } from "src/app/core/utils/router.utils";

@Component({
  selector: "app-add-to-playlist",
  templateUrl: "./add-to-playlist.component.html",
  styleUrls: ["./add-to-playlist.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddToPlaylistComponent implements OnInit {
  loading = true;
  createNewPlaylist = false;
  title = "";
  public = false;
  playlists: VideoPlaylist[] = [];
  constructor(
    private videoService: VideoService,
    private messagingService: MessagingService,
    private notificationService: NotificationService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { id: string }
  ) {}

  ngOnInit() {
    this.videoService.getPlaylists().subscribe((res) => {
      this.playlists = res;
      this.loading = false;
    });
  }

  checkIfVideoInPlaylist(playlist: VideoPlaylist) {
    return playlist.videos.some((video) => video.id == this.data.id);
  }

  createPlaylist() {
    let payload = {
      title: this.title,
      videos: [this.data.id],
      public: this.public,
    };

    this.videoService.createPlaylist(payload).subscribe(() => {
      this.notificationService.showNotification("Playlist Created");
    });
  }

  check(playlist: VideoPlaylist, checked: boolean) {
    let payload = {
      videoId: this.data.id,
      playlistId: playlist.id,
    };
    if (checked) {
      this.videoService
        .addVideoToPlaylist(payload)
        .subscribe(() =>
          this.notificationService.showNotification("Video Added to Playlist")
        );
    } else {
      this.videoService
        .removeVideoFromPlaylist(payload)
        .subscribe(() =>
          this.notificationService.showNotification(
            "Video Removed from Playlist"
          )
        );
    }
  }

  newPlaylist() {
    this.router.navigate([
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.playlist.root,
      ROUTER_UTILS.candidate.dashboard.playlist.createPlaylist,
    ]);
  }
}
