<div class="question-modal-container">
  <div class="modal-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 mat-dialog-title>Ask a Question</h2>
    <mat-icon class="close-x" [mat-dialog-close]="true">close</mat-icon>
  </div>
  <div class="qa-modal-body" [formGroup]="questionForm">
    <app-outline-input-box
      [submitted]="submitted"
      title="Question"
      [largeText]="true"
      [control]="questionControl"
    ></app-outline-input-box>
    <app-outline-select
      title="Prep time"
      hint="(minutes)"
      [submitted]="submitted"
      [options]="prepTimeOpts"
      [control]="prepTimeControl"
    ></app-outline-select>
    <app-outline-select
      title="Response time"
      hint="(minutes)"
      [submitted]="submitted"
      [options]="responseTimeOpts"
      [control]="responseTimeControl"
    ></app-outline-select>
    <app-outline-select
      [dropUp]="true"
      title="Max allowed attempts"
      [submitted]="submitted"
      [options]="attempsOpts"
      [control]="attemptsControl"
    ></app-outline-select>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button [mat-dialog-close]="true" class="tw-btn-negative">Cancel</button>
      <button (click)="closeDialog()" class="tw-btn-primary">Save</button>
    </div>
  </div>
</div>
