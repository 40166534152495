import { Component, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-message-recruiter",
  templateUrl: "./message-recruiter.component.html",
  styleUrls: ["./message-recruiter.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MessageRecruiterComponent {
  messageForm = new FormGroup({
    message: new FormControl("", Validators.compose([Validators.required])),
  });
  submitted = false;

  constructor(public dialogRef: MatDialogRef<MessageRecruiterComponent>) {}

  public get messageControl() {
    return this.messageForm.controls.message as FormControl;
  }

  send() {
    this.dialogRef.close(this.messageForm.value["message"]);
  }
}
