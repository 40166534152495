<div class="margin-top-60">
  <div class="app-navbar">
    <mat-toolbar class="upper-nav" color="white">
      <div class="logo-image-container">
        <div fxLayout="row" fxLayoutAlign="center center">
          <a href="#">
            <img src="../../../../assets/img/logo.png" class="logo-image" />
          </a>
          <a href="#">
            <img
              src="../../../../assets/img/workstory.io.png"
              class="logo-image"
            />
          </a>
        </div>
      </div>

      <div fxFlex fxLayout fxLayoutAlign="end">
        <button
          (click)="onNoClick()"
          color="white"
          fxHide.gt-xs="true"
          mat-icon-button
          color="white"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  </div>
  <br />
  <div fxLayout="column" class="button-column">
    <button
      (click)="btnRoute(btn)"
      *ngFor="let btn of btns"
      mat-raised-button
      id="side-nav-item"
    >
      <mat-icon fontSet="material-icons-outlined">{{ btn.icon }}</mat-icon>
      <p>
        {{ btn.btnText }}
      </p>
    </button>
  </div>
</div>
