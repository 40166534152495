import { ISelectOptions } from "src/app/shared/components/outline-select/outline-select.component";
import { Job } from "./models/job.model";

export const DefaultCompanyLogo =
  "https://ih1.redbubble.net/image.791961290.3946/st,small,507x507-pad,600x600,f8f8f8.jpg";
export let jobTypeMap = new Map<string, string>([
  ["F", "Full-Time"],
  ["T", "Temporary"],
  ["C", "Contract"],
  ["I", "Internship"],
]);
export let experienceLevelMap = new Map<string, string>([
  ["E", "Entry Level"],
  ["A", "Associate"],
  ["J", "Junior"],
  ["S", "Mid-Senior Level"],
  ["P", "Principal"],
  ["M", "Manager"],
  ["D", "Director"],
  ["V", "VP"],
  ["C", "C-Level"],
  ["O", "Other"],
]);

export let workTypeMap = new Map<string, string>([
  ["R", "Remote"],
  ["H", "Hybrid"],
  ["O", "On-site"],
]);

export let currencyChoiceMap = new Map<string, string>([
  ["USD", "\u{00024}"],
  ["CAD", "\u{00024}"],
  ["EUR", "\u{020AC}"],
  ["AUD", "\u{00024}"],
  ["GBP", "\u{000A3}"],
  ["YEN", "\u{000A5}"],
  ["PKR", "\u{020A8}"],
  ["INR", "\u{020B9}"],
]);

export const getExperienceLevel = (job: Job) => {
  return experienceLevelMap.get(job.experience_level);
};
export const getWorkType = (job: Job) => {
  return workTypeMap.get(job.work_type);
};
export const getJobType = (job: Job) => {
  return jobTypeMap.get(job.job_type);
};
export const getCurrencySymbol = (currency: string) => {
  return currencyChoiceMap.get(currency);
};

export const JOB_TYPE_CHOICES: ISelectOptions[] = [
  { value: "F", display: "Full-Time" },
  { value: "T", display: "Temporary" },
  { value: "C", display: "Contract" },
  { value: "I", display: "Internship" },
];

export const EXPERIENCE_LEVEL_CHOICES: ISelectOptions[] = [
  { value: "E", display: "Entry Level" },
  { value: "A", display: "Associate" },
  { value: "J", display: "Junior" },
  { value: "M", display: "Mid-Senior Level" },
  { value: "P", display: "Principal" },
  { value: "S", display: "Manager" },
  { value: "D", display: "Director" },
  { value: "V", display: "VP" },
  { value: "C", display: "C-Level" },
  { value: "O", display: "Other" },
];

export const CURRENCY_CHOICES: ISelectOptions[] = [
  { value: "USD", display: "USD" },
  { value: "EUR", display: "EUR" },
  { value: "GBP", display: "GBP" },
  { value: "CAD", display: "CAD" },
  { value: "YEN", display: "YEN" },
  { value: "PKR", display: "PKR" },
  { value: "AUD", display: "AUD" },
  { value: "INR", display: "INR" },
];

export const CURRENCY_SYMBOLS: Record<string, string> = {
  USD: "&#36;",
  EUR: "&#8364;",
  GBP: "&#163;",
  CAD: "",
  YEN: "",
  PKR: "",
  AUD: "",
  INR: "",
};

export const WORK_TYPE_CHOICES: ISelectOptions[] = [
  { value: "R", display: "Remote" },
  { value: "H", display: "Hybrid" },
  { value: "O", display: "On-site" },
];

export function DaysBetween(StartDate, EndDate): number {
  // The number of milliseconds in all UTC days (no DST)
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(
    EndDate.getFullYear(),
    EndDate.getMonth(),
    EndDate.getDate()
  );
  const end = Date.UTC(
    StartDate.getFullYear(),
    StartDate.getMonth(),
    StartDate.getDate()
  );

  // so it's safe to divide by 24 hours
  return Math.abs((start - end) / oneDay);
}
