import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Clipboard } from "@angular/cdk/clipboard";
import { NotificationService } from "src/app/core/services/notification/notification.service";

@Component({
  selector: "app-share-video",
  templateUrl: "./share-video.component.html",
  styleUrls: ["./share-video.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ShareVideoComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { url: string },
    private clipboard: Clipboard,
    private notificationService: NotificationService
  ) {}
  ngOnInit(): void {}

  copy() {
    this.clipboard.copy(this.data.url);
    this.notificationService.showNotification("copied!");
  }
}
