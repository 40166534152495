import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Question } from "src/app/core/models/question.model";

@Component({
  selector: "app-qa-confirmation-prompt",
  templateUrl: "./qa-confirmation-prompt.component.html",
  styleUrls: ["./qa-confirmation-prompt.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QaConfirmationPromptComponent {
  constructor(
    public dialogRef: MatDialogRef<QaConfirmationPromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { question: Question }
  ) {}

  onCommit() {
    this.dialogRef.close(true);
  }
}
