<mat-card [ngClass]="{ 'intro-video': video.isPlaylistIntro }">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <div fxLayout="row" fxLayoutGap="10px">
      <img class="curved-img" [src]="video.candidate_video.thumbnail" />
      <div fxLayout="column">
        <mat-card-title>{{ video.title }}</mat-card-title>
        <mat-card-subtitle>{{ video.description }}</mat-card-subtitle>
        <mat-card-subtitle>{{
          video.created_dt | timeSince
        }}</mat-card-subtitle>
      </div>
    </div>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="removeFromPlaylist(video.id)" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>Remove from playlist</span>
      </button>
      <button (click)="makeIntro(video.id)" mat-menu-item>
        <mat-icon>library_add</mat-icon>
        <span>Make Playlist Intro</span>
      </button>
    </mat-menu>
  </div>
</mat-card>
