import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { VideoPayload } from "src/app/core/models/video-api-payloads/video.model";
import { MessagingService } from "src/app/core/services/messaging/messaging.service";
import { VideoService } from "src/app/candidate/services/video/video.service";
import {
  constructShareVideoUrl,
  ROUTER_UTILS,
} from "src/app/core/utils/router.utils";
import { DialogService } from "src/app/shared/services/dialog/dialog.service";
import { encodeParam } from "src/app/core/utils/paramEncoder";
import { IConfirmationPrompt } from "src/app/shared/services/dialog/dialog-components/confirmation-prompt/confirmation-prompt.component";

@Component({
  selector: "app-video-tile",
  templateUrl: "./video-tile.component.html",
  styleUrls: ["./video-tile.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VideoTileComponent implements OnInit, OnChanges {
  @Input() item!: VideoPayload;
  @Input() selectedVideos: VideoPayload[] = [];
  @Input() selectMode: boolean = false;
  @Output() videosUpdated = new EventEmitter();
  @Output() selectEvent = new EventEmitter();

  selected = false;
  loading = true;
  constructor(
    private videoService: VideoService,
    private router: Router,
    private messagingService: MessagingService,
    private dialogService: DialogService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selected = this.selectedVideos.find((vid) => vid.id == this.item.id)
      ? true
      : false;
  }

  disableLoading() {
    this.loading = false;
  }

  ngOnInit() {}

  delete(event: Event) {
    event.stopPropagation();
    this.videoService.deleteVideo(this.item.id).subscribe({
      next: () => {
        this.videosUpdated.emit();
      },
    });
  }

  routeToDetail() {
    if (!this.selectMode) {
      this.router.navigate([
        ROUTER_UTILS.candidate.root,
        ROUTER_UTILS.candidate.dashboard.videos.root,
        this.item.id,
      ]);
    }
  }

  select() {
    if (this.selectMode) {
      this.selectEvent.emit(this.item);
      this.selected = this.selectedVideos.find((vid) => vid.id == this.item.id)
        ? true
        : false;
    }
  }

  makePrivate() {
    this.messagingService.emitLoader(true);
    let payload = {
      intro: this.item.intro,
      public: false,
      description: this.item.description,
      title: this.item.title,
      tags: this.item.tags,
    };

    this.videoService
      .updateVideoMetaData(payload, this.item.id)
      .subscribe((res) => {
        this.item = res;
        this.messagingService.emitLoader(false);
      });
  }

  makePublic() {
    this.messagingService.emitLoader(true);
    let payload = {
      intro: this.item.intro,
      public: true,
      description: this.item.description,
      title: this.item.title,
      tags: this.item.tags,
    };

    this.videoService
      .updateVideoMetaData(payload, this.item.id)
      .subscribe((res) => {
        this.item = res;
        this.messagingService.emitLoader(false);
      });
  }

  addToPlaylist() {
    this.dialogService.openAddToPlaylistDialog(this.item.id);
  }

  share() {
    if (this.item.public) {
      const noParamUrl = `${location.origin}/${constructShareVideoUrl()}`;
      const url = new URL(noParamUrl);
      let payload = {
        id: this.item.id,
        key: this.item.key,
      };

      url.searchParams.append("meta", encodeParam(JSON.stringify(payload)));
      this.dialogService.openShareVideoDialog(url.toString());
    }
  }

  prompt: IConfirmationPrompt = {
    heading : "Delete Video",
    promptMessage : "Are you sure want to delete this video?",
    actionButtonText : "Delete",
    action : "delete",
  };
  
  openDeleteConfirmationDialog(event: Event) {
    this.dialogService
      .openConfirmationDialog(
        this.prompt,
      )
      .subscribe((result) => {
        if (result) {
          this.delete(event);
        }
      });
  }
}
