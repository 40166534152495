import { MatDrawerMode } from "@angular/material/sidenav";

export function handleScreenResize(): IScreenResizeObj {
  if (window.innerWidth <= 1640 && window.innerWidth > 480) {
    return {
      opened: true,
      sideNavMode: "side",
      isTabWidth: true,
      isMobileWidth: false,
    };
  } else if (window.innerWidth <= 480) {
    return {
      opened: false,
      sideNavMode: "over",
      isTabWidth: false,
      isMobileWidth: true,
    };
  } else {
    return {
      opened: true,
      sideNavMode: "side",
      isTabWidth: false,
      isMobileWidth: false,
    };
  }
}

interface IScreenResizeObj {
  opened: boolean;
  sideNavMode: MatDrawerMode;
  isTabWidth: boolean;
  isMobileWidth: boolean;
}
