import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CandidateProfile } from "src/app/candidate/models/candidate-profile.model";
import { RecruiterProfile } from "src/app/recruiter/models/recruiter-profile.model";
import { Auth0UserModel } from "../../models/authentication-payloads/auth0-profile.model";
import { LoginPayload } from "../../models/authentication-payloads/login-payload.model";
import { VideoPayload } from "../../models/video-api-payloads/video.model";

@Injectable({
  providedIn: "root",
})
export class MessagingService {
  private userProfile = new BehaviorSubject<LoginPayload | undefined>(
    undefined
  );

  private recruiterProfile = new BehaviorSubject<RecruiterProfile | undefined>(
    undefined
  );

  private candidateProfile = new BehaviorSubject<CandidateProfile | undefined>(
    undefined
  );

  private profilePicture = new BehaviorSubject<string | undefined>(undefined);

  private videos = new BehaviorSubject<VideoPayload[] | undefined>(undefined);

  private loader = new BehaviorSubject<boolean | undefined>(true);

  constructor() {}

  getCandidateProfile() {
    return this.candidateProfile.asObservable();
  }

  emitCandidateProfile(profile: CandidateProfile) {
    this.candidateProfile.next(profile);
  }

  getRecruiterProfile() {
    return this.recruiterProfile.asObservable();
  }

  emitRecruiterProfile(profile: RecruiterProfile) {
    this.recruiterProfile.next(profile);
  }

  getVideos() {
    return this.videos.asObservable();
  }

  emitVideos(videos: VideoPayload[]) {
    this.videos.next(videos);
  }

  getProfilePicture() {
    return this.profilePicture.asObservable();
  }

  emitProfilePicture(profilePic: string) {
    this.profilePicture.next(profilePic);
  }

  getLoader() {
    return this.loader.asObservable();
  }

  emitLoader(value: boolean) {
    this.loader.next(value);
  }
}
