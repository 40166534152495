import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { encodeParam } from "src/app/core/utils/paramEncoder";
import { ROUTER_UTILS } from "src/app/core/utils/router.utils";
import {
  Application,
  JobApplication,
} from "src/app/recruiter/dashboard/pages/application-manager/models/application.model";
import {
  ApplicationStatusKeys,
  ContactStatusKeys,
} from "src/app/recruiter/dashboard/pages/application-manager/utils";

@Component({
  selector: "app-manager-card",
  templateUrl: "./app-manager-card.component.html",
  styleUrls: ["./app-manager-card.component.scss"],
})
export class AppManagerCardComponent implements OnInit {
  ngOnInit(): void {}
  @Input() application?: Application;
  @Input() job?: JobApplication;

  @Input() candidateMode: boolean = false;

  @Input() appViewMode: boolean = false;
  @Output() selectEvent = new EventEmitter();
  @Output() videoAnswerEvent = new EventEmitter();
  @Output() archiveEvent = new EventEmitter();
  @Output() shortlistEvent = new EventEmitter();
  @Output() qaEvent = new EventEmitter();
  @Output() seenEvent = new EventEmitter();

  @Output() viewApplicantsEvent = new EventEmitter();

  contactedStatus = ContactStatusKeys;
  appStatus = ApplicationStatusKeys;

  constructor(private router: Router) {}

  select() {
    this.selectEvent.emit(this.application);
  }

  linkedInProfile() {
    window.open(this.application?.linkedIn_url, "_blank");
  }

  resume() {
    window.open(this.application?.resume.resume, "_blank");
  }

  videoAnswer() {
    this.videoAnswerEvent.emit(this.application);
  }

  viewProfile() {
    this.router.navigate(
      [
        ROUTER_UTILS.recruiter.root,
        ROUTER_UTILS.recruiter.dashboard.applicationManager.root,
        ROUTER_UTILS.recruiter.dashboard.applicationManager.applicantProfile,
      ],
      {
        queryParams: {
          id: encodeParam(this.application?.candidate.id.toString() as string),
        },
      }
    );
  }

  viewJob() {
    this.router.navigate(
      [
        ROUTER_UTILS.recruiter.root,
        ROUTER_UTILS.recruiter.dashboard.jobs.root,
        ROUTER_UTILS.recruiter.dashboard.jobs.jobDetail,
      ],
      { queryParams: { id: encodeParam(this.job!.id.toString()) } }
    );
  }

  viewApplicants() {
    this.viewApplicantsEvent.emit(this.job);
  }

  archive() {
    this.archiveEvent.emit(this.application);
  }

  shortlist() {
    this.shortlistEvent.emit(this.application);
  }

  qaPortal() {
    this.qaEvent.emit(this.application);
  }
}
