import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { VideoPlaylist } from "src/app/core/models/video-api-payloads/video-playlist.model";
import { VideoPayload } from "src/app/core/models/video-api-payloads/video.model";
import { VideoService } from "src/app/candidate/services/video/video.service";

@Component({
  selector: "app-playlist-video-tile",
  templateUrl: "./playlist-video-tile.component.html",
  styleUrls: ["./playlist-video-tile.component.scss"],
})
export class PlaylistVideoTileComponent implements OnInit {
  @Input() video!: VideoPayload;
  @Input() playlist!: VideoPlaylist;
  @Output() videosUpdated = new EventEmitter();
  constructor(private videoService: VideoService) {}

  ngOnInit(): void {}

  removeFromPlaylist(id: string) {
    let payload = {
      videoId: id,
      playlistId: this.playlist?.id,
    };
    this.videoService.removeVideoFromPlaylist(payload).subscribe(() => {
      this.videosUpdated.emit();
    });
  }

  makeIntro(id: string) {
    let payload = {
      videoId: id,
      playlistId: this.playlist?.id,
    };
    this.videoService.makePlaylistIntro(payload).subscribe(() => {
      this.videosUpdated.emit();
    });
  }
}
