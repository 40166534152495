<div class="share-box-container">
  <div
    class="share-box-header"
    fxLayout="row"
    fxLayoutAlign="space-between start"
  >
    <h1 mat-dialog-title>{{ data.prompt.heading }}</h1>
  </div>

  <ng-container *ngIf="!data.prompt?.isResumeDelete; else resumeDelete">
    <div class="share-box-body">
      <span style="font-size: medium">{{ data.prompt.promptMessage }}</span>
      <div
        mat-dialog-actions
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <button class="btn-md tw-btn-secondary" mat-dialog-close cdkFocusInitial>
          Cancel
        </button>
        <button class="btn-md tw-btn-primary {{ data.prompt.action }} " (click)="onClick()">
          {{ data.prompt.actionButtonText }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-template #resumeDelete>
    <div class="resume-box-body">
      <span style="font-size: medium">{{ data.prompt.promptMessage }}</span>
      <div>
        <span style="font-size: small; color: #595959; font-weight: 300"
          >This resume is associated with
          <ng-container *ngIf="data.prompt.data.playlists.length"
            >{{ data.prompt.data.playlists.length }}
            {{
              data.prompt.data.playlists.length > 1 ? "playlists" : "playlist"
            }}</ng-container
          >
          <ng-container *ngIf="data.prompt.data.applications.length">
            &
            {{ data.prompt.data.applications.length }}
            {{
              data.prompt.data.applications.length > 1
                ? "applications"
                : "application"
            }}</ng-container
          >
        </span>
      </div>
      <br />
      <div
        mat-dialog-actions
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <button class="btn-md tw-btn-secondary" mat-dialog-close cdkFocusInitial>
          Cancel
        </button>
        <button class="btn-md tw-btn-primary {{ data.prompt.action }} " (click)="onClick()">
          {{ data.prompt.actionButtonText }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
