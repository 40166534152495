import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { STORAGE_ITEM } from "../../utils/local-storage.utils";
import { NotificationService } from "../notification/notification.service";
import { Inject, Injectable } from "@angular/core";
import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import {
  from,
  of,
  Observable,
  BehaviorSubject,
  combineLatest,
  throwError,
} from "rxjs";
import { tap, catchError, concatMap, shareReplay } from "rxjs/operators";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { LocalstorageService } from "../local-storage/local-storage.service";
import { ROUTER_UTILS } from "../../utils/router.utils";
import { MessagingService } from "../messaging/messaging.service";
import { TokenPayload } from "../../models/authentication-payloads/token-payload.model";
import { LoginPayload } from "../../models/authentication-payloads/login-payload.model";
import { decodeParam, encodeParam } from "../../utils/paramEncoder";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  userApiBaseUrl = environment.baseUrl + "auth/";

  roles = {
    recruiter: "recruiter",
    candidate: "candidate",
  };

  candidateRefreshTokenUrl =
    environment.baseUrl + "api/token/refreshCandidate/";
  recruiterRefreshTokenUrl =
    environment.baseUrl + "api/token/refreshRecruiter/";

  candidateAuth0CallBackUrl = `${this.document.location.origin}/${ROUTER_UTILS.auth0Callback.candidateCallback}`;
  recruiterAuth0CallBackUrl = `${this.document.location.origin}/${ROUTER_UTILS.auth0Callback.recruiterCallback}`;

  auth0LogoutUrl = `${this.document.location.origin}/${ROUTER_UTILS.portfolio.root}`;
  auth0Domain: string = environment.auth0Domain;
  auth0ClientId: string = environment.auth0ClientId;

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private localStorage: LocalstorageService,
    private messagingService: MessagingService
  ) {}

  auth0Client$ = (
    from(
      createAuth0Client({
        domain: this.auth0Domain,
        client_id: this.auth0ClientId,
        redirect_uri: this.candidateAuth0CallBackUrl,
      })
    ) as Observable<Auth0Client>
  ).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError((err) => throwError(() => new Error(err)))
  );

  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated()))
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );

  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();

  // Create a local property for login status
  loggedIn: boolean | null = null;

  getTokenSilently$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getTokenSilently()))
  );
  getIdTokenClaims$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getIdTokenClaims()))
  );

  // Create subject and public observable of access token
  private accessTokenSubject$ = new BehaviorSubject<string | null>(null);
  accessToken$ = this.accessTokenSubject$.asObservable();

  getUser$(options?: any): Observable<any> {
    return this.auth0Client$.pipe(
      // tap((options) => console.debug('get User',options)),
      concatMap((client: Auth0Client) => from(client.getUser(options)))
    );
  }

  localAuthSetup() {
    // This should only be called on app initialization
    // Check if user already has an active session with Auth0
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: any) => {
        if (loggedIn) {
          // If authenticated, return stream that emits user object and token
          return combineLatest([this.getUser$(), this.getTokenSilently$]);
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    const checkAuthSub = checkAuth$.subscribe((response: any[]) => {
      // If authenticated, response will be array of user object and token
      // If not authenticated, response will be 'false'
      // Set subjects appropriately
      if (response) {
        // console.debug('auth0 response', response)
        const user = response[0];
        const token = response[1];
        this.userProfileSubject$.next(user);
        this.accessTokenSubject$.next(token);
      }
      this.loggedIn = !!response;
      // Clean up subscription
      checkAuthSub.unsubscribe();
    });
  }

  candidateLogin(redirectPath: string = "/") {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists

    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in

      client.loginWithRedirect({
        redirect_uri: this.candidateAuth0CallBackUrl,
        appState: { target: redirectPath },
      });
    });
  }

  recruiterLogin(redirectPath: string = "/") {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists

    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: this.recruiterAuth0CallBackUrl,
        appState: { target: redirectPath },
      });
    });
  }

  handleRecruiterAuthCallback() {
    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0
    let targetRoute: string; // Path to redirect to after login processsed
    this.messagingService.emitLoader(true);
    // Ensure Auth0 client instance exists
    const authComplete$ = this.auth0Client$.pipe(
      // Have client, now call method to handle auth callback redirect
      concatMap(() => this.handleRedirectCallback$),
      tap((cbRes) => {
        // Get and set target redirect route from callback results
        console.debug("handle callback tap", cbRes);
        targetRoute =
          cbRes.appState && cbRes.appState.target ? cbRes.appState.target : "/";
      }),
      concatMap(() => {
        // Redirect callback complete; create stream returning
        // user data, token, and authentication status
        return combineLatest([
          this.getUser$(),
          this.getTokenSilently$,
          this.isAuthenticated$,
        ]);
      })
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user, token, and login status
    authComplete$.subscribe(([user, token, loggedIn]) => {
      // Update subjects and loggedIn property

      this.userProfileSubject$.next(user);
      this.accessTokenSubject$.next(token);
      this.localStorage.setItem(STORAGE_ITEM.auth0_token, token);

      this.loggedIn = loggedIn;

      if (!this.localStorage.getItem(STORAGE_ITEM.access_token)) {
        let payload = {
          username: user?.sub,
          email: user?.email,
          access_token: token,
          role: this.roles.recruiter,
        };
        this.isUserRegistered(payload).subscribe({
          next: (res) => {
            this.messagingService.emitProfilePicture(res.profile_picture);
            this.messagingService.emitRecruiterProfile(res);
            this.localStorage.setItem(
              STORAGE_ITEM.access_token,
              res.access_token
            );
            this.localStorage.setItem(
              STORAGE_ITEM.refresh_token,
              res.refresh_token
            );

            this.router.navigateByUrl(targetRoute);
            this.messagingService.emitLoader(false);
          },
          error: (err) => {
            this.messagingService.emitLoader(false);
            this.logout();
            console.log(err);
          },
        });
      } else {
        this.router.navigateByUrl(targetRoute);
        this.messagingService.emitLoader(false);
      }
    });
  }

  handleCandidateAuthCallback() {
    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0
    let targetRoute: string; // Path to redirect to after login processsed
    this.messagingService.emitLoader(true);
    // Ensure Auth0 client instance exists
    const authComplete$ = this.auth0Client$.pipe(
      // Have client, now call method to handle auth callback redirect
      concatMap(() => this.handleRedirectCallback$),
      tap((cbRes) => {
        // Get and set target redirect route from callback results
        console.debug("handle callback tap", cbRes);
        targetRoute =
          cbRes.appState && cbRes.appState.target ? cbRes.appState.target : "/";
      }),
      concatMap(() => {
        // Redirect callback complete; create stream returning
        // user data, token, and authentication status
        return combineLatest([
          this.getUser$(),
          this.getTokenSilently$,
          this.isAuthenticated$,
        ]);
      })
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user, token, and login status
    authComplete$.subscribe(([user, token, loggedIn]) => {
      // Update subjects and loggedIn property
      this.userProfileSubject$.next(user);
      this.accessTokenSubject$.next(token);
      this.localStorage.setItem(STORAGE_ITEM.auth0_token, token);

      this.loggedIn = loggedIn;

      if (!this.localStorage.getItem(STORAGE_ITEM.access_token)) {
        let payload = {
          username: user?.sub,
          email: user?.email,
          access_token: token,
          role: this.roles.candidate,
        };
        this.isUserRegistered(payload).subscribe({
          next: (res) => {
            this.messagingService.emitProfilePicture(res.profile_picture);
            this.messagingService.emitCandidateProfile(res);
            this.localStorage.setItem(
              STORAGE_ITEM.access_token,
              res.access_token
            );
            this.localStorage.setItem(
              STORAGE_ITEM.refresh_token,
              res.refresh_token
            );
            // this.messagingService.emitLoader(false);
            if (targetRoute.includes("/public/job")) {
              this.router.navigate(
                [
                  ROUTER_UTILS.candidate.root,
                  ROUTER_UTILS.candidate.dashboard.jobs.root,
                  ROUTER_UTILS.candidate.dashboard.jobs.jobDetail,
                ],
                {
                  queryParams: {
                    id: encodeParam(
                      JSON.parse(decodeParam(targetRoute.split("?meta=")[1]))[
                        "id"
                      ]
                    ),
                  },
                }
              );
            } else {
              this.router.navigateByUrl(targetRoute);
            }
            this.messagingService.emitLoader(false);
          },
          error: (err) => {
            this.messagingService.emitLoader(false);
            this.logout();
            console.log(err);
          },
        });
      } else {
        if (targetRoute.includes("/public/job")) {
          this.router.navigate(
            [
              ROUTER_UTILS.candidate.root,
              ROUTER_UTILS.candidate.dashboard.jobs.root,
              ROUTER_UTILS.candidate.dashboard.jobs.jobDetail,
            ],
            {
              queryParams: {
                id: encodeParam(
                  JSON.parse(decodeParam(targetRoute.split("?meta=")[1]))["id"]
                ),
              },
            }
          );
        } else {
          this.router.navigateByUrl(targetRoute);
        }

        this.messagingService.emitLoader(false);
      }
    });
  }

  logout(useRedirection: boolean = false) {
    let lang = this.localStorage.getItem(STORAGE_ITEM.langauge);
    this.localStorage.clear();
    this.localStorage.setItem(STORAGE_ITEM.langauge, lang as string);
    let redirectPath: string;
    if (useRedirection) {
      redirectPath = `${this.document.location.origin}/${
        ROUTER_UTILS.portfolio.root
      }?redirectUrl=${encodeURIComponent(this.router.url)}`;
    } else {
      redirectPath = `${this.document.location.origin}/${ROUTER_UTILS.portfolio.root}`;
    }
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: this.auth0ClientId,
        returnTo: redirectPath,
      });
    });
  }

  getIdToken() {
    // id token is the jwt token
    return this.accessTokenSubject$.getValue();
  }

  isUserRegistered(payload: Object) {
    return this.http.post<LoginPayload>(
      this.userApiBaseUrl + "login/",
      payload
    );
  }

  refreshToken(payload: object) {
    let access_token = payload["access"];
    let role = JSON.parse(atob(access_token.split(".")[1]))["role"];
    if (role == this.roles.candidate) {
      return this.http.post<TokenPayload>(
        this.candidateRefreshTokenUrl,
        payload
      );
    } else {
      return this.http.post<TokenPayload>(
        this.recruiterRefreshTokenUrl,
        payload
      );
    }
  }

  getUserRole() {
    let access_token = this.localStorage.getItem(
      STORAGE_ITEM.access_token
    ) as string;
    return JSON.parse(atob(access_token.split(".")[1]))["role"];
  }
}
