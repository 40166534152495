<mat-toolbar class="upper-nav" color="white">
  <!-- Side Nav Toggle Button Only Visible if sidenav hidden -->
  <div fxHide.gt-sm="true">
    <ul fxLayout fxLayoutGap="15px" class="navigation-items">
      <li>
        <button
          (click)="onMenuClick()"
          mat-icon-button
          aria-label="Example icon-button with a menu"
        >
          <mat-icon>menu</mat-icon>
        </button>
      </li>
    </ul>
  </div>
  <!-- Side Nav Toggle Button Only Visible if sidenav hidden -->

  <!-- Side Nav toggle Button Always Visible -->
  <!-- <div>
    <ul fxLayout fxLayoutGap="15px" class="navigation-items">
      <li>
        <button
          (click)="onMenuClick()"
          mat-icon-button
          aria-label="Example icon-button with a menu"
        >
          <mat-icon>menu</mat-icon>
        </button>
      </li>
    </ul>
  </div> -->
  <!-- Side Nav toggle Button Always Visible -->
  <div>
    <a routerLink="/home">{{ header }}</a>
  </div>
</mat-toolbar>
