<mat-card *ngIf="!candidateMode">
  <div *ngIf="application" fxLayout="row" fxLayoutAlign="space-between">
    <div style="flex-wrap: inherit; align-items: center" fxLayout="row">
      <img
        style="width: 60px; height: 60px; border-radius: 50%"
        [src]="application.candidate.profile_picture"
        alt=""
      />
      <div style="margin-left: 10px" fxLayout="column">
        <h4 style="margin-bottom: 5px">{{ application.candidate.name }}</h4>
        <p
          style="
            font-size: small;
            margin: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 80%;
          "
        >
          {{ application.additionalInfo }}
        </p>
        <p style="font-size: x-small; margin: 0px">
          Applied {{ application.created_dt | timeSince }}
        </p>
      </div>
    </div>

    <ng-container>
      <div *ngIf="!appViewMode; else appView" fxLayoutGap="10px" fxLayout="row">
        <div fxLayoutGap="5px" fxLayout="column">
          <button
            *ngIf="application.questions"
            (click)="qaPortal()"
            mat-raised-button
          >
            Replied to {{ application.responses }} /
            {{ application.questions }} Questions
          </button>
        </div>
        <div fxLayoutGap="5px" fxLayout="column">
          <button (click)="viewProfile()" mat-raised-button>
            View Profile
          </button>
          <button (click)="resume()" mat-raised-button>View Resume</button>
        </div>
        <div fxLayoutGap="5px" fxLayout="column">
          <div
            matTooltip="Candidate has not provided linkedin profile"
            [matTooltipDisabled]="application.linkedIn_url ? true : false"
            matTooltipPosition="above"
          >
            <button
              [disabled]="!application.linkedIn_url"
              (click)="linkedInProfile()"
              mat-raised-button
              style="width: 100%"
            >
              LinkedIn Profile
            </button>
          </div>
          <button
            [matBadgeHidden]="application.seen_dt"
            matBadgeSize="small"
            matBadge="&#8288;"
            matBadgeColor="warn"
            (click)="select()"
            mat-raised-button
          >
            View Application
          </button>
        </div>
      </div>
      <ng-template #appView>
        <div fxLayoutGap="10px" fxLayout="row">
          <div fxLayoutGap="5px" fxLayout="column">
            <button (click)="qaPortal()" mat-raised-button style="width: 100%">
              Q/A Portal
            </button>
            <div
              matTooltip="Candidate has not provided linkedin profile"
              [matTooltipDisabled]="application.linkedIn_url ? true : false"
              matTooltipPosition="below"
            >
              <button
                [disabled]="!application.linkedIn_url"
                (click)="linkedInProfile()"
                mat-raised-button
                style="width: 100%"
              >
                LinkedIn Profile
              </button>
            </div>
          </div>
          <div fxLayoutGap="5px" fxLayout="column">
            <button (click)="resume()" mat-raised-button>View Resume</button>
            <button (click)="viewProfile()" mat-raised-button>
              View Profile
            </button>
          </div>
          <div
            fxLayoutGap="5px"
            fxLayoutAlign="center space-between"
            fxLayout="column"
          >
            <mat-slide-toggle
              (click)="archive()"
              [checked]="application.status == appStatus.Archived"
              >Archive</mat-slide-toggle
            >

            <mat-slide-toggle
              (click)="shortlist()"
              [checked]="application.status == appStatus.Shortlisted"
              >Shortlist</mat-slide-toggle
            >
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <div *ngIf="job" fxLayout="row" fxLayoutAlign="space-between">
    <div style="flex-wrap: inherit; align-items: center" fxLayout="row">
      <img
        style="width: 60px; height: 60px"
        [src]="job.company_logo_url"
        alt=""
      />
      <div style="margin-left: 10px" fxLayout="column">
        <h4 style="margin-bottom: 5px">{{ job.company }}</h4>
        <p style="font-size: small; margin: 0px">{{ job.job_title }}</p>
        <!-- <p style="font-size: x-small">
          Applied {{ application.created_dt | timeSince }} for
          {{ application.job.job_title }} at {{ application.job.company }}
        </p> -->
      </div>
    </div>

    <ng-container>
      <div fxLayoutGap="10px" fxLayout="row">
        <div fxLayoutGap="5px" fxLayout="column">
          <button (click)="viewApplicants()" mat-raised-button>
            View Applicants
          </button>
        </div>
        <div fxLayoutGap="5px" fxLayout="column">
          <button disabled style="color: black" mat-raised-button>
            Total Applicants : {{ job.count }}
          </button>
          <button (click)="viewJob()" mat-raised-button>
            View Job Posting
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</mat-card>

<mat-card *ngIf="candidateMode">
  <div *ngIf="application" fxLayout="row" fxLayoutAlign="space-between">
    <div style="flex-wrap: inherit; align-items: center" fxLayout="row">
      <img
        style="width: 60px; height: 60px"
        [src]="application.job.company_logo_url"
        alt=""
      />
      <div style="margin-left: 10px" fxLayout="column">
        <h4 style="margin-top: 10px">{{ application.job.company }}</h4>
        <h4 style="margin-top: 10px">{{ application.job.job_title }}</h4>
        <p style="font-size: x-small">
          Applied {{ application.created_dt | timeSince }}
        </p>
      </div>
    </div>

    <ng-container>
      <div fxLayoutGap="10px" fxLayout="row">
        <div fxLayoutGap="5px" fxLayout="column">
          <ng-container *ngIf="!application.contacted; else Contacted">
            <ng-container *ngIf="!application.replied; else replied">
              <button
                *ngIf="application.seen_dt"
                disabled
                style="color: black"
                mat-raised-button
              >
                Seen {{ application.seen_dt | timeSince }}
              </button>

              <button
                *ngIf="!application.seen_dt"
                disabled
                style="color: black"
                mat-raised-button
              >
                Applied
              </button>
            </ng-container>
            <ng-template #replied>
              <button
                style="color: black"
                mat-raised-button
                (click)="videoAnswer()"
              >
                View Q/A History
              </button>
            </ng-template>
          </ng-container>
          <ng-template #Contacted>
            <button
              *ngIf="application.contacted && !application.replied"
              matBadgeSize="small"
              matBadge="&#8288;"
              matBadgeColor="warn"
              style="color: black"
              mat-raised-button
              (click)="videoAnswer()"
            >
              Recruiter has requested a video
            </button>
          </ng-template>
          <!-- <button [hidden]="true" mat-raised-button>View Application</button> -->
        </div>
      </div>
    </ng-container>
  </div>
</mat-card>
