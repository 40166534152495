import { Injectable } from "@angular/core";
import {
  FormControl,
  MinLengthValidator,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Tag } from "src/app/shared/components/video-form-components/video-tag/video-tag.component";

const form = {
  video: new UntypedFormControl("", Validators.compose([Validators.required])),
  thumbnail: new UntypedFormControl(
    "",
    Validators.compose([Validators.required])
  ),
  tags: new UntypedFormControl([]),
  public: new UntypedFormControl(false),
  intro: new UntypedFormControl(false),
  description: new UntypedFormControl(
    "",
    Validators.compose([Validators.required, Validators.maxLength(900)])
  ),
  title: new UntypedFormControl(
    "",
    Validators.compose([Validators.required, Validators.maxLength(250)])
  ),
};

@Injectable()
export class VideoForm extends UntypedFormGroup {
  videoLength: number = 0;
  constructor() {
    super(form);
  }

  getVideo() {
    return this.controls["video"];
  }

  setVideo(val: Blob) {
    this.controls["video"].setValue(val);
  }

  setThumbnail(val: Blob) {
    this.controls["thumbnail"].setValue(val);
  }

  getThumbnail() {
    return this.controls["thumbnail"];
  }

  getTags() {
    return this.controls["tags"] as FormControl;
  }

  setTags(val: Tag[]) {
    this.controls["tags"].setValue(val);
  }

  setPublic(val: Boolean) {
    this.controls["public"].setValue(val);
  }
  setIntro(val: Boolean) {
    this.controls["intro"].setValue(val);
  }

  getIsPublic() {
    return this.controls["public"];
  }

  getIsIntro() {
    return this.controls["intro"];
  }

  getDescription() {
    return this.controls["description"] as FormControl;
  }

  setDescription(val: string) {
    this.controls["description"].setValue(val);
  }

  getVideoTitle() {
    return this.controls["title"] as FormControl;
  }

  setVideoTitle(val: string) {
    this.controls["title"].setValue(val);
  }

  setDescriptionValidation() {
    this.controls["description"].setValidators(
      Validators.compose([Validators.required])
    );
    this.controls["description"].updateValueAndValidity();
  }

  removeDescriptionValidation() {
    this.controls["description"].clearValidators();
    this.controls["description"].updateValueAndValidity();
  }

  clear() {
    this.reset();
    this.setVideo("" as unknown as Blob);
    this.setThumbnail("" as unknown as Blob);
    this.setVideoTitle("");
    this.setTags([]);
    this.setDescription("");
    this.setIntro(false);
    this.setPublic(false);
  }
}
