<div class="add-to-playlist-container">
  <div class="add-to-playlist-header" fxLayout="row" fxLayoutAlign="space-between start">
    <h2 mat-dialog-title>Add To Playlist</h2>
    <mat-icon class="close-x" [mat-dialog-close]="true">close</mat-icon>
  </div>
  <div class="add-to-playlist-body">
    <div>
      <ul class="row" *ngIf="data.id && !createNewPlaylist">
        <li *ngFor="let playlist of playlists" class="flex max-w-md text-wrap">
          <mat-checkbox class="column left" style="font-size: 18px; margin-bottom: 10px;"
            (change)="check(playlist, $event.checked)" [checked]="checkIfVideoInPlaylist(playlist)">
            {{ playlist.title }}

          </mat-checkbox>
          <span class="material-icons-outlined column right"
            style="vertical-align: middle; margin-left: 5px;">
            lock
          </span>
        </li>
      </ul>

      <div *ngIf="createNewPlaylist || !data.id">
        <ul>
          <li>
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="title" />
            </mat-form-field>
          </li>
          <li>
            <mat-form-field appearance="fill">
              <mat-label>Privacy</mat-label>
              <mat-select [(value)]="public">
                <mat-option [value]="false">Private</mat-option>
                <mat-option [value]="true">Public</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
        </ul>
      </div>
    </div>

    <button *ngIf="!createNewPlaylist && data.id" (click)="newPlaylist()" mat-dialog-close
      class="btn-md tw-btn-secondary" style="align-self: center;">
      <span class="material-icons-outlined">
        add_circle
      </span>
      Create new playlist
    </button>

    <div fxLayout="row" fxLayoutAlign="end" *ngIf="createNewPlaylist || !data.id">
      <button class="tw-btn white-btn" mat-dialog-close>Cancel</button>
      <button class="tw-btn" (click)="createPlaylist()" [mat-dialog-close]="true" cdkFocusInitial>
        Save
      </button>
    </div>
  </div>
</div>