import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CandidateProfile } from "src/app/candidate/models/candidate-profile.model";
import { PaginatedResponse } from "src/app/core/models/paginated-payload.model";
import { environment } from "src/environments/environment";
import {
  Applicant,
  Application,
  JobApplication,
} from "../../models/application.model";

export interface IInviteApplicant {
  emails: string[];
  message: string;
  job: string;
  jobId: string;
}

@Injectable({
  providedIn: "root",
})
export class ApplicationManagerService {
  private applicationApiBaseUrl = `${environment.baseUrl}application/api/`;

  constructor(private http: HttpClient) {}

  getJobApplicants(id: string, param?: string) {
    if (param) {
      let params = new HttpParams();
      params = params.append("type", param);
      return this.http.get<JobApplication>(
        this.applicationApiBaseUrl + "job-applicants/" + id,
        { params: params }
      );
    } else {
      return this.http.get<JobApplication>(
        this.applicationApiBaseUrl + "job-applicants/" + id
      );
    }
  }

  inviteApplications(payload: IInviteApplicant) {
    return this.http.post(
      this.applicationApiBaseUrl + "applicants/invite/",
      payload
    );
  }

  getAllJobApplicants() {
    return this.http.get<PaginatedResponse<Applicant>>(
      this.applicationApiBaseUrl + "job-applicants/all"
    );
  }

  askQuestion(payload: Object) {
    return this.http.post<PaginatedResponse<JobApplication>>(
      this.applicationApiBaseUrl + "job-applicants/" + "question/",
      payload
    );
  }
  getJobs() {
    return this.http.get<PaginatedResponse<JobApplication>>(
      this.applicationApiBaseUrl + "job-applicants/"
    );
  }

  applicantPaginator(url: string) {
    return this.http.get<PaginatedResponse<Applicant>>(url);
  }

  getApplicantProfile(id: string) {
    return this.http.get<CandidateProfile>(
      this.applicationApiBaseUrl + "applicants/" + id
    );
  }

  getJobApplicant(id: string) {
    return this.http.get<Application>(
      this.applicationApiBaseUrl + "applicants/" + id
    );
  }

  appPaginator(url: string) {
    return this.http.get<PaginatedResponse<Application>>(url);
  }

  archive(id: string) {
    let payload = {
      id: id,
    };

    return this.http.post(
      this.applicationApiBaseUrl + "job-applicants/" + "archive/",
      payload
    );
  }

  seen(id: string) {
    let payload = {
      id: id,
    };

    return this.http.post(
      this.applicationApiBaseUrl + "job-applicants/" + "seen/",
      payload
    );
  }

  shortlist(id: string) {
    let payload = {
      id: id,
    };

    return this.http.post(
      this.applicationApiBaseUrl + "job-applicants/" + "shortlist/",
      payload
    );
  }
}
