<app-loader *ngIf="loading"></app-loader>
<router-outlet></router-outlet>
<app-drawer></app-drawer>

<!-- <mat-fab-menu
  id="languageSelector"
  color="primary"
  icon="language"
  [fabButtons]="languageSelectorOptions"
  (onFabMenuItemSelected)="switchLanguage($event)"
>
</mat-fab-menu> -->
