import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { VideoPlaylist } from "src/app/core/models/video-api-payloads/video-playlist.model";

@Component({
  selector: "app-playlist-grid-list",
  templateUrl: "./playlist-grid-list.component.html",
  styleUrls: ["./playlist-grid-list.component.scss"],
})
export class PlaylistGridListComponent implements OnInit {
  @Input() items: VideoPlaylist[] = [];
  @Output() playlistsUpdated = new EventEmitter();

  breakPoint?: number;
  ngOnInit() {
    this.handleSize(window.innerWidth);
  }

  passWidth(event: any) {
    this.handleSize(event.target.innerWidth);
  }

  handleSize(width: number) {
    if (width >= 1200) {
      this.breakPoint = 3;
    } else if (width < 1200 && width > 960) {
      this.breakPoint = 2;
    } else if (width < 960 && width > 580) {
      this.breakPoint = 2;
    } else if (width < 580) {
      this.breakPoint = 1;
    }
  }

  updatePlaylists() {
    this.playlistsUpdated.emit();
  }
}
