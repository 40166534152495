<div class="outline-field" [class.white-background]="whiteBackground">
  <h3
    matTooltipClass="custom-tooltip"
    [matTooltip]="tooltipText"
    matTooltipDisabled
    matTooltipPosition="right"
  >
    {{ title }}
    <span
      [matTooltip]="infoText"
      matTooltipPosition="above"
      *ngIf="infoText"
      style="font-size: small"
      class="material-icons-outlined"
    >
      info
    </span>
    <em
      ><font size="-1.5">{{ optional }}</font></em
    >
  </h3>

  <span class="subtitle">{{ hint }}</span>
  <input
    [formControl]="control"
    *ngIf="!largeText && !location"
    class="outline-input"
    [class.error]="!control.valid && submitted"
    [class.input-icon]="hasIcon"
    [type]="type"
    [placeholder]="placeholder"
    [matTooltip]="tooltipText"
    [matTooltipPosition]="tooltipPostion"
  />

  <ng-template [ngIf]="largeText && !location" #textArea>
    <textarea
      style="height: 100px"
      [formControl]="control"
      class="outline-input"
      [class.error]="!control.valid && submitted"
      type="text"
      rows="4"
      cols="50"
      [matTooltip]="tooltipText"
      [matTooltipPosition]="tooltipPostion"
      [placeholder]="placeholder"
    >
    </textarea>
    <p
      class="error-text"
      *ngIf="!control.valid && submitted && control.hasError('required')"
    >
      This field is required
    </p>
    <p
      class="error-text"
      *ngIf="!control.valid && submitted && control.hasError('maxlength')"
    >
      The maximum length for this field is
      {{ control.errors?.['maxlength'].requiredLength }} characters.
    </p>
  </ng-template>

  <ng-template [ngIf]="!largeText && location" #locationInput>
    <div
      fxlayout="row"
      class="location-editable-field"
      fxLayoutAlign=" center"
      [class.error]="!control.valid && submitted"
    >
      <app-location-autocomplete
        style="width: inherit"
        [oneLine]="true"
        [control]="control"
        [placeholder]="placeholder"
        (locationOut)="retrieveLocation($event)"
      ></app-location-autocomplete>
    </div>
  </ng-template>

  <ng-container *ngIf="!largeText">
    <p
      class="error-text"
      *ngIf="
        !control.valid &&
        submitted &&
        !control.hasError('required') &&
        control.hasError('invalidUrl')
      "
    >
      Please enter a valid url
    </p>
    <p
      class="error-text iconFix"
      *ngIf="!control.valid && control.hasError('invalidSalary')"
    >
      Invalid Range. Please recheck
    </p>
    <p
      class="error-text iconFix"
      *ngIf="!control.valid && control.hasError('negativeSalary')"
    >
      Salary cannot be negative. Please recheck
    </p>
    <p
      class="error-text"
      *ngIf="
        !control.valid &&
        submitted &&
        !control.hasError('required') &&
        control.hasError('invalidUrlList')
      "
    >
      Please enter a valid list of emails
    </p>
    <p
      class="error-text"
      *ngIf="!control.valid && submitted && control.hasError('required')"
    >
      This field is required
    </p>
    <p
      class="error-text"
      *ngIf="!control.valid && submitted && control.hasError('maxlength')"
    >
      The maximum length for this field is
      {{ control.errors?.['maxlength'].requiredLength }} characters.
    </p>
  </ng-container>
</div>
