import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { VideoPlaylist } from "src/app/core/models/video-api-payloads/video-playlist.model";
import { VideoService } from "src/app/candidate/services/video/video.service";
import { ROUTER_UTILS } from "src/app/core/utils/router.utils";
import { NotificationService } from "src/app/core/services/notification/notification.service";
import { DialogService } from "src/app/shared/services/dialog/dialog.service";
import { IConfirmationPrompt } from "src/app/shared/services/dialog/dialog-components/confirmation-prompt/confirmation-prompt.component";

@Component({
  selector: "app-playlist-tile",
  templateUrl: "./playlist-tile.component.html",
  styleUrls: ["./playlist-tile.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PlaylistTileComponent implements OnChanges, OnInit {
  @Input() item!: VideoPlaylist;
  @Input() selectMode: boolean = false;
  @Input() selectedPlaylist?: VideoPlaylist;
  @Output() selectEvent = new EventEmitter();
  @Output() playlistsUpdated = new EventEmitter();

  loading = true;
  selected = false;

  constructor(
    private router: Router,
    private videoService: VideoService,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {}
  ngOnInit(): void {
    this.disableLoadingSpecial();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selected = this.item.id == this.selectedPlaylist?.id;
  }

  disableLoading() {
    this.loading = false;
  }

  disableLoadingSpecial() {
    if (this.item?.videos.length < 1) {
      this.loading = false;
    }
  }

  routeToDetail() {
    if (!this.selectMode) {
      this.router.navigate([
        ROUTER_UTILS.candidate.root,
        ROUTER_UTILS.candidate.dashboard.playlist.root,
        this.item.id,
      ]);
    }
  }

  select() {
    if (this.selectMode) {
      this.selectEvent.emit(this.item);
    }
  }

  deletePlaylist() {
    this.videoService.deletePlaylist(this.item.id).subscribe(() => {
      this.playlistsUpdated.emit();
      this.notificationService.showNotification("Playlist Deleted!");
    });
  }

  addVideos() {
    this.dialogService
      .openAddVideosToPlaylistDialog(this.item.id!, this.item.videos)
      .subscribe((res) => {
        if (res) {
          this.playlistsUpdated.emit();
          this.notificationService.showNotification("Playlist Updated");
        }
      });
  }

  getVideosCount() {
    return `${this.item?.videos.length} ${
      this.item!.videos.length > 1 ? "videos" : "video"
    }`;
  }

  prompt: IConfirmationPrompt = {
    heading: "Delete Playlist",
    promptMessage: "Are you sure want to delete this playlist?",
    actionButtonText: "Delete",
    action: "delete",
  };

  openDeleteConfirmationDialog() {
    this.dialogService
      .openConfirmationDialog(this.prompt)
      .subscribe((result) => {
        if (result) {
          this.deletePlaylist();
        }
      });
  }

  editPlaylist() {
    this.router.navigate([
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.playlist.root,
      ROUTER_UTILS.candidate.dashboard.playlist.edit,
      this.item.id,
    ]);
  }

  sharePlaylist() {
    this.dialogService.openSharePlaylistDialog(this.item).subscribe(() => {
      this.playlistsUpdated.emit();
    });
  }
}
