import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

export interface IMetaDataTags {
  title: string;
  description: string;
}

@Injectable({
  providedIn: "root",
})
export class SeoService {
  private appTitle: string = "Workstory.io";
  private appDescription: string =
    "Share your Work Story. Create a video and introduce yourself to future employers using WorkStory.io.";
  private appLogoUrl: string = "https://app.workstory.io/assets/img/logo.png";
  private appLogoWidth: string = "1186";
  private appLogoheight: string = "751";

  constructor(private meta: Meta, private title: Title) {}

  public setDefaultTags(): void {
    this.title.setTitle(this.appTitle);
    this.meta.addTags([{ name: "robots", content: "index" }]);
    this.meta.updateTag({ name: "twitter:card", content: "workstory" });
    this.meta.updateTag({ name: "twitter:site", content: "@workstory.io" });
    this.meta.updateTag({ name: "twitter:title", content: this.appTitle });
    this.meta.updateTag({
      name: "twitter:description",
      content: this.appDescription,
    });
    this.meta.updateTag({ name: "twitter:image", content: this.appLogoUrl });

    this.meta.updateTag({ property: "og:type", content: "website" });
    this.meta.updateTag({ property: "og:site_name", content: "workstory.io" });
    this.meta.updateTag({ property: "og:title", content: this.appTitle });
    this.meta.updateTag({
      property: "og:description",
      content: this.appDescription,
    });
    this.meta.updateTag({ property: "og:image", content: this.appLogoUrl });
    this.meta.updateTag({
      property: "og:image:width",
      content: this.appLogoWidth,
    });
    this.meta.updateTag({
      property: "og:image:height",
      content: this.appLogoheight,
    });
  }

  public updateTags(config: IMetaDataTags): void {
    this.meta.updateTag({ name: "twitter:title", content: config.title });
    this.meta.updateTag({
      name: "twitter:description",
      content: config.description,
    });
    this.meta.updateTag({ property: "og:title", content: config.title });
    this.meta.updateTag({
      property: "og:description",
      content: config.description,
    });
  }
}
