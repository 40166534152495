import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth/auth.service";
import { LocalstorageService } from "../services/local-storage/local-storage.service";
import { STORAGE_ITEM } from "../utils/local-storage.utils";

@Injectable({
  providedIn: "root",
})
export class CloneHeaderService {
  constructor(
    private authService: AuthService,
    private localStorage: LocalstorageService
  ) {}

  cloneHeader = (request: HttpRequest<unknown>) => {
    let isLoginUrl = request.url.includes("/auth/login");
    let isLoggedIn = this.authService.loggedIn;
    if (isLoggedIn && !isLoginUrl) {
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${this.localStorage.getItem(
            STORAGE_ITEM.access_token
          )}`,
        },
      });
    }

    return request;
  };
}
