import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { VideoForm } from "src/app/candidate/dashboard/pages/videos/forms/video-input-form";

export interface Tag {
  name: string;
}

@Component({
  selector: "app-video-tag",
  templateUrl: "./video-tag.component.html",
  styleUrls: ["./video-tag.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VideoTagComponent implements OnInit {
  _form!: VideoForm;
  @Input() set form(value: VideoForm) {
    this._form = value;
    this.tags = this._form.getTags().value;
  }

  tag: string = "";

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  tags: Tag[] = [];

  ngOnInit(): void {}

  add(addedTag: Event): void {
    const value = ((addedTag.target as HTMLTextAreaElement).value || "").trim();

    if (value) {
      this.tags.push({ name: value });
    }

    this.tag = "";
    this._form.setTags(this.tags);
  }

  remove(tag: Tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
}
