<div class="share-playlist-container">
  <div class="share-playlist-header">
    <div style="display: flex; flex-direction: column; gap: 8px">
      <span class="field-header">Manage who can view this playlist</span>
      <span class="text-content">
        You can share it with individuals using their email or share it with
        everyone!
      </span>
    </div>
    <mat-icon class="close-x" [mat-dialog-close]="false">close</mat-icon>
  </div>
  <div *ngIf="isShared" class="share-playlist-body" style="padding-bottom: 0px">
    <div class="is-shared">
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
        "
      >
        <span
          class="material-icons-outlined"
          style="font-size: 14px; color: green"
        >
          adjust
        </span>
        <span class="text-content">This playlist is shared</span>
      </div>

      <button (click)="stopSharing()" class="cta tw-btn-negative font-normal py-0 px-0.5">Stop sharing</button>
    </div>
  </div>
  <div [formGroup]="shareForm" class="share-playlist-body">
    <mat-radio-group formControlName="shareMode" class="radio-button-row">
      <mat-radio-button
        (click)="shareModeControl.setValue('public')"
        value="public"
        >Share with everyone</mat-radio-button
      >
      <mat-radio-button
        (click)="shareModeControl.setValue('select')"
        value="select"
        >Select emails</mat-radio-button
      >
    </mat-radio-group>
    <div class="share-playlist-content">
      <ng-container
        [ngTemplateOutlet]="
          shareModeControl.value === 'public' ? public : select
        "
      ></ng-container>
      <br />
    </div>
  </div>
  <div
    *ngIf="shareModeControl.value === 'public'; else selectFooter"
    class="share-playlist-footer input-field-row-button"
  >
    <app-outline-input-box
      style="width: 80%"
      [whiteBackground]="false"
      [largeText]="false"
      [control]="linkControl"
      placeholder=""
    ></app-outline-input-box>
    <button
      *ngIf="!linkControl.value"
      (click)="share()"
      class="tw-btn-secondary"
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          text-wrap: nowrap;
          gap: 2px;
        "
      >
        <span> Generate </span>

        <span style="font-size: 16px" class="material-icons-outlined">
          link
        </span>
      </div>
    </button>
    <button
      *ngIf="linkControl.value"
      (click)="copyLink()"
      class="tw-btn-secondary btn-sm"
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          text-wrap: nowrap;
          gap: 2px;
        "
      >
        <span> Copy link </span>

        <span style="font-size: 16px" class="material-icons-outlined">
          link
        </span>
      </div>
    </button>
  </div>
</div>

<ng-template #select>
  <div class="select-share-mode">
    <div class="input-field-row-button">
      <app-outline-input-box
        style="width: 80%"
        [whiteBackground]="false"
        [largeText]="false"
        placeholder="Enter email"
        [control]="emailControl"
      ></app-outline-input-box>
      <button
        [class.share-playlist-disabled-btn]="!emailControl.valid"
        (click)="addEmail()"
        class="tw-btn-secondary justify-center"
      >
        Add
      </button>
    </div>
    <mat-divider style="margin-bottom: 8px"></mat-divider>
    <ng-container *ngIf="sharedEmails.length; else noEmails">
      <span class="text-content">Shared with</span>
      <div class="shared-list-playlist">
        <div class="shared-email-content" *ngFor="let email of sharedEmails">
          <span style="font-weight: 300">{{ email }}</span>
          <button
            (click)="revoke(email)"
            style="line-height: normal !important"
            class="tw-btn-negative btn-sm mini-button"
          >
            Revoke
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #noEmails>
      <div class="share-playlist-empty-state-container">
        <span style="font-size: 24px" class="material-icons-outlined">
          email
        </span>
        <span class="text-content">Add emails to share this playlist to.</span>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #public>
  <div class="select-share-mode">
    <span class="text-content"
      >Everyone will be able to see all to see the videos and resume attached to
      this playlist.</span
    >
  </div>
</ng-template>

<ng-template #selectFooter>
  <div class="share-playlist-footer input-field-row-button">
    <button
      (click)="share()"
      style="width: 100% !important"
      class="tw-btn-primary justify-center"
      [class.share-playlist-disabled-btn]="!sharedEmails.length"
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          text-wrap: nowrap;
          gap: 2px;
        "
      >
        <span> Share playlist </span>

        <span style="font-size: 16px" class="material-icons-outlined">
          link
        </span>
      </div>
    </button>
  </div>
</ng-template>
