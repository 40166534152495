<div class="invite-applicant-container">
  <h2
    fxLayout="row"
    fxLayoutAlign="start center"
    class="invite-applicant-header"
  >
    Invite Applicant
  </h2>
  <div class="invite-applicant-body">
    <app-outline-input-box
      [submitted]="emailChanged"
      [hint]="'Supports comma separated list of emails for mass invites'"
      [whiteBackground]="false"
      title="Email address"
      [control]="email"
    ></app-outline-input-box>
    <app-outline-input-box
      [submitted]="messageChanged"
      [whiteBackground]="false"
      title="Message"
      [largeText]="true"
      [control]="message"
    ></app-outline-input-box>
    <div
      class="footer"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="end center"
    >
      <button mat-dialog-close class="tw-btn-secondary">Go Back</button>
      <button (click)="onInvite()" class="tw-btn-primary">
        <mat-icon *ngIf="inviting"
          ><mat-spinner diameter="20"> </mat-spinner
        ></mat-icon>
        Invite
      </button>
    </div>
  </div>
</div>
