import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { VideoPayload } from "src/app/core/models/video-api-payloads/video.model";

@Component({
  selector: "app-video-grid-list",
  templateUrl: "./video-grid-list.component.html",
  styleUrls: ["./video-grid-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VideoGridListComponent {
  @Input() items: VideoPayload[] = [];
  @Output() videosUpdated = new EventEmitter();

  breakPoint?: number;
  ngOnInit() {
    this.handleSize(window.innerWidth);
  }

  passWidth(event: any) {
    this.handleSize(event.target.innerWidth);
  }

  handleSize(width: number) {
    if (width >= 1200) {
      this.breakPoint = 3;
    } else if (width < 1200 && width > 960) {
      this.breakPoint = 2;
    } else if (width < 960 && width > 580) {
      this.breakPoint = 2;
    } else if (width < 580) {
      this.breakPoint = 1;
    }
  }

  updateVideos() {
    this.videosUpdated.emit();
  }
}
