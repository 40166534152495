import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { QuestionResponse } from "src/app/core/models/question.model";

@Component({
  selector: "app-answer-card",
  templateUrl: "./answer-card.component.html",
  styleUrls: ["./answer-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AnswerCardComponent {
  @Input() answer?: QuestionResponse;
  @Output() viewEvent = new EventEmitter();

  public isCandidate: boolean;
  constructor(router: Router) {
    this.isCandidate = router.url.includes("candidate");
  }
}
