<mat-card
  [style.visibility]="loading ? 'hidden' : 'visible'"
  [ngClass]="{ active: selected }"
  class="playlist-tile"
  (click)="select()"
>
  <div class="playlist-container">
    <div class="playlist-tag">
      <div
        class="playlist-meta-container"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <mat-icon>playlist_play</mat-icon>
        <p style="margin-bottom: 0px">{{ getVideosCount() }}</p>
      </div>
    </div>

    <img
      *ngIf="item.videos.length"
      (load)="disableLoading()"
      class="video-img"
      [src]="item.videos[0].candidate_video.thumbnail"
      (click)="routeToDetail()"
    />
    <div
      *ngIf="!item.videos.length"
      (click)="routeToDetail()"
      fxFlex="100"
      class="noVideo-Playlist"
    >
      <span class="material-icons-outlined" style="font-size: 90px">
        play_circle_outline
      </span>
      <h1>No Videos Added</h1>
      <h3>Click to add Videos</h3>
    </div>
  </div>

  <mat-card-content class="text-box">
    <div fxLayout fxLayoutAlign="space-between" fxLayout="row">
      <p fxFlex="90" class="title text-no-overflow">
        {{ item.title }}
      </p>

      <mat-icon
        class="rounded-lg hover:bg-gray-50 cursor-pointer"
        *ngIf="!selectMode"
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
        >more_vert</mat-icon
      >
      <mat-menu #menu="matMenu">
        <button (click)="addVideos()" mat-menu-item>
          <mat-icon>add</mat-icon>
          <span>Add Videos</span>
        </button>
        <button (click)="openDeleteConfirmationDialog()" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Delete playlist</span>
        </button>
        <button (click)="editPlaylist()" mat-menu-item>
          <mat-icon>edit</mat-icon>
          <span>Edit playlist</span>
        </button>
        <button (click)="sharePlaylist()" mat-menu-item>
          <mat-icon>share</mat-icon>
          <span>Share playlist</span>
        </button>
      </mat-menu>
    </div>

    <div fxLayout fxLayoutAlign="space-between" fxLayout="row">
      <ul class="list subtitle">
        <li>{{ item.created_dt | timeSince }}</li>
      </ul>
      <span
        *ngIf="item.share_status"
        style="color: #23a8e1"
        class="material-icons-outlined"
      >
        public
      </span>
    </div>
  </mat-card-content>
</mat-card>
