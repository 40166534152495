import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subscription, timer } from "rxjs";
import { scan, takeWhile } from "rxjs/operators";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { IdleTimeoutService } from "src/app/core/services/idle-timeout/idle-timeout.service";

@Component({
  selector: "app-idle-time-out",
  templateUrl: "./idle-timeout.component.html",
  styleUrls: ["./idle-timeout.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class IdleTimeoutComponent implements OnInit, OnDestroy {
  icon: string = "assets/images/";

  timer: Subscription = new Subscription();

  counter: number = 10;

  constructor(
    public idleTimeOutService: IdleTimeoutService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<IdleTimeoutComponent>
  ) {}

  ngOnInit(): void {
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.timer.unsubscribe();
  }

  startTimer() {
    this.counter = 0;
    this.timer.unsubscribe();
    this.timer = timer(0, 1000)
      .pipe(
        scan((acc) => --acc, 10),
        takeWhile((x) => x >= 0)
      )
      .subscribe((x) => {
        this.counter = x;
        if (this.counter === 0) {
          this.timer.unsubscribe();
          this.logoutUser();
          this.dialogRef.close();
        }
      });
  }

  cancel() {
    this.timer.unsubscribe();
  }

  logoutUser() {
    this.authService.logout();
    this.idleTimeOutService.stopTimer();
  }
}
