import { Location } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";

export interface IBreadcrumb {
  display: string;
  route: string[];
}

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent {
  @Input() items: any[] = [];
  @Input() showBackButton: boolean = false;
  constructor(private router: Router, private location: Location) {}

  route(item) {
    if (item.route.length) {
      this.router.navigate(item.route);
    }
  }

  back() {
    this.location.back();
  }
}
