import { ViewEncapsulation } from "@angular/core";
import { Component } from "@angular/core";

@Component({
  selector: "app-help-support",
  templateUrl: "./help-support.component.html",
  styleUrls: ["./help-support.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HelpSupportComponent {
  close(radio: HTMLInputElement) {
    if (!radio.checked) {
      radio.checked = true;
    } else {
      radio.checked = false;
    }
  }
}
