import { Component, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ISelectOptions } from "src/app/shared/components/outline-select/outline-select.component";

@Component({
  selector: "app-question",
  templateUrl: "./question.component.html",
  styleUrls: ["./question.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionComponent {
  questionForm = new FormGroup({
    question: new FormControl("", Validators.compose([Validators.required])),
    prep_time: new FormControl("", Validators.compose([Validators.required])),
    num_tries_max: new FormControl(
      "",
      Validators.compose([Validators.required])
    ),
    response_time_limit: new FormControl(
      "",
      Validators.compose([Validators.required])
    ),
  });
  submitted = false;

  prepTimeOpts: ISelectOptions[] = [
    { display: "2", value: 2 },
    { display: "3", value: 3 },
    { display: "5", value: 5 },
  ];

  attempsOpts: ISelectOptions[] = [
    { display: "2", value: 2 },
    { display: "3", value: 3 },
    { display: "5", value: 5 },
  ];

  responseTimeOpts: ISelectOptions[] = [
    { display: "2", value: 2 },
    { display: "3", value: 3 },
    { display: "5", value: 5 },
  ];

  constructor(public dialogRef: MatDialogRef<QuestionComponent>) {}

  closeDialog() {
    this.submitted = true;

    if (this.questionForm.valid) {
      this.dialogRef.close(this.questionForm.value);
    }
  }

  public get questionControl() {
    return this.questionForm.controls.question as FormControl;
  }

  public get prepTimeControl() {
    return this.questionForm.controls.prep_time as FormControl;
  }

  public get responseTimeControl() {
    return this.questionForm.controls.response_time_limit as FormControl;
  }

  public get attemptsControl() {
    return this.questionForm.controls.num_tries_max as FormControl;
  }
}
