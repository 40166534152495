<div class="dialog-img" fxLayout="column">
  <div class="qa-video-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 mat-dialog-title>Select attempt</h2>
    <mat-icon class="close-x" [mat-dialog-close]="false">close</mat-icon>
  </div>
  <div class="qa-video-body">
    <div
    class="playlist-select-videos-vertical-scroll-container"
    fxLayout="row"
    fxLayoutGap="10px"
  >
    <div
      (click)="select(i)"
      class="playlist-select-video-card"
      [class.playlist-select-video-card-selected]="selectedInd == i"
      *ngFor="let answer of data.answers; let i = index"
    >
      <div fxLayout="row" fxLayoutAlign="space-between">
        <span
          *ngIf="selectedInd == i"
          style="font-size: 16px; color: #23a8e1"
          class="material-icons"
        >
          check_circle
        </span>
        <span
          *ngIf="selectedInd !== i"
          style="font-size: 16px; color: #8e8e8e"
          class="material-icons"
        >
          radio_button_unchecked
        </span>
      </div>

      <div style="padding-top: 5px">
        <video
          [src]="videoUrls[i]"
          style="width: 100%; border-radius: 4px"
          controls
        ></video>
      </div>
    </div>
  </div>
  <div style="padding: 16px">
    <button (click)="confirm()" class="tw-btn-primary">
      Confirm
    </button>
  </div>

  </div>
  
</div>
