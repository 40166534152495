import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "src/app/core/services/notification/notification.service";
import { decodeParam } from "src/app/core/utils/paramEncoder";
import {
  ApplicationManagerService,
  IInviteApplicant,
} from "src/app/recruiter/dashboard/pages/application-manager/services/application-manager/application-manager.service";

export function ValidateUrl(control: AbstractControl) {
  const regex = /\S+@\S+\.\S+/;
  const emails = control.value.split(",").map((v) => v.trim());
  const res = emails.every((email) => {
    if (regex.test(email)) return true;
    return false;
  });

  if (res) return null;
  return { invalidUrlList: true };
}

@Component({
  selector: "app-invite-applicant",
  templateUrl: "./invite-applicant.component.html",
  styleUrls: ["./invite-applicant.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class InviteApplicantComponent implements OnInit {
  inviting = false;
  constructor(
    public dialogRef: MatDialogRef<InviteApplicantComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { job: URL },
    private applicationService: ApplicationManagerService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.email.valueChanges.subscribe(() => {
      this.emailChanged = true;
    });
    this.message.valueChanges.subscribe(() => {
      this.messageChanged = true;
    });
  }

  email = new FormControl(
    "",
    Validators.compose([Validators.required, ValidateUrl])
  );
  message = new FormControl(
    "Hi, I would like to invite you to apply to this job, Thanks.",
    Validators.compose([Validators.required])
  );

  emailChanged = false;
  messageChanged = false;

  onInvite() {
    if (this.email.valid && this.message.valid) {
      this.inviting = true;
      const payload: IInviteApplicant = {
        emails: this.email.value?.split(",").map((v) => v.trim()) as string[],
        message: this.message.value as string,
        job: this.data.job.toString(),
        jobId: JSON.parse(
          decodeParam(this.data.job.searchParams.get("meta") as string)
        )["id"],
      };

      this.applicationService.inviteApplications(payload).subscribe({
        next: () => {
          this.notificationService.showNotification("Invitation Sent!");
          this.inviting = false;
          this.dialogRef.close();
        },
        error: () => {
          this.notificationService.showErrorNotification("Please try again!");
          this.inviting = false;
          this.dialogRef.close();
        },
      });
    }
  }
}
