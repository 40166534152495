<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between"
  fxLayoutGap="10px"
  class="job-search-fields-container"
  [formGroup]="form"
>
  <input
    class="outline-input"
    placeholder="Search"
    formControlName="search"
    type="search"
    matInput
  />

  <app-outline-select
    class="job-search-field-select"
    placeholder="Experience Level"
    [control]="experience_level"
    [options]="EXPERIENCE_LEVEL_CHOICES"
    [clearable]="true"
  ></app-outline-select>

  <app-outline-select
    class="job-search-field-select"
    placeholder="Job Type"
    [control]="job_type"
    [options]="JOB_TYPE_CHOICES"
    [clearable]="true"
  ></app-outline-select>

  <app-outline-select
    class="job-search-field-select"
    placeholder="Work Type"
    [control]="work_type"
    [options]="WORK_TYPE_CHOICES"
    [clearable]="true"
  ></app-outline-select>

  <div class="helper-buttons" fxLayoutGap="5px" fxLayout="row">
    <button
      *ngIf="!isTabView()"
      class="tw-btn-secondary"
      matTooltip="Clear all filters"
      (click)="clearFilters()"
    >
      <mat-icon>clear_all</mat-icon>
    </button>
    <ng-container
      *ngIf="createJobButton"
      [ngTemplateOutlet]="createJobButton"
    ></ng-container>
    <ng-container
      *ngIf="shareJobsButton"
      [ngTemplateOutlet]="shareJobsButton"
    ></ng-container>
  </div>
</div>
