import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { MatMenu, MatMenuTrigger } from "@angular/material/menu";
import { Job } from "src/app/recruiter/dashboard/pages/jobs/models/job.model";
import {
  DefaultCompanyLogo,
  experienceLevelMap,
  jobTypeMap,
  workTypeMap,
} from "src/app/recruiter/dashboard/pages/jobs/utils";

@Component({
  selector: "app-job-card",
  templateUrl: "./job-card.component.html",
  styleUrls: ["./job-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class JobCardComponent {
  @Input() job!: Job;
  @Input() skeleton: boolean = false;
  @Input() candidateMode?: boolean;

  @Output() publishEvent = new EventEmitter();
  @Output() delistEvent = new EventEmitter();
  @Output() detailEvent = new EventEmitter();
  @Output() shareEvent = new EventEmitter();

  defaultLogo = DefaultCompanyLogo;

  openMenu(menu: MatMenuTrigger, event: Event) {
    menu.openMenu();
    event.stopPropagation();
  }

  getExperienceLevel(job: Job) {
    return experienceLevelMap.get(job.experience_level);
  }
  getWorkType(job: Job) {
    return workTypeMap.get(job.work_type);
  }
  getJobType(job: Job) {
    return jobTypeMap.get(job.job_type);
  }

  detail(job: Job) {
    this.detailEvent.emit(job);
  }

  publish(job: Job, event: Event) {
    event.stopPropagation();
    this.publishEvent.emit(job);
  }

  delist(job: Job, event: Event) {
    event.stopPropagation();
    this.delistEvent.emit(job);
  }

  share(job: Job, event: Event) {
    event.stopPropagation();
    this.shareEvent.emit(job);
  }
}
