<mat-card
  [style.visibility]="loading ? 'hidden' : 'visible'"
  class="video-tile"
  [ngClass]="{ 'intro-video': item.intro }"
  [ngClass]="{ 'active-video-selected': selected }"
  (click)="select()"
  ><img
    (load)="disableLoading()"
    (click)="routeToDetail()"
    loading="lazy"
    class="video-img"
    [src]="item.candidate_video.thumbnail"
  />
  <mat-card-content class="text-box">
    <div style="align-items: end" fxLayout="row" class="pt-1">
      <p fxFlex="90" class="title text-no-overflow">
        {{ item!.title }}
      </p>

      <mat-icon
        class="rounded-lg hover:bg-gray-50 cursor-pointer"
        *ngIf="!selectMode"
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
        >more_vert</mat-icon
      >
      <mat-menu #menu="matMenu">
        <button (click)="openDeleteConfirmationDialog($event)" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
        <button *ngIf="item.public" (click)="makePrivate()" mat-menu-item>
          <mat-icon>cancel_presentation</mat-icon>
          <span>Make Private</span>
        </button>

        <button *ngIf="!item.public" (click)="makePublic()" mat-menu-item>
          <mat-icon>public</mat-icon>
          <span>Make Public</span>
        </button>
        <div
          [matTooltipDisabled]="item.public"
          matTooltip="Video is not public"
          matTooltipPosition="right"
        >
          <button [disabled]="!item.public" (click)="share()" mat-menu-item>
            <mat-icon>share</mat-icon>
            <span>Share</span>
          </button>
        </div>
        <button (click)="addToPlaylist()" mat-menu-item>
          <mat-icon>playlist_add</mat-icon>
          <span>Add to playlist</span>
        </button>
      </mat-menu>
    </div>
    <div *ngIf="!loading && !selectMode" class="tile-description">
      {{ item.description }}&nbsp;
    </div>
    <br *ngIf="!selectMode" />
    <div fxLayout fxLayoutAlign="start">
      <ul fxLayout fxLayoutGap="5px" class="list subtitle">
        <li>{{ item.created_dt | timeSince }}</li>
      </ul>
    </div>
  </mat-card-content>
</mat-card>
