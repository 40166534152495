export function isMobileWidth() {
  if (window.innerWidth < 600) {
    return true;
  } else return false;
}

export function isTabWidth() {
  if (window.innerWidth < 960) {
    return true;
  } else return false;
}

export function isDesktopWidth() {
  if (window.innerWidth > 960) {
    return true;
  } else return false;
}
