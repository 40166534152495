<div class="page">
  <div class="questions">
    <div class="heading">
      <span style="color: black; font-weight: bold">Help & </span>
      <span style="color: #23a8e1; font-weight: bold"> Support</span>
      <br />
      <span style="font-size: 24px"> Frequently Asked Questions </span>
    </div>

    <div class="questions-container">
      <div class="row">
        <div class="col">
          <div class="tabs">
            <!-- New Accordion Tab -->
            <div class="tab" (click)="close(tab1)">
              <input
                #tab1
                class="accordion"
                type="radio"
                id="chck1"
                disabled
                name="openOne"
              />
              <label class="tab-label" for="chck1">What is Workstory?</label>
              <div class="tab-content">
                Workstory is a web application designed to streamline the
                recruitment process by allowing recruiters to conduct and record
                video interviews with applicants. It offers a user-friendly
                platform that enhances the traditional interview process and
                helps both recruiters and applicants save time and effort.
              </div>
            </div>
            <!-- New Accordion Tab -->
            <div class="tab" (click)="close(tab2)">
              <input
                #tab2
                class="accordion"
                type="radio"
                id="chck2"
                disabled
                name="openOne"
              />
              <label class="tab-label" for="chck2">How does Workstory work?</label>
              <div class="tab-content">
                Workstory enables recruiters to send interview invitations to
                applicants. Once applicants receive the invitation, they can log
                in to the platform and record their video responses to
                predefined interview questions. Recruiters can then review and
                assess these video interviews at their convenience.
              </div>
            </div>
            <!-- New Accordion Tab -->
            <div class="tab" (click)="close(tab3)">
              <input
                #tab3
                class="accordion"
                type="radio"
                id="chck3"
                disabled
                name="openOne"
              />
              <label class="tab-label" for="chck3">What the benefits of using Workstory?</label>
              <div class="tab-content">
                - <strong><em>Time Efficiency:</em></strong> Recruiters can
                review recorded interviews at their convenience, eliminating
                scheduling conflicts. <br />
                - <strong><em>Enhanced Candidate Evaluation:</em></strong> Video
                interviews provide additional insights into candidates'
                communication skills, personality, and professionalism. <br />
                - <strong><em>Global Reach:</em></strong> Applicants can
                participate in interviews regardless of their geographical
                location. <br />
                - <strong><em>Consistency:</em></strong> All applicants answer
                the same set of questions, ensuring fair and consistent
                evaluation. <br />
                - <strong><em>Reduced Costs:</em></strong> Reduces the need for
                in-person or live video interviews, saving travel and logistics
                expenses. <br />
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="tabs">
            <!-- New Accordion Tab -->
            <div class="tab" (click)="close(tab4)">
              <input
                #tab4
                class="accordion"
                type="radio"
                id="chck4"
                disabled
                name="openOne"
              />
              <label class="tab-label" for="chck4">Is Workstory's platform secure?</label>
              <div class="tab-content">
                Yes, security is a priority for Workstory. The platform employs
                encryption and other security measures to protect interview
                videos and applicant data. Only authorized users, such as
                recruiters and HR professionals, can access the recorded
                interviews.
              </div>
            </div>
            <!-- New Accordion Tab -->
            <div class="tab" (click)="close(tab5)">
              <input
                #tab5
                class="accordion"
                type="radio"
                id="chck5"
                disabled
                name="openOne"
              />
              <label class="tab-label" for="chck5">Can applicants rerecord their responses?</label>
              <div class="tab-content">
                Yes, in most cases, applicants have the ability to rerecord
                their responses before submitting the final version. This
                feature allows applicants to present their best selves and
                minimize interview nerves.
              </div>
            </div>
            <!-- New Accordion Tab -->
            <div class="tab" (click)="close(tab6)">
              <input
                #tab6
                class="accordion"
                type="radio"
                id="chck6"
                disabled
                name="openOne"
              />
              <label class="tab-label" for="chck6">What questions can recruiters ask in video interviews?</label>
              <div class="tab-content">
                Recruiters can customize the interview questions based on the
                position and their specific hiring needs. Common types of
                questions include behavioral, situational, and skills-based
                inquiries.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="contact">
    <div class="heading">
      <span style="color: black; font-weight: bold">Still have</span>
      <span style="color: #23a8e1; font-weight: bold"> Questions?</span>
      <br />
      <button
        
        class="contact-button tw-btn-primary"
        onclick="window.location.href = 'https://workstory.io/support/';"
      >
        Contact Us
      </button>
    </div>
    <!-- <div class="flexbox">
            <div class="message">
                <form class="message-form">
                    <label class="message-label1" for="name">Name:</label>
                    <label class="message-label2" for="email">Email:</label>

                    <input class="message-input1" type="text" id="name" name="name" required>
                    <input class="message-input2" type="text" id="email" name="email" required>
                    <br>
                    <label class="message-label3" for="subject">Subject:</label>
                    <input class="message-input3" type="text" id="subject" name="subject" required>
                    <br>
                    <label class="message-label4" for="message">Message:</label>
                    <textarea class="message-input4" id="message" name="message" required></textarea>

                </form>
                <button mat-raised-button class="submit">Send Message</button>
            </div>
            <div class="contact-details">
                <div class="flexbox2">
                    <span class="material-icons-outlined contact-icon">location_on</span>

                    <p class="detail">
                        Main Office: NO.22-23 Street Name- City,CountryCustomer <br>
                        Center: NO.130-45 Streen Name- City, Country
                    </p>

                    <span class="material-icons-outlined contact-icon">phone</span>
                    <p class="detail">
                        Customer Support: info@mail.com <br>
                        Technical Support:support@mail.com
                    </p>

                    <span class="material-icons-outlined contact-icon"> mail</span>
                    <p class="detail">
                        Main Office: +880 123 456 789 <br>
                        Customer Supprort: +880 123 456 789
                    </p>
                </div>

            </div>
        </div> -->
  </div>
</div>
