<div
  *ngIf="!skeleton; else jobSkeleton"
  (click)="detail(job)"
  class="job-list-card"
>
  <div class="job-card-header">
    <img
      class="logo-img"
      [src]="job.company_logo || job.company_logo_url || defaultLogo"
      alt=""
    />
    <div style="width: 100%" fxLayout="column" fxLayoutGap="12px">
      <div fxLayout="row" fxLayoutAlign="space-between start ">
        <span class="job-card-title text-no-overflow">
          {{ job.job_title }}
        </span>

        <div *ngIf="job.applied" class="btn-md tw-btn-secondary">
          <span class="material-icons-outlined"> check_circle </span>
          <span style="margin-left: 5px">Applied</span>
        </div>
      </div>
      <div class="job-icon-tags">
        <div class="icon-tag">
          <mat-icon>work</mat-icon>
          <p>{{ job.company }}</p>
        </div>
        <div *ngIf="job.location" class="icon-tag">
          <mat-icon>location_on</mat-icon>
          <p>{{ job.location }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="job-cards-chips-container">
    <mat-chip-list class="chipli" #chipList>
      <mat-chip> {{ getJobType(job) }} </mat-chip>
      <mat-chip> {{ getWorkType(job) }} </mat-chip>
      <mat-chip> {{ getExperienceLevel(job) }} </mat-chip>
    </mat-chip-list>
  </div>
  <mat-divider></mat-divider>
  <!-- <mat-divider></mat-divider>
  <p class="job-card-description">
    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
    Lorem Ipsum has been the industry's standard dummy text ever....
  </p> -->
  <!-- <div *ngIf="!candidateMode" align="start">
    <button
      style="background-color: red; color: white"
      disabled
      *ngIf="!job.is_published"
      mat-button
    >
      Draft
    </button>
    <button
      style="background-color: green; color: white"
      disabled
      *ngIf="job.is_published"
      mat-button
    >
      Published
    </button>
    <button
      *ngIf="!job.is_published"
      (click)="publish(job, $event)"
      mat-raised-button
    >
      Publish
    </button>
    <button
      *ngIf="job.is_published"
      (click)="delist(job, $event)"
      mat-raised-button
    >
      Delist
    </button>
    <button
      *ngIf="job.is_published"
      (click)="share(job, $event)"
      mat-raised-button
    >
      Share
    </button>
  </div> -->
  <div style="display: flex; justify-content: space-between">
    <div *ngIf="job.min_salary && job.max_salary">
      {{ job.min_salary | currency : job.currency : "symbol" : "1.0-0" }} -
      {{ job.max_salary | currency : job.currency : "symbol" : "1.0-0" }}
      /
      <span style="font-weight: 300">year</span>
    </div>
    <div
      style="
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
      "
    >
      <mat-icon style="transform: scale(0.7)">watch_later</mat-icon>
      <span style="font-weight: 300; font-size: small">{{
        job.published_dt | timeSince
      }}</span>
    </div>
  </div>
</div>
<ng-template #jobSkeleton>
  <div class="job-list-card">
    <div class="job-card-header">
      <img class="logo-img job-card-skeleton-image" alt="" />
      <div style="width: 100%" fxLayout="column" fxLayoutGap="12px">
        <div fxLayout="row" fxLayoutAlign="space-between start ">
          <span
            style="width: 100%; height: 30px"
            class="job-card-title text-no-overflow job-card-skeleton"
          >
          </span>
        </div>
        <div class="job-icon-tags">
          <div class="icon-tag">
            <p style="width: 90px; height: 20px" class="job-card-skeleton"></p>
          </div>
          <div class="icon-tag">
            <p style="width: 70px; height: 20px" class="job-card-skeleton"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="job-cards-chips-container">
      <mat-chip-list class="chipli" #chipList>
        <mat-chip class="job-card-skeleton" style="width: 80px"> </mat-chip>
        <mat-chip class="job-card-skeleton" style="width: 80px"> </mat-chip>
        <mat-chip class="job-card-skeleton" style="width: 80px"> </mat-chip>
      </mat-chip-list>
    </div>

    <mat-divider></mat-divider>
    <p class="job-card-description job-card-skeleton"></p>
    <div style="display: flex; justify-content: space-between">
      <div style="height: 20px; width: 30%" class="job-card-skeleton"></div>
      <div style="height: 20px; width: 30%" class="job-card-skeleton"></div>
    </div>
  </div>
</ng-template>
