import { ROUTER_UTILS } from "src/app/core/utils/router.utils";
import { SideNavItem } from "src/app/shared/models/sideNavItem";

export const RecruiterFeatures: SideNavItem[] = [
  {
    btnText: "Home",
    route: [
      ROUTER_UTILS.recruiter.root,
      ROUTER_UTILS.recruiter.dashboard.home.root,
    ],
    icon: "home",
  },
  {
    btnText: "Jobs",
    route: [
      ROUTER_UTILS.recruiter.root,
      ROUTER_UTILS.recruiter.dashboard.jobs.root,
    ],
    icon: "work_outline",
  },
  {
    btnText: "My Applicants",
    route: [
      ROUTER_UTILS.recruiter.root,
      ROUTER_UTILS.recruiter.dashboard.applicationManager.root,
    ],
    icon: "apps",
  },

  {
    btnText: "Profile",
    route: [
      ROUTER_UTILS.recruiter.root,
      ROUTER_UTILS.recruiter.dashboard.profile.root,
    ],
    icon: "account_circle",
  },

  // {
  //   btnText: "Settings",
  //   route: [
  //     ROUTER_UTILS.recruiter.root,
  //     ROUTER_UTILS.recruiter.dashboard.settings.root,
  //   ],
  //   icon: "settings",
  // },
];
