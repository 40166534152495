import { Component, Input } from "@angular/core";
import { Application } from "src/app/recruiter/dashboard/pages/application-manager/models/application.model";

@Component({
  selector: "app-manager-detail",
  templateUrl: "./app-manager-detail.component.html",
  styleUrls: ["./app-manager-detail.component.scss"],
})
export class AppManagerDetailComponent {
  @Input() application?: Application;
  @Input() applicant?: Application;
}
