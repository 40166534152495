export const CountryFabButtons = [
  {
    id: "en",
    imgUrl: "assets/flags/en.svg",
    tooltip: "english",
    tooltipPosition: "before",
  },
  {
    id: "es",
    imgUrl: "assets/flags/es.svg",
    tooltip: "spanish",
    tooltipPosition: "before",
  },
];
