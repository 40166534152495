export const environment = {
  production: true,
  name: "dev",
  // name: "development",
  auth0Domain: "dev-workstory.us.auth0.com",
  auth0ClientId: "Jc2L8q5lfp4mIgfzLr17ZaM5u43ofrUg",
  baseUrl: "https://dev.api.workstory.io/",
  // baseUrl: "http://127.0.0.1:8000/",
  idleTimeInMinutes: 240,
};
