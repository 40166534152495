import { trigger, transition, style, animate } from "@angular/animations";
import {
  Component,
  HostListener,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import {
  DrawerService,
  IDrawerConfig,
} from "../../services/drawer/drawer.service";

@Component({
  selector: "app-drawer",
  templateUrl: "./drawer.component.html",
  styleUrls: ["./drawer.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateX(100%)" }),
        animate("200ms ease-in", style({ transform: "translateX(0%)" })),
      ]),
      transition(":leave", [
        animate("200ms ease-in", style({ transform: "translateX(100%)" })),
      ]),
    ]),
  ],
})
export class DrawerComponent {
  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.drawerService.opened.next(false);
    }
  }

  public template: TemplateRef<any> | null = null;
  public header: string = "";

  constructor(public drawerService: DrawerService) {
    this.drawerService.config.subscribe((config: IDrawerConfig) => {
      this.template = config.template;
      this.header = config.header;
    });
  }
}
