import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularMaterialModule } from "../angular-material.module";
import { MainContentHeaderComponent } from "./components/main-content-header/main-content-header.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SideNavItemListComponent } from "./components/side-nav-item-list/side-nav-item-list.component";
import { IdleTimeoutComponent } from "./services/dialog/dialog-components/idle-timeout/idle-timeout.component";
import { DialogService } from "./services/dialog/dialog.service";
import { TranslateModule } from "@ngx-translate/core";
import { MatFabMenuModule } from "@angular-material-extensions/fab-menu";
import { VideoTagComponent } from "./components/video-form-components/video-tag/video-tag.component";
import { VideoRecordCardComponent } from "./components/video-form-components/video-record-card/video-record-card.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CountdownModule } from "ngx-countdown";
import { VideoGridListComponent } from "./components/video-grid-list-components/video-grid-list/video-grid-list.component";
import { VideoTileComponent } from "./components/video-grid-list-components/video-tile/video-tile.component";
import { VideoForm } from "../candidate/dashboard/pages/videos/forms/video-input-form";
import { CreateVideoFormComponent } from "./components/video-form-components/create-video-form/create-video-form.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { TimeSincePipe } from "./pipes/time-since-pipe/time-since.pipe";
import { PlaylistGridListComponent } from "./components/playlist-grid-list-components/playlist-grid-list/playlist-grid-list.component";
import { PlaylistTileComponent } from "./components/playlist-grid-list-components/playlist-tile/playlist-tile.component";
import { AddToPlaylistComponent } from "./services/dialog/dialog-components/add-to-playlist/add-to-playlist.component";
import { PlaylistVideoTileComponent } from "./components/playlist-video-tile/playlist-video-tile.component";
import { PersonalInfoFormComponent } from "./components/personal-info-form/personal-info-form.component";
import { CandidateProfileForm } from "../candidate/dashboard/pages/profile/form/candidate-profile-form";
import { RecruiterProfileForm } from "../recruiter/dashboard/pages/profile/form/recruiter-profile-form";
import { NgxMatIntlTelInputComponent } from "ngx-mat-intl-tel-input";
import { ShareVideoComponent } from "./services/dialog/dialog-components/share-video/share-video.component";
import { JobSearchFieldComponent } from "./components/job-search-field/job-search-field.component";
import { JobCardComponent } from "./components/job-card/job-card.component";
import { JobDetailComponent } from "./components/job-detail/job-detail.component";
import { AppManagerCardComponent } from "./components/app-manager-card/app-manager-card.component";
import { AppManagerDetailComponent } from "./components/app-manager-detail/app-manager-detail.component";
import { ScrollTrackerDirective } from "./directives/scroll-tracker.directive";
import { VerticalScrollTrackerDirective } from "./directives/vertical-scroll-tracker.directive";
import { QuestionComponent } from "./services/dialog/dialog-components/question/question.component";
import { CandidateProfileComponent } from "./components/candidate-profile/candidate-profile.component";
import { VideoComponent } from "./services/dialog/dialog-components/video/video.component";
import { AddVideoToPlaylistComponent } from "./services/dialog/dialog-components/add-video-to-playlist/add-video-to-playlist.component";
import { FullscreenMenuComponent } from "./services/dialog/dialog-components/fullscreen-menu/fullscreen-menu.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { OutlineInputBoxComponent } from "./components/outline-input-box/outline-input-box.component";
import { TileSkeletonComponent } from "./components/tile-skeleton/tile-skeleton.component";
import { TileGridSkeletonComponent } from "./components/tile-grid-skeleton/tile-grid-skeleton.component";
import { OutlineSelectComponent } from "./components/outline-select/outline-select.component";
import { JobGridSkeletonComponent } from "./components/job-grid-skeleton/job-grid-skeleton.component";
import { QaConfirmationPromptComponent } from "./services/dialog/dialog-components/qa-confirmation-prompt/qa-confirmation-prompt.component";
import { QaVideoSelectComponent } from "./services/dialog/dialog-components/qa-video-select/qa-video-select.component";
import { DndDirective } from "./directives/dnd.directive";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { HelpSupportComponent } from "./components/help-support/help-support.component";
import { LoadingScreenComponent } from "./loading-screen/loading-screen.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { AnswerCardComponent } from "./components/answer-card/answer-card.component";
import { EmptyStateComponent } from "./components/empty-state/empty-state.component";
import { ConfirmationPromptComponent } from "./services/dialog/dialog-components/confirmation-prompt/confirmation-prompt.component";
import { InviteApplicantComponent } from "./services/dialog/dialog-components/invite-applicant/invite-applicant.component";
import { RecruiterProfileComponent } from "./components/recruiter-profile/recruiter-profile.component";
import { MessageRecruiterComponent } from "./services/dialog/dialog-components/message-recruiter/message-recruiter.component";
import { LocationAutocompleteComponent } from "./components/location-autocomplete/location-autocomplete.component";
import { FeedbackComponent } from "./services/dialog/dialog-components/feedback/feedback.component";
import { SharePlaylistComponent } from "./services/dialog/dialog-components/share-playlist/share-playlist.component";
import { DrawerComponent } from "./components/drawer/drawer.component";

@NgModule({
  declarations: [
    SideNavItemListComponent,
    MainContentHeaderComponent,
    IdleTimeoutComponent,
    VideoTagComponent,
    VideoRecordCardComponent,
    CreateVideoFormComponent,
    VideoGridListComponent,
    VideoTileComponent,
    LoaderComponent,
    TimeSincePipe,
    PlaylistGridListComponent,
    PlaylistTileComponent,
    AddToPlaylistComponent,
    PlaylistVideoTileComponent,
    PersonalInfoFormComponent,
    ShareVideoComponent,
    JobSearchFieldComponent,
    JobCardComponent,
    JobDetailComponent,
    AppManagerCardComponent,
    AppManagerDetailComponent,
    ScrollTrackerDirective,
    VerticalScrollTrackerDirective,
    QuestionComponent,
    CandidateProfileComponent,
    VideoComponent,
    AddVideoToPlaylistComponent,
    FullscreenMenuComponent,
    LoadingScreenComponent,
    BreadcrumbComponent,
    OutlineInputBoxComponent,
    TileSkeletonComponent,
    TileGridSkeletonComponent,
    OutlineSelectComponent,
    JobGridSkeletonComponent,
    QaConfirmationPromptComponent,
    QaVideoSelectComponent,
    DndDirective,
    AnswerCardComponent,
    HelpSupportComponent,
    NotificationsComponent,
    EmptyStateComponent,
    ConfirmationPromptComponent,
    InviteApplicantComponent,
    RecruiterProfileComponent,
    MessageRecruiterComponent,
    LocationAutocompleteComponent,
    FeedbackComponent,
    SharePlaylistComponent,
    DrawerComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FlexLayoutModule,
    TranslateModule,
    MatFabMenuModule,
    FormsModule,
    CountdownModule,
    ReactiveFormsModule,
    NgxMatIntlTelInputComponent,
    DragDropModule,
  ],

  exports: [
    SideNavItemListComponent,
    MainContentHeaderComponent,
    CreateVideoFormComponent,
    VideoGridListComponent,
    FlexLayoutModule,
    AngularMaterialModule,
    TranslateModule,
    MatFabMenuModule,
    CountdownModule,
    LoaderComponent,
    TimeSincePipe,
    VideoTagComponent,
    PlaylistGridListComponent,
    PlaylistVideoTileComponent,
    PersonalInfoFormComponent,
    JobSearchFieldComponent,
    JobCardComponent,
    JobDetailComponent,
    AppManagerCardComponent,
    AppManagerDetailComponent,
    VideoTileComponent,
    PlaylistTileComponent,
    ScrollTrackerDirective,
    VerticalScrollTrackerDirective,
    LoadingScreenComponent,
    BreadcrumbComponent,
    OutlineInputBoxComponent,
    TileSkeletonComponent,
    TileGridSkeletonComponent,
    OutlineSelectComponent,
    JobGridSkeletonComponent,
    VideoRecordCardComponent,
    DndDirective,
    AnswerCardComponent,
    EmptyStateComponent,
    LocationAutocompleteComponent,
    NotificationsComponent,
    DrawerComponent,
  ],
  providers: [
    DialogService,
    VideoForm,
    CandidateProfileForm,
    RecruiterProfileForm,
  ],
})
export class SharedModule {}
