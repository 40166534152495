<div class="question-modal-container">
  <div class="modal-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 mat-dialog-title>Send a message</h2>
    <mat-icon class="close-x" [mat-dialog-close]="false">close</mat-icon>
  </div>
  <div class="qa-modal-body" [formGroup]="messageForm">
    <app-outline-input-box
      [submitted]="submitted"
      title="Message"
      [largeText]="true"
      [control]="messageControl"
    ></app-outline-input-box>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button [mat-dialog-close]="false" class="tw-btn-negative">
        Cancel
      </button>
      <button (click)="send()" class="tw-btn-primary">Send</button>
    </div>
  </div>
</div>
