import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  Applicant,
  CandidateProfile,
} from "src/app/candidate/models/candidate-profile.model";
import { VideoPayload } from "src/app/core/models/video-api-payloads/video.model";
import { decodeParam } from "src/app/core/utils/paramEncoder";
import { ROUTER_UTILS } from "src/app/core/utils/router.utils";
import { RecruiterService } from "src/app/recruiter/services/user-service/recruiter-user.service";

@Component({
  selector: "app-candidate-profile",
  templateUrl: "./candidate-profile.component.html",
  styleUrls: ["./candidate-profile.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CandidateProfileComponent {
  breadcrumb: any[] = [];

  loading = false;
  isPubMode = false;
  selectedVideo?: VideoPayload;
  applicant!: Applicant;
  constructor(
    private recruiterService: RecruiterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.getApplicantProfile(decodeParam(params["id"]));
      if (params["pubMode"]) {
        this.isPubMode = true;
        this.breadcrumb = [
          {
            display: "Recruiter",
            route: [
              ROUTER_UTILS.recruiter.root,
              ROUTER_UTILS.recruiter.dashboard.home.root,
            ],
          },
          {
            display: "Candidate search",
            route: [],
          },
        ];
      } else {
        this.breadcrumb = [
          {
            display: "Recruiter",
            route: [
              ROUTER_UTILS.recruiter.root,
              ROUTER_UTILS.recruiter.dashboard.home.root,
            ],
          },
          {
            display: "Job",
            route: [
              ROUTER_UTILS.recruiter.root,
              ROUTER_UTILS.recruiter.dashboard.home.root,
            ],
          },
          {
            display: "Applicant Profile",
            route: [],
          },
        ];
      }
    });
  }

  getApplicantProfile(id: string) {
    this.loading = true;
    this.recruiterService.getApplicantProfile(id).subscribe((res) => {
      res.videos = res.videos.filter((v: VideoPayload) => !!v.candidate_video);
      this.applicant = res;
      this.selectedVideo = this.applicant.videos.filter(
        (video: VideoPayload) => video.intro
      )[0];
      if (!this.selectedVideo) this.selectedVideo = this.applicant.videos[0];
      this.loading = false;
    });
  }

  select(video: VideoPayload) {
    this.selectedVideo = video;
  }
}
