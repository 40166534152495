import { ROUTER_UTILS } from "src/app/core/utils/router.utils";
import { SideNavItem } from "src/app/shared/models/sideNavItem";

export const CandidateFeatures: SideNavItem[] = [
  {
    btnText: "Home",
    route: [
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.home.root,
    ],
    icon: "dashboard",
  },
  {
    btnText: "Videos",
    route: [
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.videos.root,
    ],
    icon: "video_library",
  },
  {
    btnText: "Playlists",
    route: [
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.playlist.root,
    ],
    icon: "playlist_play",
  },
  {
    btnText: "Jobs",
    route: [
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.jobs.root,
    ],
    icon: "work_outline",
  },
  {
    btnText: "My Applications",
    route: [
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.applicationManager.root,
    ],
    icon: "apps",
  },
  {
    btnText: "My Resumes",
    route: [
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.resumes.root,
    ],
    icon: "description",
  },
  {
    btnText: "Profile",
    route: [
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.profile.root,
    ],
    icon: "account_circle",
  },
  {
    btnText: "Settings",
    route: [
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.settings.root,
    ],
    icon: "settings",
  },
];
