import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CandidateCallbackComponent } from "./core/auth0-callbacks/candidate-callback/candidate-callback.component";
import { RecruiterCallbackComponent } from "./core/auth0-callbacks/recruiter-callback/recruiter-callback.component";
import { AuthGuard } from "./core/guards/auth-guard/auth.guard";
import { NoAuthGuard } from "./core/guards/no-auth-guard/no-auth.guard";
import { ROUTER_UTILS } from "./core/utils/router.utils";
import { HeadlessLayoutComponent } from "./shell/layout-components/headless-layout/headless-layout.component";
import { LayoutComponent } from "./shell/layout-components/layout/layout.component";

const routes: Routes = [
  { path: "", redirectTo: ROUTER_UTILS.portfolio.root, pathMatch: "full" },
  {
    path: "",
    component: HeadlessLayoutComponent,
    children: [
      {
        path: ROUTER_UTILS.portfolio.root,
        loadChildren: async () =>
          (await import("./portfolio/portfolio.module")).PortfolioModule,
        canActivate: [NoAuthGuard],
      },
      {
        path: ROUTER_UTILS.public.root,
        loadChildren: async () =>
          (await import("./public/public.module")).PublicModule,
      },
    ],
  },
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: ROUTER_UTILS.candidate.root,
        loadChildren: async () =>
          (await import("./candidate/candidate.module")).CandidateModule,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTER_UTILS.recruiter.root,
        loadChildren: async () =>
          (await import("./recruiter/recruiter.module")).RecruiterModule,
        canActivate: [AuthGuard],
      },
    ],
  },

  {
    path: ROUTER_UTILS.auth0Callback.candidateCallback,
    component: CandidateCallbackComponent,
  },
  {
    path: ROUTER_UTILS.auth0Callback.recruiterCallback,
    component: RecruiterCallbackComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
