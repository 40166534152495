<div class="playlist-detail-container">
  <div class="add-video-to-playlist-header" fxLayout="row" fxLayoutAlign="space-between start">
    <div>
      <h2 mat-dialog-title>Select videos</h2>
    </div>

    <mat-icon class="close-x" [mat-dialog-close]="false">close</mat-icon>
  </div>
  <div class="add-video-to-playlist-body">
    <ng-container [formGroup]="searchForm">
      <input
        class="outline-input"
        formControlName="search"
        placeholder="Search"
        type="search"
        style="width: 100%"
      />
    </ng-container>

    <div class="add-video-to-playlist-content">
      <div
        id="scroller"
        appScrollTracker
        (scrolled)="getMoreVideos()"
        style="display: flex; overflow-x: auto; gap: 10px"
      >
        <br />
        <div
          *ngFor="let video of videos"
          style="max-width: 400px; min-width: 400px"
        >
          <app-video-tile
            [selectedVideos]="selectedVideos"
            [selectMode]="true"
            (selectEvent)="selectVideo($event)"
            [item]="video"
          ></app-video-tile>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-chip-list>
          <mat-chip *ngFor="let vid of selectedVideos">
            {{ vid.title }}
            <button (click)="selectVideo(vid)" matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip></mat-chip-list
        >
      </div>
    </div>
  
    <br>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
      <button class="btn-md tw-btn-secondary" [mat-dialog-close]="false">
        Cancel
      </button>
      <button class="btn-md tw-btn-primary" [mat-dialog-close]="false" (click)="save()">
        Save
      </button>
    </div>

  </div>
  
</div>
