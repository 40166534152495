import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Applicant,
  CandidateProfile,
} from "src/app/candidate/models/candidate-profile.model";
import { environment } from "src/environments/environment";
import { RecruiterProfile } from "../../models/recruiter-profile.model";

@Injectable({
  providedIn: "root",
})
export class RecruiterService {
  private recruiterApiBaseUrl = `${environment.baseUrl}recruiter/api/`;

  constructor(private http: HttpClient) {}

  getProfile() {
    return this.http.get<RecruiterProfile>(
      this.recruiterApiBaseUrl + "profile/"
    );
  }

  updateProfile(
    fName: string,
    lName: string,
    phone: string,
    name?: string,
    picture?: Blob,
    company?: string,
    companyDescription?: string
  ) {
    let formData = new FormData();

    if (picture) {
      formData.append("profile_picture", picture, "profile_picture");
    }
    if (company) {
      formData.append("company", company);
    }
    if (companyDescription) {
      formData.append("company_description", companyDescription);
    }
    formData.append("first_name", fName);
    formData.append("last_name", lName);
    formData.append("name", name as string);
    formData.append("phone_number", phone);

    return this.http.put<RecruiterProfile>(
      this.recruiterApiBaseUrl + "profile/",
      formData
    );
  }

  saveLocationSettings(
    city: string,
    country: string,
    state: string,
    fName: string,
    lName: string,
    phone: string,
    name?: string,
  ) {
    let formData = new FormData();
    formData.append("city", city);
    formData.append("state", state);
    formData.append("country", country);
    formData.append("first_name", fName);
    formData.append("last_name", lName);
    formData.append("phone_number", phone);
    return this.http.put<RecruiterProfile>(
      this.recruiterApiBaseUrl + "profile/",
      formData
    );
  }

  getApplicantProfile(id: string) {
    return this.http.get<Applicant>(
      this.recruiterApiBaseUrl + "applicantProfile/" + id
    );
  }
}
