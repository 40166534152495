<div class="share-box-container">
  <div class="share-box-header" fxLayout="row" fxLayoutAlign="space-between start">
    <h2 mat-dialog-title>Share</h2>
    <mat-icon class="close-x" [mat-dialog-close]="true">close</mat-icon>
  </div>
  <div class="share-box-body">
    <mat-dialog-content>
      <mat-form-field appearance="fill" style="width: 650px;">
      <mat-label>Shareable Link</mat-label>
      <input matInput type="text" [(ngModel)]="data.url" />
      <button (click)="copy()" matSuffix mat-icon-button aria-label="Copy">
        <mat-icon class="close-x">link</mat-icon>
      </button>
      </mat-form-field>
    </mat-dialog-content>
  </div>
</div>