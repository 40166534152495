import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterModule } from "./layout-components/footer/footer.module";
import { HeaderModule } from "./layout-components/header/header.module";
import { HeadlessLayoutModule } from "./layout-components/headless-layout/headless-layout.module";
import { LayoutModule } from "./layout-components/layout/layout.module";
import { RouterModule } from "@angular/router";
import { AngularMaterialModule } from "../angular-material.module";

@NgModule({
  imports: [
    CommonModule,
    FooterModule,
    HeaderModule,
    HeadlessLayoutModule,
    LayoutModule,
    RouterModule,
    AngularMaterialModule,
  ],
  exports: [
    CommonModule,
    FooterModule,
    HeaderModule,
    HeadlessLayoutModule,
    LayoutModule,
    RouterModule,
  ],
})
export class ShellModule {}
