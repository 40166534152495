import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { CloneHeaderService } from "./clone-header.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private cloneHeaderService: CloneHeaderService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(this.cloneHeaderService.cloneHeader(request));
  }
}
