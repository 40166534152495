import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { SideNavItem } from "src/app/shared/models/sideNavItem";

@Component({
  selector: "app-fullscreen-menu",
  templateUrl: "./fullscreen-menu.component.html",
  styleUrls: ["./fullscreen-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FullscreenMenuComponent implements OnInit, OnDestroy {
  logoutBtn = {
    btnText: "Logout",
    route: [],
    icon: "logout",
  };
  btns: SideNavItem[] = [];
  constructor(
    public dialogRef: MatDialogRef<FullscreenMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { items: SideNavItem[] },
    private router: Router,
    private authService: AuthService
  ) {}
  ngOnDestroy(): void {
    this.btns = [];
  }
  ngOnInit(): void {
    this.btns = this.data.items.map((v) => v);
    this.btns.push(this.logoutBtn);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  btnRoute(btn: SideNavItem) {
    if (btn.btnText === "Logout") {
      this.logout();
      return;
    }
    this.router.navigate(btn.route);
    this.onNoClick();
  }

  logout() {
    this.authService.logout();
  }
}
