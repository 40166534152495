import { FormControl } from "@angular/forms";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import "@angular/google-maps";
import { __await } from "tslib";

export interface location {
  city: string;
  state: string;
  country: string;
  oneline: string;
}

@Component({
  selector: "app-location-autocomplete",
  templateUrl: "./location-autocomplete.component.html",
  styleUrls: ["./location-autocomplete.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LocationAutocompleteComponent {
  @ViewChild("inputField") inputField?: ElementRef;
  @Input() placeholder = "No City Selected";
  @Input() control: FormControl = new FormControl("");
  @Input() oneLine = false;
  @Output() locationOut = new EventEmitter<location>();

  city = "";
  state = "";
  country = "";
  cityCheck = false;
  cityError = false;

  location?: location;
  place?: google.maps.places.PlaceResult;
  autocomplete?: google.maps.places.Autocomplete;

  retrieveLocation() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputField?.nativeElement,
      { fields: ["address_components", "formatted_address"] }
    );
    this.autocomplete.addListener("place_changed", async () => {
      this.place = this.autocomplete?.getPlace();
      try {
        this.city = "";
        this.state = "";
        this.country = "";
        this.cityCheck = false;

        for await (const component of this.place
          ?.address_components as google.maps.GeocoderAddressComponent[]) {
          const componentType = component.types[0];

          switch (componentType) {
            case "locality": {
              this.city = component.long_name;
              this.cityCheck = true;
              break;
            }
            case "administrative_area_level_1": {
              this.state = component.long_name;
              break;
            }
            case "country":
              this.country = component.long_name;
              break;
          }
        }
      } catch (error) {
        console.error("Undefined City or State");
      }

      this.location = {
        city: this.city,
        state: this.state,
        country: this.country,
        oneline: this.place?.formatted_address!,
      };

      if (!this.oneLine) {
        if (!this.cityCheck) {
          this.placeholder = "City not Selected. Please select a city!";
          this.cityError = true;
        }
      } else {
        if (this.inputField) {
          this.inputField.nativeElement.value = this.place?.formatted_address;
        }
      }
      this.locationOut.emit(this.location);
    });
  }
}
