<div class="profile-form-container">
  <form
    *ngIf="!loading"
    [formGroup]="form"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column"
  >
    <div
      class="profile-form"
      fxFlex.gt-sm="50%"
      fxFlex.lt-md="50%"
      fxLayoutAlign="start center"
      fxLayout="column"
    >
      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>First name</mat-label>
        <input formControlName="firstName" matInput placeholder="John" />
        <mat-error *ngIf="firstName.errors?.['required']"
          >First Name is required</mat-error
        >
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Email</mat-label>
        <input
          formControlName="email"
          matInput
          placeholder="JohnDoe@workstory.io"
        />
      </mat-form-field>
    </div>
    <div
      fxFlex.gt-sm="50%"
      fxFlex.lt-md="50%"
      fxLayoutAlign="start center"
      fxLayout="column"
    >
      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input formControlName="lastName" matInput placeholder="Doe" />
        <mat-error *ngIf="lastName.errors?.['required']"
          >Last Name is required</mat-error
        >
      </mat-form-field>
      <!-- <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Phone</mat-label>
       
        <input formControlName="phone" matInput placeholder="XXX XXXXXXX" />
        <mat-error *ngIf="phone.errors?.['required']"
          >Phone Number is required</mat-error
        >
        <mat-error *ngIf="phone.errors?.['minLength']"
          >Phone number must be at least 5 digits long.</mat-error
        >
      </mat-form-field> -->
      <ngx-mat-intl-tel-input
        [preferredCountries]="['us']"
        [enablePlaceholder]="true"
        [enableSearch]="true"
        name="phone"
        describedBy="phoneInput"
        formControlName="phone"
      ></ngx-mat-intl-tel-input>
      <mat-error *ngIf="phone.errors?.['required']"
        >Phone Number is required</mat-error
      >
    </div>
  </form>
</div>
<div flexLayout="row" style="padding: 10px">
  <button
    [disabled]="!form.valid"
    (click)="save()"
    mat-raised-button
    color="white"
  >
    Save
  </button>
</div>
