import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, take, switchMap } from "rxjs/operators";
import { STORAGE_ITEM } from "../utils/local-storage.utils";
import { AuthService } from "../services/auth/auth.service";
import { LocalstorageService } from "../services/local-storage/local-storage.service";
import { CloneHeaderService } from "./clone-header.service";

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private localStorage: LocalstorageService,
    private cloneHeaderService: CloneHeaderService
  ) {}

  isRefreshing = false;

  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          !request.url.includes("api/token/refresh")
        ) {
          return this.handle401Error(request, next);
        } else {
          return throwError(() => {
            return error;
          });
        }
      })
    ) as Observable<HttpEvent<any>>;
  }

  handle401Error(request: HttpRequest<unknown>, next: HttpHandler): any {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.localStorage.getItem(
        STORAGE_ITEM.refresh_token
      );

      if (refreshToken) {
        const accessToken = this.localStorage.getItem(
          STORAGE_ITEM.access_token
        );
        let payload = {
          refresh: refreshToken,
          access: accessToken,
        };
        return this.authService.refreshToken(payload).pipe(
          switchMap((res) => {
            this.isRefreshing = false;
            this.localStorage.setItem(STORAGE_ITEM.access_token, res.access);
            this.refreshTokenSubject.next(res.access);
            return next.handle(this.cloneHeaderService.cloneHeader(request));
          }),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {
              this.isRefreshing = false;
              return this.logout();
            } else {
              return throwError(() => {
                return err;
              });
            }
          })
        );
      }
      return this.logout();
    } else {
      return this.refreshTokenSubject.pipe(
        filter((res) => res != null),
        take(1),
        switchMap(() => {
          return next.handle(this.cloneHeaderService.cloneHeader(request));
        })
      );
    }
  }

  logout() {
    this.authService.logout(true);
    return throwError(() => {
      new Error("Unauthorized");
    });
  }
}
