<mat-list role="list">
  <mat-list-item
    [class.active-nav-item]="btn.btnText === selectedTab?.btnText"
    class="sidenav-item"
    *ngFor="let btn of btns"
    role="listitem"
  >
    <div
      *ngIf="!isTab; else onlyIcon"
      class="d-flex full-width"
      id="side-nav-item"
      (click)="route(btn, $event)"
    >
      <mat-icon fontSet="material-icons-outlined">{{ btn.icon }}</mat-icon>
      <p>
        {{ btn.btnText }}
      </p>
    </div>
    <ng-template #onlyIcon>
      <div
        #toolTip="matTooltip"
        class="only-icon"
        matTooltipClass="custom-tooltip"
        [matTooltip]="btn.btnText"
        matTooltipPosition="right"
        (click)="route(btn, $event); toolTip.toggle()"
        color="white"
      >
        <mat-icon fontSet="material-icons-outlined">{{ btn.icon }}</mat-icon>
      </div>
    </ng-template>
  </mat-list-item>
  <div (click)="onFeedback()" class="nav-footer header-subtitle">
    <mat-icon fontSet="material-icons-outlined">feedback</mat-icon>
    <span *ngIf="!isTab">Give feedback</span>
  </div>
</mat-list>
