<app-loading-screen *ngIf="!readyToRecord"></app-loading-screen>

<div class="video-record-card-container">
  <div *ngIf="readyToRecord" class="video-options-container">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="timer-container"
    >
      <ng-container *ngIf="!hasRecorded">
        <span class="time-left-indicator text-nowrap">TIME TO RECORD</span>
        <div>
          <countdown
            #timer
            [config]="timerConfig!"
            (event)="handleTimerEvent($event)"
          ></countdown>
        </div>

        <hr style="border-top: 1px solid rgb(98, 98, 98); width: 100%" />
        <span
          style="text-align: center; font-weight: 300"
          class="light-subtitle-text"
        >
          Your recording will stop when the timer hits zero. Record away!
        </span>
      </ng-container>
      <ng-container *ngIf="hasRecorded">
        <span class="time-left-indicator">VIDEO DURATION</span>
        <span *ngIf="videoLength" class="timer-text">{{
          videoLength * 1000 | date : "mm:ss" : "UTC"
        }}</span>
        <hr style="border-top: 1px solid rgb(98, 98, 98); width: 100%" />
      </ng-container>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-evenly" fxLayoutGap="8px">
      <button
        class="tw-btn-negative text-nowrap text-center"
        *ngIf="!isRecording && !hasRecorded && !disableAttempts"
        (click)="startRecording()"
      >
        Start Recording
      </button>
      <button
        class="tw-btn-negative text-nowrap justify-center"
        *ngIf="isRecording"
        (click)="stopRecording()"
      >
        Stop
      </button>
      <button
        class="tw-btn-secondary text-nowrap justify-center"
       
        *ngIf="hasRecorded && !disableAttempts"
        (click)="tryAgain()"
        style="border: 1px solid gray"
      >
        Try again
      </button>
      <button
        class="tw-btn-primary bg-[#28a745] hover:bg-green-600 text-nowrap justify-center"
        
        *ngIf="hasRecorded && !disableAttempts && !answerMode"
        (click)="nextEvent.emit()"
      >
        Next
      </button>
    </div>
  </div>
  <div class="video-container">
    <video
      [muted]="!hasRecorded"
      [class.d-none]="!hasRecorded"
      [controls]="true"
      class="video"
      id="videoC"
      #videoC
    ></video>
    <canvas [class.d-none]="hasRecorded" #canvas id="myCanvas">
      <mat-icon>playlist_play</mat-icon>
    </canvas>

    <mat-icon
      [class.vis-hidden]="!(!isRecording && !hasRecorded)"
      (click)="toggleOpened()"
      class="canvas-button"
    >
      auto_awesome
    </mat-icon>

    <div
      *ngIf="showInputOptions"
      fxLayout="row"
      fxLayoutGap="14px"
      style="margin-top: 14px"
    >
      <app-outline-select
        style="width: 250px"
        [dropUp]="true"
        [curvedStyle]="true"
        icon="headphones"
        [options]="audioDevices"
        [default]="audioDevices[0]"
        (selectEvent)="handleAudioInputChange($event)"
      ></app-outline-select>

      <app-outline-select
        [dropUp]="true"
        style="width: 250px"
        [curvedStyle]="true"
        icon="videocam"
        [options]="cameraDevices"
        [default]="cameraDevices[0]"
        (selectEvent)="handleCameraInputChange($event)"
      ></app-outline-select>
    </div>
  </div>
</div>

<canvas #imageCanvas style="display: none"></canvas>

<ng-template #drawerContent>
  <div class="blur-options-container">
    <span class="field-header">Blur</span>

    <div class="blur-options-row">
      <div
        [class.active]="blurAmount === 0"
        (click)="noBlur()"
        #toolTip="matTooltip"
        matTooltip="No blur"
        class="blur-options-button"
      >
        <mat-icon fontSet="material-icons">blur_off</mat-icon>
      </div>
      <div
        [class.active]="blurAmount === 5"
        #toolTip="matTooltip"
        matTooltip="Low blur"
        class="blur-options-button"
        (click)="medBlur()"
      >
        <mat-icon fontSet="material-icons">deblur</mat-icon>
      </div>
      <div
        [class.active]="blurAmount === 10"
        #toolTip="matTooltip"
        matTooltip="High blur"
        class="blur-options-button"
        (click)="highBlur()"
      >
        <mat-icon fontSet="material-icons">lens_blur</mat-icon>
      </div>
    </div>
  </div>
  <div class="virtual-backgrounds-container">
    <span class="field-header">Virtual backgrounds</span>

    <div class="virtual-backgrounds-row">
      <div
        (click)="setBackground(0)"
        class="virtual-backgrounds-button img-btn"
      >
        <img
          [src]="'../assets/bg' + 0 + '.jpg'"
          alt=""
          style="width: 120px; height: 90px"
        />
      </div>
      <div
        (click)="setBackground(2)"
        class="virtual-backgrounds-button img-btn"
      >
        <img
          [src]="'../assets/bg' + 2 + '.jpg'"
          alt=""
          style="width: 120px; height: 90px"
        />
      </div>
      <div
        (click)="setBackground(3)"
        class="virtual-backgrounds-button img-btn"
      >
        <img
          [src]="'../assets/bg' + 3 + '.jpg'"
          alt=""
          style="width: 120px; height: 90px"
        />
      </div>
    </div>
  </div>
</ng-template>
