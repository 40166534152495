import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { isMobileWidth, isTabWidth } from "src/app/core/utils/responsive.util";
import {
  WORK_TYPE_CHOICES,
  JOB_TYPE_CHOICES,
  EXPERIENCE_LEVEL_CHOICES,
} from "src/app/recruiter/dashboard/pages/jobs/utils";

@Component({
  selector: "app-job-search-field",
  templateUrl: "./job-search-field.component.html",
  styleUrls: ["./job-search-field.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class JobSearchFieldComponent {
  WORK_TYPE_CHOICES = WORK_TYPE_CHOICES;
  JOB_TYPE_CHOICES = JOB_TYPE_CHOICES;
  EXPERIENCE_LEVEL_CHOICES = EXPERIENCE_LEVEL_CHOICES;

  @Input() form!: FormGroup;

  @Input() createJobButton?: TemplateRef<any>;
  @Input() shareJobsButton?: TemplateRef<any>;

  isTabView = isTabWidth;
  clearFilters() {
    for (const field in this.form.controls) {
      this.form.controls[field].setValue("");
    }
  }

  get experience_level() {
    return this.form.controls["experience_level"] as FormControl;
  }

  get job_type() {
    return this.form.controls["job_type"] as FormControl;
  }

  get work_type() {
    return this.form.controls["work_type"] as FormControl;
  }
}
