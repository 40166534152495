import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout.component";
import { FooterModule } from "../footer/footer.module";
import { RouterModule } from "@angular/router";
import { AngularMaterialModule } from "src/app/angular-material.module";
import { HeaderModule } from "../header/header.module";

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    RouterModule,
    AngularMaterialModule,
  ],
  exports: [LayoutComponent, HeaderModule],
})
export class LayoutModule {}
