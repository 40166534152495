import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from "@angular/core";
import { Job } from "src/app/recruiter/dashboard/pages/jobs/models/job.model";
import {
  getExperienceLevel,
  getWorkType,
  getJobType,
  DefaultCompanyLogo,
} from "src/app/recruiter/dashboard/pages/jobs/utils";

@Component({
  selector: "app-job-detail",
  templateUrl: "./job-detail.component.html",
  styleUrls: ["./job-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class JobDetailComponent {
  @Input() job!: Job;
  @Input() editButton?: TemplateRef<any>;
  @Input() deleteButton?: TemplateRef<any>;
  @Input() applyButton?: TemplateRef<any>;
  @Output() applyEvent = new EventEmitter();

  defaultLogo = DefaultCompanyLogo;

  getExperienceLevel = getExperienceLevel;
  getWorkType = getWorkType;
  getJobType = getJobType;

  routeToCompany() {
    if (this.job.company_url) {
      window.open(this.job.company_url, "_blank");
    }
  }

  public get expiryDate() {
    const createdDt = new Date(this.job.published_dt);
    createdDt.setMonth(createdDt.getMonth() + 1);
    return createdDt;
  }

  hideSidebar() {
    if (window.innerWidth < 1332) return true;
    return false;
  }

  hideIcons() {
    if (window.innerWidth < 851) return true;
    return false;
  }

  public get daysToApplyOrExpired() {
    const oneDay = 24 * 60 * 60 * 1000;
    const publishedDt = new Date(this.job!.published_dt);
    publishedDt.setMonth(publishedDt.getMonth() + 1);
    const days = this.DaysBetween(publishedDt, new Date());
    if (days > 31) {
      return "Job Expired";
    } else return `${days} ${days > 1 ? "days" : "day"} left to apply`;
  }

  public get isJobExpired() {
    const oneDay = 24 * 60 * 60 * 1000;
    const publishedDt = new Date(this.job!.published_dt);
    publishedDt.setMonth(publishedDt.getMonth() + 1);
    const days = this.DaysBetween(publishedDt, new Date());
    if (days > 31) {
      return true;
    }
    return false;
  }

  DaysBetween(StartDate, EndDate) {
    // The number of milliseconds in all UTC days (no DST)
    const oneDay = 1000 * 60 * 60 * 24;

    // A day in UTC always lasts 24 hours (unlike in other time formats)
    const start = Date.UTC(
      EndDate.getFullYear(),
      EndDate.getMonth(),
      EndDate.getDate()
    );
    const end = Date.UTC(
      StartDate.getFullYear(),
      StartDate.getMonth(),
      StartDate.getDate()
    );

    // so it's safe to divide by 24 hours
    return Math.abs((start - end) / oneDay);
  }

  apply() {
    this.applyEvent.emit();
  }
}
