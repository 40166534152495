import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, throwError } from "rxjs";
import { JobApplication } from "src/app/recruiter/dashboard/pages/application-manager/models/application.model";
import { environment } from "src/environments/environment";
import { Job } from "../../../recruiter/dashboard/pages/jobs/models/job.model";
import { PaginatedResponse } from "../../models/paginated-payload.model";
import { IRecruiterMessage } from "src/app/shared/components/recruiter-profile/recruiter-profile.component";

@Injectable({
  providedIn: "root",
})
export class JobService {
  private recruiterApiBaseUrl = `${environment.baseUrl}recruiter/api/`;
  private candidateApiBaseUrl = `${environment.baseUrl}candidate/api/`;
  private applicationApiBaseUrl = `${environment.baseUrl}application/api/`;

  constructor(private http: HttpClient) {}

  getJobs() {
    return this.http.get<PaginatedResponse<Job>>(
      this.recruiterApiBaseUrl + "job/"
    );
  }

  getJob(id: string) {
    return this.http.get<Job>(this.recruiterApiBaseUrl + "job/" + id);
  }

  deleteJob(id: string) {
    return this.http.delete<any>(this.recruiterApiBaseUrl + "job/" + id);
  }

  saveJob(payload: Object) {
    return this.http.post<Job>(this.recruiterApiBaseUrl + "job/", payload);
  }

  candidateGetJob(id: string) {
    return this.http.get<Job>(this.candidateApiBaseUrl + "job/" + id);
  }

  searchJobs(
    searchTerm?: string,
    experience_level?: string,
    work_type?: string,
    job_type?: string
  ) {
    let queryParams = new HttpParams();
    if (searchTerm) {
      queryParams = queryParams.append("search", searchTerm);
    }
    if (experience_level) {
      queryParams = queryParams.append("experience_level", experience_level);
    }
    if (work_type) {
      queryParams = queryParams.append("work_type", work_type);
    }
    if (job_type) {
      queryParams = queryParams.append("job_type", job_type);
    }
    return this.http.get<PaginatedResponse<Job>>(
      `${this.recruiterApiBaseUrl}job/`,
      {
        params: queryParams,
      }
    );
  }

  getPublishedJobs() {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("is_published", true);
    return this.http.get<PaginatedResponse<Job>>(
      `${this.recruiterApiBaseUrl}job/`,
      {
        params: queryParams,
      }
    );
  }

  getUnPublishedJobs() {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("is_published", false);
    return this.http.get<PaginatedResponse<Job>>(
      `${this.recruiterApiBaseUrl}job/`,
      {
        params: queryParams,
      }
    );
  }

  candidateJobPageSearch(pageIndex: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("page", pageIndex);
    return this.http.get<PaginatedResponse<Job>>(
      `${this.candidateApiBaseUrl}job/`,
      {
        params: queryParams,
      }
    );
  }

  candidateSearchJobs(
    searchTerm?: string,
    experience_level?: string,
    work_type?: string,
    job_type?: string,
    recruiter?: string
  ) {
    let queryParams = new HttpParams();
    if (searchTerm) {
      queryParams = queryParams.append("search", searchTerm);
    }
    if (experience_level) {
      queryParams = queryParams.append("experience_level", experience_level);
    }
    if (work_type) {
      queryParams = queryParams.append("work_type", work_type);
    }
    if (job_type) {
      queryParams = queryParams.append("job_type", job_type);
    }
    if (recruiter) {
      queryParams = queryParams.append("recruiter__user__id", recruiter);
    }
    return this.http.get<PaginatedResponse<Job>>(
      `${this.candidateApiBaseUrl}job/`,
      {
        params: queryParams,
      }
    );
  }

  jobPaginator(url: string) {
    return this.http.get<PaginatedResponse<Job>>(url);
  }

  jobAppPaginator(url: string) {
    return this.http.get<PaginatedResponse<JobApplication>>(url);
  }

  updateJob(payload: Object, id: string) {
    return this.http.put<Job>(
      this.recruiterApiBaseUrl + "job/" + id + "/",
      payload
    );
  }

  publishJob(payload: Object, id: string) {
    return this.http.put<Job>(
      this.recruiterApiBaseUrl + "job/" + id + "/",
      payload
    );
  }

  delistJob(payload: Object, id: string) {
    return this.http.put<Job>(
      this.recruiterApiBaseUrl + "job/" + id + "/",
      payload
    );
  }

  applyJob(payload: Object) {
    return this.http.post<any>(
      `${this.applicationApiBaseUrl}applications/`,
      payload
    );
  }

  messageRecruiter(message: IRecruiterMessage) {
    return this.http.post<any>(
      `${this.candidateApiBaseUrl}job/message_recruiter/`,
      message
    );
  }
}
