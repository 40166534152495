import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Observable, fromEvent, merge, Subject, Subscription } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class IdleTimeoutService {
  private idle: Observable<any> = new Observable();

  private timeOutSeconds: number = environment.idleTimeInMinutes * 60;
  private idleSubscription: Subscription = new Subscription();

  public expired: Subject<boolean> = new Subject<boolean>();

  private window: Window;

  private interval?: any;
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView!;
  }

  public startWatching(): Observable<any> {
    this.idle = merge(
      fromEvent(this.document, "mousemove"),
      fromEvent(this.document, "click"),
      fromEvent(this.document, "mousedown"),
      fromEvent(this.document, "keypress"),
      fromEvent(this.document, "DOMMouseScroll"),
      fromEvent(this.document, "mousewheel"),
      fromEvent(this.document, "touchmove"),
      fromEvent(this.document, "MSPointerMove"),
      fromEvent(this.window, "mousemove"),
      fromEvent(this.window, "resize")
    );

    this.idleSubscription = this.idle.subscribe(() => {
      this.resetTimer();
    });
    this.startTimer();
    return this.expired;
  }

  private startTimer() {
    var self = this;
    this.interval = this.document.defaultView!.setInterval(() => {
      self.timeOutSeconds--;
      if (self.timeOutSeconds == 0) {
        self.resetTimer();
        self.expired.next(true);
      }
    }, 1000);
  }

  public resetTimer() {
    this.document.defaultView!.clearInterval(this.interval);
    this.timeOutSeconds = environment.idleTimeInMinutes * 60;
    this.startTimer();
  }

  public stopTimer() {
    this.document.defaultView!.clearInterval(this.interval);
    this.idleSubscription.unsubscribe();
  }
}
