<div *ngIf="application || applicant; else noSelection">
  <div *ngIf="application">
    <div class="header">
      {{ application.job.job_title }}
    </div>
  </div>
  <div *ngIf="applicant">
    <div fxLayout="column">
      <div fxLayout="row" class="header" fxLayoutAlign="space-between">
        <div fxLayout="row">
          <img
            style="width: 50px; height: 50px; border-radius: 50%"
            [src]="applicant.candidate.profile_picture"
            alt=""
          />
          <div>{{ applicant.candidate.name }}</div>
        </div>
        <div fxLayout="row">
          <div class="icon-caption">
            <img
              src="https://cdn-icons-png.flaticon.com/512/6588/6588143.png"
            />
            <span class="caption">Request CV</span>
          </div>
          <div class="icon-caption">
            <img src="https://static.thenounproject.com/png/1593109-200.png" />
            <span class="caption">Media</span>
          </div>
        </div>
      </div>

      <div class="chat-box">
        <div class="messages"></div>
        <div class="chat" fxLayout="row">
          <mat-form-field floatLabel="always" class="example-full-width">
            <mat-label>Chat</mat-label>
            <input matInput />
          </mat-form-field>
          <mat-icon>send</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noSelection>
  <div style="height: max-content" fxLayoutAlign="center center">
    <h1 style="text-align: center">Please Select an Item</h1>
  </div>
</ng-template>
