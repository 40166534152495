import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VideoComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { video: string }) {}
}
