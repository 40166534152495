export const ROUTER_UTILS = {
  portfolio: {
    root: "portfolio",
  },
  candidate: {
    root: "candidate",
    dashboard: {
      home: { root: "home" },
      videos: { root: "videos", createVideo: "create-video" },
      playlist: {
        root: "playlists",
        createPlaylist: "create-playlist",
        edit: "edit-playlist",
      },
      settings: { root: "settings" },
      profile: { root: "profile" },
      resumes: { root: "resumes", upload: "upload" },
      applicationManager: {
        root: "myApplications",
        videoReply: "question",
        QAPortal: "questions",
        application: "application",
      },
      jobs: {
        root: "jobs",
        jobDetail: "job-detail",
        jobApply: "job-apply",
        recruiterProfile: "recruiter",
      },
      help: { root: "help" },
      candidateNotification: { root: "candidate-notification" },
    },
  },
  recruiter: {
    root: "recruiter",
    dashboard: {
      home: {
        root: "home",
        detailCandidateSearch: "search",
      },
      profile: { root: "profile" },
      jobs: { root: "jobs", createJob: "create-job", jobDetail: "job-detail" },
      applicationManager: {
        root: "application-manager",
        applicantList: "applicants",
        application: "application",
        applicantProfile: "applicant-profile",
        QAPortal: "questions",
      },
      settings: { root: "settings" },
      recruiterNotification: { root: "recruiter-notification" },
      help: { root: "help" },
    },
  },

  auth0Callback: {
    root: "auth0-callback",
    candidateCallback: "candidate-auth0-callback",
    recruiterCallback: "recruiter-auth0-callback",
  },

  public: {
    root: "public",
    shareVideo: "video",
    shareJob: "job",
    shareAllJobs: "jobs",
    playlist: "playlist",
  },
};

export const contsructURL = (URLs: string[]): string => {
  let constructedUrl = "/";
  URLs.forEach((url) => {
    constructedUrl += url + "/";
  });
  return constructedUrl.substring(0, constructedUrl.length - 1);
};

export const constructShareVideoUrl = () => {
  return `${ROUTER_UTILS.public.root}/${ROUTER_UTILS.public.shareVideo}`;
};

export const constructShareJobUrl = () => {
  return `${ROUTER_UTILS.public.root}/${ROUTER_UTILS.public.shareJob}`;
};

export const constructSharePlaylistUrl = () => {
  return `${ROUTER_UTILS.public.root}/${ROUTER_UTILS.public.playlist}`;
};

export const constructShareAllJobUrl = () => {
  return `${ROUTER_UTILS.public.root}/${ROUTER_UTILS.public.shareAllJobs}`;
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
