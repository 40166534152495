import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CV } from "../../models/candidate-cv.model";
import { CandidateProfile } from "../../models/candidate-profile.model";
import { VideoPlaylist } from "src/app/core/models/video-api-payloads/video-playlist.model";
import { Application } from "express";

export interface IResumeDelete {
  playlists: VideoPlaylist[];
  applications: Application[];
  canDelete: boolean;
}

@Injectable({
  providedIn: "root",
})
export class CandidateService {
  private candidateApiBaseUrl = `${environment.baseUrl}candidate/api/`;

  constructor(private http: HttpClient) {}

  getProfile() {
    return this.http.get<CandidateProfile>(
      this.candidateApiBaseUrl + "profile/"
    );
  }

  updateProfile(
    city: string,
    country: string,
    state: string,
    fName: string,
    lName: string,
    phone: string,
    profileSummary?: string,
    linkedInUrl?: string,
    picture?: Blob,
    languages?: string,
    experience?: string,
    industry?: string
  ) {
    let formData = new FormData();
    if (picture) {
      formData.append("profile_picture", picture, "profile_picture");
    }
    formData.append("first_name", fName);
    formData.append("last_name", lName);
    formData.append("phone_number", phone);
    formData.append("profile_summary", profileSummary as string);
    formData.append("linkedin_url", linkedInUrl as string);
    formData.append("city", city as string);
    formData.append("country", country as string);
    formData.append("state", state as string);
    formData.append("languages", languages as string);
    formData.append("experience", experience as string);
    formData.append("industry", industry as string);

    return this.http.put<CandidateProfile>(
      this.candidateApiBaseUrl + "profile/",
      formData
    );
  }

  saveLocationSettings(
    city: string,
    country: string,
    state: string,
    fName: string,
    lName: string,
    phone: string,
    profileSummary: string,
    linkedInUrl: string
  ) {
    let formData = new FormData();
    formData.append("city", city);
    formData.append("state", state);
    formData.append("country", country);
    formData.append("first_name", fName);
    formData.append("last_name", lName);
    formData.append("phone_number", phone);
    formData.append("profile_summary", profileSummary as string);
    formData.append("linkedin_url", linkedInUrl as string);
    return this.http.put<CandidateProfile>(
      this.candidateApiBaseUrl + "profile/",
      formData
    );
  }

  updateSearchability(visibility: boolean) {
    let formData = new FormData();
    formData.append("searchable", visibility.toString());

    return this.http.put<CandidateProfile>(
      this.candidateApiBaseUrl + "profile/",
      formData
    );
  }

  uploadCv(cv: Blob, name: string) {
    let formData = new FormData();

    formData.append("resume", cv);

    formData.append("name", name);

    return this.http.post<CV>(this.candidateApiBaseUrl + "resume/", formData);
  }

  deleteCV(id: number, force: boolean) {
    const options = {
      body: {
        force: force,
      },
    };
    return this.http.delete<IResumeDelete>(
      this.candidateApiBaseUrl + "resume/" + id,
      options
    );
  }

  getCVs(): Observable<CV[]> {
    return this.http.get<CV[]>(this.candidateApiBaseUrl + "resume/");
  }
}
