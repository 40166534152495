import { Component } from '@angular/core';

@Component({
  selector: 'app-tile-grid-skeleton',
  templateUrl: './tile-grid-skeleton.component.html',
  styleUrls: ['./tile-grid-skeleton.component.scss']
})
export class TileGridSkeletonComponent {

}
