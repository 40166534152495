import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

export interface ISelectOptions {
  display: string;
  value: string | boolean | number;
  onClick?: Function;
}

@Component({
  selector: "app-outline-select",
  templateUrl: "./outline-select.component.html",
  styleUrls: ["./outline-select.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OutlineSelectComponent implements OnInit {
  @Output() selectEvent = new EventEmitter();
  @Input() options: ISelectOptions[] = [];
  @Input() default?: ISelectOptions;
  @Input() hint: string = "";
  @Input() submitted = false;
  @Input() placeholder: string = "";
  @Input() clearable: boolean = false;
  @Input() dropUp: boolean = false;
  @Input() control: UntypedFormControl = new UntypedFormControl("");
  @Input() title: string = "";
  @Input() noMargin: boolean = false;
  @Input() icon: string = "";
  @Input() curvedStyle: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  opened = false;
  selectedOption?: ISelectOptions;

  ngOnInit(): void {
    this.control.valueChanges.subscribe((val) => {
      this.updateSelectedOption();
    });

    if (this.default) {
      this.control.setValue(this.default.value);
      this.selectedOption = this.default;
    }
  }

  emitSelect(opt: ISelectOptions) {
    if (opt.onClick) {
      opt.onClick();
      return;
    }
    this.opened = false;
    this.control.setValue(opt.value);
    this.selectedOption = opt;
    this.selectEvent.emit(opt);
  }

  updateSelectedOption() {
    this.selectedOption = this.options.find(
      (opt) => opt.value == this.control.value
    );
  }

  onFocusOutEvent() {
    setTimeout(() => {
      this.opened = false;
    }, 100);
  }

  handleClick(btn: HTMLElement) {
    this.opened = !this.opened;
    if (!this.opened) {
      btn.blur();
    }
  }

  clear() {
    this.opened = false;
    this.control.setValue("");
    this.selectedOption = undefined;
    this.selectEvent.emit(null);
  }
}
