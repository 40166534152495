<div class="empty-state-container">
  <img src="{{ imgSource }}" alt="" />
  <h3>{{ heading }}</h3>
  <p>{{ text }}</p>
  <br />

  <button *ngIf="showButton" class="tw-btn-secondary" (click)="onClick()">
    {{ buttonText }}
  </button>
</div>
