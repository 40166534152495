import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface IConfirmationPrompt {
  heading: string;
  promptMessage: string;
  actionButtonText: string;
  action: string;
  data?: any;
  isResumeDelete?: boolean;
}

@Component({
  selector: "app-confirmation-prompt",
  templateUrl: "./confirmation-prompt.component.html",
  styleUrls: ["./confirmation-prompt.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationPromptComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationPromptComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      prompt: IConfirmationPrompt;
    }
  ) {}

  onClick() {
    this.dialogRef.close(true);
  }
}
