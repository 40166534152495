<div class="container">
  <div fxLayout="column">
    <div fxLayoutGap="5px" fxLayout="row" class="stepper-header">
      <div
        fxLayoutAlign="center center"
        fxLayoutGap="5px"
        fxLayout="row"
        class="step active-step"
      >
        <div class="numberCircle">
          <span>1</span>
        </div>
        <span>Record Video</span>
      </div>
      <hr [class.active-hr]="step >= 1" />
      <div
        fxLayoutAlign="center center"
        fxLayoutGap="5px"
        fxLayout="row"
        class="step"
        [class.active-step]="step >= 1"
      >
        <div class="numberCircle">
          <span>2</span>
        </div>
        <span>Video Detail</span>
      </div>
      <hr [class.active-hr]="step >= 2" />
      <div
        fxLayoutAlign="center center"
        fxLayoutGap="5px"
        fxLayout="row"
        class="step"
        [class.active-step]="step >= 2"
      >
        <div class="numberCircle">
          <span>3</span>
        </div>
        <span>Video Upload</span>
      </div>
    </div>
    <br />
    <ng-container *ngTemplateOutlet="getStepTemplateRef()"></ng-container>
  </div>
</div>

<ng-template #videoRecording>
  <div *ngIf="!videoRecorderError; else videoError">
    <app-video-record-card
      [form]="videoForm"
      [answerMode]="answerMode ? true : false"
      [maxLength]="answerMode ? question!.response_time_limit! * 60 : 1800"
      (attemptEvent)="attemptEventHandler()"
      (recordEvent)="recordEventHandler()"
      (nextEvent)="next()"
      [disableAttempts]="disableAttempts"
      [triggerRecord]="triggerRecordObservable.asObservable()"
      (videoError)="videoRecorder($event)"
    ></app-video-record-card>
  </div>
</ng-template>

<ng-template #videoDetails [formGroup]="videoForm" #editVideo>
  <mat-card class="edit-video">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h2 style="margin: 0">Video Details</h2>
      <div fxLayoutAlign="center start" fxLayout="column" class="video-thumb">
        <!-- <img [src]="video?.candidate_video?.thumbnail" /> -->
        <button
          (click)="playVideo()"
          mat-button
          [ngStyle]="{
            'background-image': 'url(' + this.videoThumb + ')'
          }"
        >
          <span style="color: white" class="material-icons-outlined">
            play_circle_filled
          </span>
        </button>
      </div>
    </div>
    <br />
    <mat-divider></mat-divider>
    <br />
    <app-outline-input-box
      [submitted]="submitted"
      [control]="videoForm.getVideoTitle()"
      title="Title"
      hint="250 characters max"
      tooltipText="It’s a good idea to include keywords your audience is likely to use when looking for videos like yours."
    ></app-outline-input-box>
    <br />
    <app-outline-input-box
      [submitted]="submitted"
      title="Description"
      [control]="videoForm.getDescription()"
      hint="900 characters max"
      [largeText]="true"
      tooltipText="Writing descriptions with keywords can help viewers find your videos more easily through search. You can give an overview of your video and place keywords in the beginning of the description."
    ></app-outline-input-box>
    <br />
    <div class="dashed-box" fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayoutGap="10px" fxLayout="row">
        <button
          style="background-color: rgba(35, 168, 224, 0.04)"
          mat-mini-fab
          disabled
          aria-label="Example icon button with a home icon"
        >
          <span class="material-icons-outlined" style="color: #23a8e1">
            upload_file
          </span>
        </button>
        <div fxLayout="column">
          <span class="title">Upload a picture for you video thumbnail</span>

          <span class="subtitle">SVG, PNG,JPG or GIF</span>
        </div>
      </div>

      <div style="width: 100px">
        <input
          #fileUpload
          type="file"
          (change)="onFileSelected($event)"
          style="display: none"
          accept="image/*"
        />
        <mat-chip-list>
          <mat-basic-chip (click)="fileUpload.click()"
            >Browse</mat-basic-chip
          >
        </mat-chip-list>
      </div>
    </div>

    <br />
    <br />
    <app-video-tag [form]="videoForm"></app-video-tag>
    <br />
    <div fxLayoutGap="10px" fxLayout="column" class="slider-list">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div style="width: 70%" class="toggle">
          <h3>Public</h3>

          <p class="subtitle">
            Public videos are accessible for users on our platform aswell as
            anyone who has the link to your video.
          </p>
        </div>
        <mat-slide-toggle formControlName="public"> </mat-slide-toggle>
      </div>
      <div
        style="align-items: center"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div style="width: 70%" class="toggle">
          <h3>Intro</h3>

          <p class="subtitle">
            Intro videos are highlighted at the top of your page.
          </p>
        </div>

        <mat-slide-toggle formControlName="intro"> </mat-slide-toggle>
      </div>
    </div>
    <br />
    <div fxLayout="row" fxLayoutAlign="space-between">
      <button (click)="prev()" class="tw-btn-secondary">Back</button>
      <button (click)="next()" class="tw-btn-primary">Next</button>
    </div>
  </mat-card>
</ng-template>
<ng-template #videoUpload [formGroup]="videoForm">
  <mat-card class="edit-video">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h2 style="margin: 0">Video Upload</h2>
    </div>
    <br />
    <mat-divider></mat-divider>
    <br />
    <div fxLayout="row" fxLayoutAlign="center center">
      <video
        controls
        style="width: 90%; border-radius: 4px"
        [src]="videoSrc"
      ></video>
    </div>

    <br />
    <br />
    <div fxLayout="row" fxLayoutAlign="start">
      <div fxLayout="column">
        <h3 class="field-header">Title</h3>
        <p class="text-content">{{ videoForm.getVideoTitle().value }}</p>
      </div>
    </div>
    <br />
    <div fxLayout="row" fxLayoutAlign="start">
      <div fxLayout="column">
        <h3 class="field-header">Description</h3>
        <p class="text-content">{{ videoForm.getDescription().value }}</p>
      </div>
    </div>

    <br />
    <div
      *ngIf="videoForm.getTags().value.length"
      fxLayout="row"
      fxLayoutAlign="start"
    >
      <div fxLayout="column">
        <h3 class="field-header">Tags</h3>
        <mat-chip-list>
          <mat-basic-chip *ngFor="let tag of videoForm.getTags().value">{{
            tag.name
          }}</mat-basic-chip>
        </mat-chip-list>
      </div>
    </div>
    <br />

    <div fxLayoutGap="10px" fxLayout="column" class="slider-list">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div style="width: 70%" class="toggle">
          <h3>Public</h3>
          <p class="subtitle">
            Public videos are accessible for users on our platform aswell as
            anyone who has the link to your video.
          </p>
        </div>
        <mat-slide-toggle formControlName="public"> </mat-slide-toggle>
      </div>
      <div
        style="align-items: center"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div style="width: 70%" class="toggle">
          <h3>Intro</h3>
          <p class="subtitle">
            Intro videos are highlighted at the top of your page.
          </p>
        </div>

        <mat-slide-toggle formControlName="intro"> </mat-slide-toggle>
      </div>
    </div>
    <br />
    <div fxLayout="row" fxLayoutAlign="space-between">
      <button (click)="prev()" class="tw-btn-negative">Cancel</button>
      <button (click)="onSubmit()" class="tw-btn-primary">
        <mat-icon *ngIf="uploading"
          ><mat-spinner diameter="20"> </mat-spinner
        ></mat-icon>
        Upload
      </button>
    </div>
  </mat-card>
</ng-template>

<ng-template #loader>
  <app-loading-screen></app-loading-screen>
</ng-template>
<ng-template #videoError>
  <div class="videoError">
    <img src="../../../../assets/img/webcam-error.svg" alt="" />
    <h3>Video Recorder Error</h3>
    <ul>
      <li>
        Check if the website has permission to use your camera. Look for a small
        camera icon in the address bar and allow access if needed.
      </li>
      <li>
        Make sure your web browser is up to date for better webcam support.
      </li>
      <li>Ensure your webcam is plugged in and working properly.</li>
      <li>
        Turn off any extra tools or add-ons in your browser that might cause
        issues.
      </li>
      <li>Shut down other apps using the webcam before using the web app.</li>
      <li>
        Ensure a strong and stable internet connection for better webcam
        performance.
      </li>
    </ul>
    <button class="tw-btn-secondary" (click)="routeToVideos()">Go Back</button>
  </div>
</ng-template>
