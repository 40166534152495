import { Component, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute } from "@angular/router";
import { debounceTime, distinctUntilChanged, filter, switchMap } from "rxjs";
import { JobService } from "src/app/core/services/job/job.service";
import { decodeParam } from "src/app/core/utils/paramEncoder";
import { ROUTER_UTILS } from "src/app/core/utils/router.utils";
import { RecruiterProfile } from "src/app/public/models/recruiter-jobs.model";
import { Job } from "src/app/recruiter/dashboard/pages/jobs/models/job.model";
import { DialogService } from "../../services/dialog/dialog.service";

export interface IRecruiterMessage {
  message: string;
  recruiter: string;
}
@Component({
  selector: "app-recruiter-profile",
  templateUrl: "./recruiter-profile.component.html",
  styleUrls: ["./recruiter-profile.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RecruiterProfileComponent {
  searchForm = new FormGroup({
    search: new FormControl(""),
    experience_level: new FormControl(""),
    job_type: new FormControl(""),
    work_type: new FormControl(""),
  });
  jobs?: Job[];
  loading = true;
  next?: string;
  prev?: string;
  count?: string;
  pageIndex = 0;
  recruiter?: RecruiterProfile;
  searching = false;
  recruiterId?: string;
  breadcrumb = [
    {
      display: "Home",
      route: [
        ROUTER_UTILS.candidate.root,
        ROUTER_UTILS.candidate.dashboard.home.root,
      ],
    },
    {
      display: "Recruiter Profile",
      route: [
        ROUTER_UTILS.candidate.root,
        ROUTER_UTILS.candidate.dashboard.jobs.recruiterProfile,
      ],
    },
  ];

  constructor(
    private jobService: JobService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService
  ) {
    this.activatedRoute.queryParams.subscribe((id) => {
      this.recruiterId = decodeParam(id["id"]);
      this.initialize();
    });
  }

  private initialize(): void {
    this.loading = true;
    this.jobService
      .candidateSearchJobs(
        undefined,
        undefined,
        undefined,
        undefined,
        this.recruiterId
      )
      .subscribe((res) => {
        this.next = res.next;
        this.jobs = res.results;
        this.count = res.count;
        this.recruiter = this.jobs[0].recruiter;
        this.loading = false;
      });

    this.searchForm?.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        switchMap((searchTerm) => {
          this.searching = true;
          return this.jobService.candidateSearchJobs(
            searchTerm["search"] as string,
            searchTerm["experience_level"] as string,
            searchTerm["work_type"] as string,
            searchTerm["job_type"] as string,
            this.recruiterId
          );
        })
      )
      .subscribe((res) => {
        this.next = res.next;
        this.prev = res.previous;
        this.jobs = res.results;
        this.count = res.count;
        this.pageIndex = 0;
        this.searching = false;
      });
  }

  messageRecruiter(): void {
    this.dialogService.openMessageRecruiterDialog().subscribe((res: string) => {
      if (!res) {
        return;
      }
      const message: IRecruiterMessage = {
        message: res,
        recruiter: this.recruiterId as string,
      };

      this.jobService.messageRecruiter(message).subscribe();
    });
  }

  public get candidateString(): string {
    return Number(this.recruiter?.interviewee_count) > 1
      ? "Candidates"
      : "Candidate";
  }

  public get postedJobsString(): string {
    return Number(this.recruiter?.posted_job_count) > 1
      ? `${this.recruiter?.posted_job_count} Posted Jobs`
      : `${this.recruiter?.posted_job_count} Posted Job`;
  }

  onPaginateChange(page: PageEvent) {
    this.searching = true;
    if (page.pageIndex - page.previousPageIndex! > 0) {
      this.jobService.jobPaginator(this.next as string).subscribe((res) => {
        this.next = res.next;
        this.prev = res.previous;
        this.jobs = res.results;
        this.count = res.count;
        this.pageIndex += 1;
        this.searching = false;
      });
    } else {
      this.jobService.jobPaginator(this.prev as string).subscribe((res) => {
        this.next = res.next;
        this.prev = res.previous;
        this.jobs = res.results;
        this.count = res.count;
        this.pageIndex -= 1;
        this.searching = false;
      });
    }
  }
}
