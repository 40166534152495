import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { emit } from 'process';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmptyStateComponent {
  @Input() heading?: string = "";
  @Input() text?: string = "";
  @Input() imgSource?: string = "";
  @Input() buttonText: string = "";
  @Input() buttonClick?: string = "";
  @Input() showButton?: boolean = false;
  @Output() buttonControl = new EventEmitter();

  onClick(){
    this.buttonControl.emit();
  }
  
}
