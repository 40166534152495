import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
  OnDestroy,
  TemplateRef,
} from "@angular/core";

import {
  CountdownComponent,
  CountdownConfig,
  CountdownEvent,
} from "ngx-countdown";
import { Observable } from "rxjs";
import { VideoForm } from "src/app/candidate/dashboard/pages/videos/forms/video-input-form";

import * as bodySegmentation from "@tensorflow-models/body-segmentation";
import { animate, style, transition, trigger } from "@angular/animations";
import { Router } from "@angular/router";
import { ISelectOptions } from "../../outline-select/outline-select.component";
import {
  DrawerService,
  IDrawerConfig,
} from "src/app/shared/services/drawer/drawer.service";

@Component({
  selector: "app-video-record-card",
  templateUrl: "./video-record-card.component.html",
  styleUrls: ["./video-record-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VideoRecordCardComponent implements AfterViewInit, OnDestroy {
  private _form!: VideoForm;

  @Input() set form(value: VideoForm) {
    this._form = value;
  }
  @Input() maxLength: number = 1800;
  @Input() started: boolean = false;
  @Input() hideBackground: boolean = false;
  @Input() disableAttempts: boolean = false;
  @Input() answerMode: boolean = false;
  @Input() triggerRecord?: Observable<void>;

  @Output() attemptEvent = new EventEmitter<void>();
  @Output() nextEvent = new EventEmitter<void>();
  @Output() recordEvent = new EventEmitter<void>();
  @Output() videoError = new EventEmitter<boolean>();

  @ViewChild("timer", { static: false }) private countdown?: CountdownComponent;
  @ViewChild("imageCanvas") imageCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild("videoC") videoC!: ElementRef<HTMLVideoElement>;
  @ViewChild("canvas") canvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild("drawerContent") drawerContent!: TemplateRef<any>;

  public timerConfig?: CountdownConfig;
  public isRecording: boolean = false;
  public downloadUrl?: string;
  public firstRecord: boolean = true;
  public readyToRecord: boolean = false;
  public hasRecorded: boolean = false;
  public blurAmount: number = 0; // Control the amount of blur
  public audioDevices: ISelectOptions[] = [];
  public cameraDevices: ISelectOptions[] = [];
  public showInputOptions: boolean = false;
  public videoLength?: number;

  private pixels!: Uint8ClampedArray;
  private requestId?: number;
  private segmenter: any; // Define segmenter as a class variable for body segmentation
  private userMediaStream?: MediaStream;
  private stream?: MediaStream;
  private mediaRecorder: MediaRecorder | null = null;
  private recordedBlobs?: Blob[] = [];
  private audioStream?: MediaStreamTrack;
  private cameraID?: string;
  private audioID?: string;
  private isBlurMode: boolean = true; // Toggle between blur mode and background image mode

  constructor(private drawerService: DrawerService) {
    this.enumerateDevices();
  }

  ngAfterViewInit(): void {
    this.timerConfig = {
      demand: true,
      leftTime: this.maxLength,
      format: "mm:ss",
    };
    this.startCamera();
  }

  async initSegmenter(): Promise<void> {
    if (!this.segmenter) {
      const model =
        bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation;
      const segmenterConfig: any = {
        runtime: "mediapipe",
        solutionPath:
          "https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation",
        modelType: "general",
      };

      this.segmenter = await bodySegmentation.createSegmenter(
        model,
        segmenterConfig
      );
    }

    if (this.isBlurMode) {
      this.canvas.nativeElement.setAttribute("style", `background-image: none`);
      this.blurBackground();
    } else {
      this.removeBackground();
    }
  }

  async blurBackground(): Promise<void> {
    const foregroundThreshold = 0.5;
    const edgeBlurAmount = 3;
    const flipHorizontal = false;
    const context = this.canvas.nativeElement.getContext("2d", {
      willReadFrequently: true,
    });

    const processFrame = async () => {
      context?.drawImage(this.videoC.nativeElement, 0, 0, 640, 480);

      await bodySegmentation.drawBokehEffect(
        this.canvas.nativeElement,
        this.videoC.nativeElement,
        await this.segmenter.segmentPeople(this.videoC.nativeElement),
        foregroundThreshold,
        this.blurAmount,
        edgeBlurAmount,
        flipHorizontal
      );

      this.stream = this.canvas.nativeElement.captureStream();
      this.requestId = requestAnimationFrame(processFrame);
    };

    this.requestId = requestAnimationFrame(processFrame);
  }

  async removeBackground(): Promise<void> {
    this.canvas.nativeElement.width = 640;
    this.canvas.nativeElement.height = 480;
    const context = this.canvas.nativeElement.getContext("2d", {
      willReadFrequently: true,
    });

    const processFrame = async () => {
      context?.drawImage(this.videoC.nativeElement, 0, 0);

      const segmentation = await this.segmenter.segmentPeople(
        this.videoC.nativeElement
      );
      const foregroundColor = { r: 0, g: 0, b: 0, a: 12 };
      const backgroundColor = { r: 0, g: 0, b: 0, a: 15 };

      const coloredPartImage = await bodySegmentation.toBinaryMask(
        segmentation,
        foregroundColor,
        backgroundColor
      );

      const imageData = context?.getImageData(0, 0, 640, 480);
      const pixels = imageData?.data;

      if (pixels) {
        for (let i = 3; i < pixels.length; i += 4) {
          if (coloredPartImage.data[i] === 15) {
            pixels[i] = 255;
            pixels[i - 1] = this.pixels[i - 1];
            pixels[i - 2] = this.pixels[i - 2];
            pixels[i - 3] = this.pixels[i - 3];
          }
        }
      }

      if (context && imageData) {
        await bodySegmentation.drawBokehEffect(
          this.canvas.nativeElement,
          imageData,
          segmentation,
          0.5,
          0,
          20,
          false
        );
      }

      this.stream = this.canvas.nativeElement.captureStream();
      this.requestId = requestAnimationFrame(processFrame);
    };

    this.requestId = requestAnimationFrame(processFrame);
  }

  async startCamera(): Promise<void> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: 640, height: 480, deviceId: this.cameraID },
        audio: { deviceId: this.audioID },
      });
      this.userMediaStream = stream;

      if (this._form.getVideo().value) {
        this.downloadUrl = window.URL.createObjectURL(
          this._form.getVideo().value
        );
        this.videoC.nativeElement.src = this.downloadUrl;
        this.videoLength = this._form.videoLength;
        this.hasRecorded = true;
        this.readyToRecord = true;
        this.isRecording = false;
      } else {
        this.videoC.nativeElement.srcObject = stream;
        this.videoC.nativeElement.play().then(() => {
          this.initSegmenter();
        });
        this.audioStream = stream.getAudioTracks()[0];
        this.readyToRecord = true;
        this.isRecording = false;
        this.hasRecorded = false;
      }

      if (this.answerMode && this.triggerRecord) {
        this.triggerRecord.subscribe(() => {
          this.startRecording();
        });
      }
    } catch (err) {
      console.error(`An error occurred: ${err}`);
      this.videoError.emit(true);
    }
  }

  startRecording(): void {
    this.recordedBlobs = [];
    try {
      const newStream = new MediaStream();
      if (this.audioStream) {
        newStream.addTrack(this.audioStream);
      }
      if (this.stream) {
        newStream.addTrack(this.stream.getVideoTracks()[0]);
      }
      this.mediaRecorder = new MediaRecorder(newStream);

      if (this.firstRecord) {
        this.recordEvent.emit();
        this.firstRecord = false;
      }
    } catch (err) {
      console.log(err);
    }

    if (this.mediaRecorder) {
      this.mediaRecorder.start();
      this.countdown?.begin();
      this.isRecording = !this.isRecording;
      this.onDataAvailableEvent();
      this.onStopRecordingEvent();
    }
  }

  onDataAvailableEvent(): void {
    if (this.mediaRecorder) {
      this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
          this.recordedBlobs!.push(event.data);
          const file = new File([event.data], `${Date.now()}.mp4`, {
            type: "video/mp4",
          });
          this._form.setVideo(file);
        }
      };
    }
  }

  onStopRecordingEvent(): void {
    if (this.mediaRecorder) {
      this.mediaRecorder.onstop = () => {
        const videoBuffer = new Blob(this.recordedBlobs);
        this.downloadUrl = window.URL.createObjectURL(videoBuffer);
        if (this.videoC.nativeElement) {
          this.videoC.nativeElement.srcObject = null;
          this.videoC.nativeElement.src = this.downloadUrl;
        }
        if (this.answerMode) this.emitAttempt();
        this.hasRecorded = true;
        this.userMediaStream?.getTracks().forEach(function (track: any) {
          track.stop();
        });
      };
    }
  }

  stopRecording(): void {
    this.mediaRecorder?.stop();
    this.generateThumbnail();
    this.isRecording = !this.isRecording;
    this.hasRecorded = true;
    this.countdown?.pause();
    if (this.requestId) {
      cancelAnimationFrame(this.requestId);
    }
  }

  handleCountdownEvent(event: CountdownEvent): void {
    if (event.action === "done") {
      this.stopRecording();
      this.nextEvent.emit();
    }
  }

  toggleOpened(): void {
    const config: IDrawerConfig = {
      template: this.drawerContent,
      header: "Effects",
    };
    this.drawerService.show(config);
  }

  emitAttempt(): void {
    this.attemptEvent.emit();
  }

  emitNext(): void {
    this.nextEvent.emit();
  }

  emitRecord(): void {
    this.recordEvent.emit();
  }

  generateThumbnail(): void {
    let canvas = document.createElement("canvas");
    canvas.width = 1920;
    canvas.height = 1080;

    let ctx = canvas.getContext("2d", { willReadFrequently: true })!;

    ctx.drawImage(this.canvas.nativeElement, 0, 0, canvas.width, canvas.height);

    let image = canvas.toDataURL("image/jpeg").split(",")[1];

    const imageName = `${Date.now()}.jpeg`;
    const imageBlob = this.dataURItoBlob(image);
    const imageFile = new File([imageBlob], imageName, { type: "image/jpeg" });

    this._form.setThumbnail(imageFile);
  }

  playRecording(): void {
    if (!this.recordedBlobs || !this.recordedBlobs.length) {
      return;
    }
    this.videoC.nativeElement!.play();
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/png" });
    return blob;
  }

  noBlur(): void {
    if (!this.isBlurMode) {
      cancelAnimationFrame(this.requestId!);
      this.isBlurMode = true;
      this.initSegmenter();
    }
    this.blurAmount = 0;
  }

  medBlur(): void {
    if (!this.isBlurMode) {
      cancelAnimationFrame(this.requestId!);
      this.isBlurMode = true;
      this.initSegmenter();
    }
    this.blurAmount = 5;
  }

  highBlur(): void {
    if (!this.isBlurMode) {
      cancelAnimationFrame(this.requestId!);
      this.isBlurMode = true;
      this.initSegmenter();
    }
    this.blurAmount = 10;
  }

  setBackground(id: number): void {
    cancelAnimationFrame(this.requestId!);
    this.isBlurMode = false;
    this.canvas.nativeElement.setAttribute(
      "style",
      `background-image: url('../assets/bg${id}.jpg')`
    );
    this.loadImage(id);
  }

  loadImage(id: number): void {
    const img = new Image();
    img.src = `../assets/bg${id}.jpg`;
    img.onload = () => {
      this.drawImageToCanvas(img);
    };
  }

  drawImageToCanvas(img: HTMLImageElement): void {
    const canvas = this.imageCanvas.nativeElement;
    const context = canvas.getContext("2d", { willReadFrequently: true })!;

    if (context) {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);
      const imageData = context.getImageData(0, 0, 640, 480);
      this.pixels = imageData.data;
    }
    this.removeBackground();
  }

  tryAgain() {
    this._form.clear();
    this.startCamera();
  }

  handleTimerEvent(event: CountdownEvent) {
    if (event.action === "pause" || event.action === "done") {
      this.videoLength = this.maxLength - event.left / 1000;
      this._form.videoLength = this.videoLength;
    }
    if (event.action === "done" && this.isRecording) {
      this.stopRecording();
    }
  }

  ngOnDestroy(): void {
    this.userMediaStream?.getTracks().forEach(function (track: any) {
      track.stop();
    });
    cancelAnimationFrame(this.requestId!);
  }

  private enumerateDevices(): void {
    navigator.mediaDevices
      ?.enumerateDevices()
      .then((devices: MediaDeviceInfo[]) => {
        this.audioDevices = devices
          .filter((device: MediaDeviceInfo) => device.kind === "audioinput")
          .map((device: MediaDeviceInfo) => {
            return {
              display: device.label,
              value: device.deviceId,
            };
          });
        this.cameraDevices = devices
          .filter((device: MediaDeviceInfo) => device.kind === "videoinput")
          .map((device: MediaDeviceInfo) => {
            return {
              display: device.label,
              value: device.deviceId,
            };
          });

        this.cameraID = this.cameraDevices[0].value as string;
        this.audioID = this.audioDevices[0].value as string;
        this.showInputOptions = true;
      });
  }

  public handleAudioInputChange(event: ISelectOptions): void {
    if (event.value === this.audioID) return;
    this.audioID = event.value as string;
    if (!this.isRecording && !this.hasRecorded) this.restartCamera();
  }

  public handleCameraInputChange(event: ISelectOptions): void {
    if (event.value === this.cameraID) return;
    this.cameraID = event.value as string;
    if (!this.isRecording && !this.hasRecorded) this.restartCamera();
  }

  restartCamera(): void {
    this.userMediaStream?.getTracks().forEach(function (track: any) {
      track.stop();
    });
    cancelAnimationFrame(this.requestId!);
    this.startCamera();
  }
}
