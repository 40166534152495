<div class="idle-timeout-container" fxLayout="column" 
fxLayoutAlign="space-evenly centre">
  <div class="timer-icon" fxLayout="row" fxLayoutAlign="center">
    <mat-icon style="transform: scale(2);" fxFlexAlign="center">av_timer</mat-icon>
  </div>
  <br />
  <div class="timer-icon" fxLayout="row" fxLayoutAlign="center">
    <div style="align-content: center" mat-dialog-content>
      You are about to be logged out in...
      <h4 class="timer-counter">{{ counter }}</h4>
    </div>
  </div>
  <div class="timer-icon" fxLayout="row" fxLayoutAlign="center">
    <div mat-dialog-actions>
      <button class="tw-btn-negative" mat-dialog-close>Cancel</button>
    </div>
  </div>
  
</div>
<!-- style="width: 400px; height: 200px; padding: 20px;" -->