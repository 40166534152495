<!-- <div fxLayout="row wrap" fxLayoutGap="20px">
  <mat-card
    class="job-detail-card-header"
    style="flex-basis: 47.5%"
    fxLayout="column"
  >
    <h2>
      <span style="font-weight: lighter">Title : </span>
      {{ job.job_title }}
    </h2>

    <h4>
      <span style="font-weight: lighter">Work Experience : </span>
      {{ getExperienceLevel(job) }}
    </h4>

    <h4>
      <span style="font-weight: lighter">Job Type : </span>
      {{ getJobType(job) }}
    </h4>

    <h4>
      <span style="font-weight: lighter">Work Type : </span>
      {{ getWorkType(job) }}
    </h4>
    <div fxLayoutGap="20px">
      <ng-container *ngIf="editButton" [ngTemplateOutlet]="editButton">
      </ng-container>
      <ng-container *ngIf="deleteButton" [ngTemplateOutlet]="deleteButton">
      </ng-container>
      <ng-container *ngIf="applyButton" [ngTemplateOutlet]="applyButton">
        <h4>
          {{ job.published_dt | timeSince }}
        </h4></ng-container
      >
    </div>
  </mat-card>
  <mat-card class="job-detail-card-header" style="flex-basis: 47.5%">
    <img
      class="logo-img"
      [src]="job.company_logo || job.company_logo_url || defaultLogo"
      alt=""
    />
    <h2>
      {{ job.company }}
    </h2>
    <h4 style="max-width: 400px">
      {{ job.company_description }}
    </h4>
    <mat-divider *ngIf="applyButton"></mat-divider>
    <div
      *ngIf="applyButton"
      fxLayout="row"
      fxLayoutAlign="start end"
      fxLayoutGap="10px"
    >
      <img
        style="border-radius: 50%"
        class="logo-img"
        [src]="job.recruiter.profile_picture"
        alt=""
      />
      <h4>{{ job.recruiter.name }} is hiring for this job.</h4>
    </div>
    <button
      [disabled]="!job.company_url"
      mat-raised-button
      (click)="routeToCompany()"
    >
      Visit Company Page
    </button>
  </mat-card>
</div>

<mat-card style="margin-top: 20px">
  <div [innerHtml]="job.description"></div>
</mat-card> -->
<div class="job-detail-container">
  <div
    fxLayoutAlign="space-between"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="16px"
  >
    <div style="width: 100%" fxLayout="column" fxLayoutGap="16px">
      <div class="job-detail-header">
        <img
          class="logo-img"
          [src]="job.company_logo || job.company_logo_url || defaultLogo"
          alt=""
        />
        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px">
          <span class="card-title">{{ job.job_title }}</span>
          <span class="light-subtitle-text">{{ job.company }}</span>
        </div>
        <div
          *ngIf="!hideIcons()"
          class="job-detail-header-icons-container"
          fxLayout="row"
          fxLayoutGap="22px"
        >
          <div
            fxLayout="column"
            fxLayoutAlign="center start"
            fxLayoutGap="10px"
          >
            <div class="job-detail-header-icons">
              <span class="material-icons"> work </span>
              <p class="title">Job Type:</p>
              <span> {{ getJobType(job) }}</span>
            </div>
            <div class="job-detail-header-icons">
              <span class="material-icons"> monetization_on </span>
              <p class="title">Salary:</p>
              <span>$103,000 - $140,000</span>
            </div>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="center start"
            fxLayoutGap="10px"
          >
            <div class="job-detail-header-icons">
              <span class="material-icons"> lan </span>
              <p class="title">Experience Level:</p>
              <span> {{ getExperienceLevel(job) }}</span>
            </div>
            <div class="job-detail-header-icons">
              <span class="material-icons"> diversity_2 </span>
              <p class="title">Work Type:</p>
              <span> {{ getWorkType(job) }}</span>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div
        *ngIf="hideIcons()"
        class="job-detail-header-icons-container"
        fxLayout="row wrap"
        fxLayoutGap="10px"
      >
        <div class="job-detail-header-icons">
          <span class="material-icons"> work </span>
          <p class="title">Job Type:</p>
          <span> {{ getJobType(job) }}</span>
        </div>
        <div class="job-detail-header-icons">
          <span class="material-icons"> monetization_on </span>
          <p class="title">Salary:</p>
          <span>$103,000 - $140,000</span>
        </div>
        <div class="job-detail-header-icons">
          <span class="material-icons"> lan </span>
          <p class="title">Experience Level:</p>
          <span> {{ getExperienceLevel(job) }}</span>
        </div>
        <div class="job-detail-header-icons">
          <span class="material-icons"> diversity_2 </span>
          <p class="title">Work Type:</p>
          <span> {{ getWorkType(job) }}</span>
        </div>
      </div>
      <mat-divider *ngIf="hideIcons()"></mat-divider>
      <div [innerHtml]="job.description"></div>
      <mat-divider></mat-divider>
      <div *ngIf="hideSidebar()" class="job-detail-sidebar">
        <div
          [class.expired-days-left-container]="isJobExpired"
          class="days-left-container"
        >
          <span class="material-icons-outlined"> error_outline </span>
          <span>{{ daysToApplyOrExpired }}</span>
        </div>

        <ng-container *ngTemplateOutlet="jobSummary"></ng-container>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start">
        <span class="light-subtitle-text">
          Posted {{ job.published_dt | timeSince }}</span
        >
      </div>
    </div>

    <div *ngIf="!hideSidebar()" class="job-detail-sidebar">
      <div
        [class.expired-days-left-container]="isJobExpired"
        class="days-left-container"
      >
        <span class="material-icons-outlined"> error_outline </span>
        <span>{{ daysToApplyOrExpired }}</span>
      </div>
      <ng-container *ngTemplateOutlet="jobSummary"></ng-container>
    </div>
  </div>
</div>

<ng-template #jobSummary>
  <div class="job-detail-sidebar-summary-container">
    <div class="job-detail-sidebar-summary">
      <span class="card-title">Job Summary</span>
      <ul>
        <li>Job Posted: {{ job.published_dt | date }}.</li>
        <li>Expiration: {{ expiryDate | date }}</li>
        <!-- <li>Education: Bachelor Degree.</li> -->
      </ul>

      <button (click)="apply()" mat-button class="btn-md tw-btn-primary w-full justify-center text-nowrap">
        Apply Now
      </button>
      <!-- <button mat-button class="outline-btn max-width-imp">
        <mat-icon>favorite_border</mat-icon><span> Save</span>
      </button> -->
    </div>
  </div>
</ng-template>

<ng-template #jobDetailHeaderIconsContainer>
  <div
    class="job-detail-header-icons-container"
    fxLayout="row"
    fxLayoutGap="22px"
  >
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
      <div class="job-detail-header-icons">
        <span class="material-icons"> work </span>
        <p class="title">Job Type:</p>
        <span> {{ getJobType(job) }}</span>
      </div>
      <div class="job-detail-header-icons">
        <span class="material-icons"> monetization_on </span>
        <p class="title">Salary:</p>
        <span>$103,000 - $140,000</span>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
      <div class="job-detail-header-icons">
        <span class="material-icons"> lan </span>
        <p class="title">Experience Level:</p>
        <span> {{ getExperienceLevel(job) }}</span>
      </div>
      <div class="job-detail-header-icons">
        <span class="material-icons"> diversity_2 </span>
        <p class="title">Work Type:</p>
        <span> {{ getWorkType(job) }}</span>
      </div>
    </div>
  </div>
</ng-template>
