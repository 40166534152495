import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription, fromEvent } from "rxjs";
import { CandidateService } from "src/app/candidate/services/user-service/candidate-user.service";
import { CandidateFeatures } from "src/app/candidate/utils/features.utils";
import { LoginPayload } from "src/app/core/models/authentication-payloads/login-payload.model";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { MessagingService } from "src/app/core/services/messaging/messaging.service";
import { RecruiterService } from "src/app/recruiter/services/user-service/recruiter-user.service";
import { UserNotificationsService } from "src/app/core/services/user-notification/user-notifications.service";
import { RecruiterFeatures } from "src/app/recruiter/utils/features.utils";
import { ROUTER_UTILS } from "src/app/core/utils/router.utils";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService } from "src/app/shared/services/dialog/dialog.service";
import { handleScreenResize } from "src/app/shared/utils/side-nav-resize.util";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() public sidenavToggle = new EventEmitter();

  profilePicture: string = "";

  loader = {
    pfpLoaded: false,
  };

  private subscriptions: Subscription[] = [];
  isMobileWidth?: boolean;
  resizeObservable$: any;

  candidateFeatureBtns = CandidateFeatures;
  recruiterFeatureBtns = RecruiterFeatures;

  role?: string;
  isPublicRoute: boolean = false;
  notificationNum;
  badgehidden = true;
  showLoginButton = false;
  constructor(
    public authService: AuthService,
    private messagingService: MessagingService,
    private candidateService: CandidateService,
    private recruiterService: RecruiterService,
    private dialogService: DialogService,
    private notificationsService: UserNotificationsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.role = this.authService.getUserRole();
        this.isMobileWidth = handleScreenResize().isMobileWidth;
        this.resizeObservable$ = fromEvent(window, "resize");
        this.subscriptions.push(
          this.resizeObservable$.subscribe(() => {
            let conditions = handleScreenResize();
            this.isMobileWidth = conditions.isMobileWidth;
          })
        );
      } else {
        this.showLoginButton = true;
      }
    });

    this.messagingService.emitLoader(true);
    this.subscriptions.push(
      this.messagingService.getProfilePicture().subscribe((pp) => {
        if (pp) {
          this.profilePicture = pp;
          this.loader.pfpLoaded = true;

          this.messagingService.emitLoader(false);
        } else {
          this.authService.isAuthenticated$.subscribe((isLoggedIn) => {
            if (isLoggedIn) {
              this.role = this.authService.getUserRole();
              if (this.role == "candidate") {
                this.candidateService.getProfile().subscribe((res) => {
                  this.messagingService.emitCandidateProfile(res);
                  this.messagingService.emitProfilePicture(res.profile_picture);

                  this.messagingService.emitLoader(false);
                });
              } else if (this.role == "recruiter") {
                this.recruiterService.getProfile().subscribe((res) => {
                  this.messagingService.emitRecruiterProfile(res);
                  this.messagingService.emitProfilePicture(res.profile_picture);

                  this.messagingService.emitLoader(false);
                });
              }
            }
          });
        }
      })
    );

    if (this.authService.loggedIn) {
      this.getNotificationsBasedOnRole();
    }

    this.isPublicRoute = this.router.url.includes("/public/playlist");
  }

  getNotificationsBasedOnRole(): void {
    this.role = this.authService.getUserRole();
    if (this.role == "candidate") {
      this.notificationsService.getCandidateNotifications().subscribe((res) => {
        this.notificationNum = res.unseen_count;
        if (this.notificationNum > 0) {
          this.badgehidden = false;
        }
      });
    } else if (this.role == "recruiter") {
      this.notificationsService.getRecruiterNotifications().subscribe((res) => {
        this.notificationNum = res.unseen_count;
        if (this.notificationNum > 0) {
          this.badgehidden = false;
        }
      });
    }
    this.notificationsService.unseenCount$.subscribe((res) => {
      this.notificationNum = res;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

  candidateLogin() {
    this.authService.candidateLogin();
  }
  recruiterLogin() {
    this.authService.recruiterLogin();
  }

  logout() {
    this.authService.logout();
  }

  toggleMenu() {
    this.dialogService
      .openFullScreenMenu(
        this.role === "candidate"
          ? this.candidateFeatureBtns
          : this.recruiterFeatureBtns
      )
      .subscribe((res) => {});
  }
  prodStatus() {
    if (environment.name != "beta") {
      return true;
    }
    return false;
  }

  screenResize() {
    this.isMobileWidth = handleScreenResize().isMobileWidth;
  }

  goToHelp() {
    if (this.role === "candidate") {
      this.router.navigate([
        ROUTER_UTILS.candidate.root,
        ROUTER_UTILS.candidate.dashboard.help.root,
      ]);
    } else {
      this.router.navigate([
        ROUTER_UTILS.recruiter.root,
        ROUTER_UTILS.recruiter.dashboard.help.root,
      ]);
    }
  }

  getNotifications() {
    if (this.role === "candidate") {
      this.router.navigate([
        ROUTER_UTILS.candidate.root,
        ROUTER_UTILS.candidate.dashboard.candidateNotification.root,
      ]);
    } else {
      this.router.navigate([
        ROUTER_UTILS.recruiter.root,
        ROUTER_UTILS.recruiter.dashboard.recruiterNotification.root,
      ]);
    }
    this.ngOnInit();
  }

  goHome() {
    if (this.role === "candidate") {
      this.router.navigate([
        ROUTER_UTILS.candidate.root,
        ROUTER_UTILS.candidate.dashboard.home.root,
      ]);
    } else {
      this.router.navigate([
        ROUTER_UTILS.recruiter.root,
        ROUTER_UTILS.recruiter.dashboard.home.root,
      ]);
    }
  }

  login() {
    this.router.navigate([ROUTER_UTILS.portfolio.root], {
      queryParams: { redirectUrl: this.router.url },
    });
  }
}
