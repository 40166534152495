import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CandidateProfileForm } from "src/app/candidate/dashboard/pages/profile/form/candidate-profile-form";
import { RecruiterProfileForm } from "src/app/recruiter/dashboard/pages/profile/form/recruiter-profile-form";

@Component({
  selector: "app-personal-info-form",
  templateUrl: "./personal-info-form.component.html",
  styleUrls: ["./personal-info-form.component.scss"],
})
export class PersonalInfoFormComponent implements OnInit {
  @Output() submit = new EventEmitter();

  loading = true;

  ngOnInit(): void {
    this.loading = false;
  }
  @Input() form!: CandidateProfileForm | RecruiterProfileForm;

  save() {
    this.submit.emit();
  }

  get firstName() {
    return this.form.getFirstName();
  }

  get lastName() {
    return this.form.getlastName();
  }

  get phone() {
    return this.form.getPhone();
  }
}
