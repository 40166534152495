import { Component } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { MessagingService } from "../../services/messaging/messaging.service";

@Component({
  selector: "app-candidate-callback",
  templateUrl: "./candidate-callback.component.html",
  styleUrls: ["./candidate-callback.component.scss"],
})
export class CandidateCallbackComponent {
  constructor(
    private authService: AuthService,
    private messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    this.authService.handleCandidateAuthCallback();
  }
}
