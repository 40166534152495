import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  ɵunwrapSafeValue as unwrapSafeUrl,
} from "@angular/core";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { VideoForm } from "src/app/candidate/dashboard/pages/videos/forms/video-input-form";
import { MessagingService } from "src/app/core/services/messaging/messaging.service";
import { VideoService } from "src/app/candidate/services/video/video.service";
import { DialogService } from "src/app/shared/services/dialog/dialog.service";
import { NotificationService } from "src/app/core/services/notification/notification.service";
import { Question } from "src/app/core/models/question.model";
import {
  CountdownComponent,
  CountdownConfig,
  CountdownEvent,
} from "ngx-countdown";
import { Subject } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTER_UTILS } from "src/app/core/utils/router.utils";
import { Platform } from "@angular/cdk/platform";
import { decodeParam, encodeParam } from "src/app/core/utils/paramEncoder";
@Component({
  selector: "app-create-video-form",
  templateUrl: "./create-video-form.component.html",
  styleUrls: ["./create-video-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateVideoFormComponent implements OnInit, AfterViewInit {
  @Output() closeEvent = new EventEmitter();
  @Output() videosUpdated = new EventEmitter();
  @Output() uploadAnswerEvent = new EventEmitter();
  @Input() answerMode = false;
  @Input() question?: Question;
  @ViewChild("timer", { static: false }) private countdown?: CountdownComponent;
  @ViewChild("videoRecording") videoRecTemplate!: TemplateRef<any>;
  @ViewChild("videoDetails") videoInfoTemplate!: TemplateRef<any>;
  @ViewChild("videoUpload") videoUploadTemplate!: TemplateRef<any>;

  triggerRecordObservable: Subject<void> = new Subject<void>();

  attempts = 1;
  disableAttempts = false;

  step = 0;

  showPrepTime = true;
  tagList?: AbstractControl;
  videoThumb?: string;
  videoSrc?: string;
  submitted = false;
  uploading = false;
  videoRecorderError = false;
  routeFromApp: boolean = false;
  appId: string = "";

  constructor(
    public videoForm: VideoForm,
    private videoService: VideoService,
    private messagingService: MessagingService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private sanitize: DomSanitizer,
    private router: Router,
    private platformService: Platform,
    private activatedRoute: ActivatedRoute
  ) {
    this.tagList = this.videoForm.getTags();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.routeFromApp = params["routeFrom"] ? true : false;
      if (params["routeFrom"]) this.appId = decodeParam(params["routeFrom"]);
    });
  }
  ngAfterViewInit(): void {
    this.countdown?.begin();
  }

  ngOnInit(): void {
    if (this.answerMode) {
      this.timerConfig = {
        demand: true,
        leftTime: this.question!.prep_time * 60,
        format: "mm:ss",
      };
    }
  }

  timerConfig!: CountdownConfig;

  handleTimerEvent(event: CountdownEvent) {
    if (event.action === "done") {
      this.showPrepTime = false;
      this.triggerRecordObservable.next();
    }
  }

  makeMetaDataPayload() {
    let payload = {
      intro: this.videoForm.getIsIntro().value,
      public: this.videoForm.getIsPublic().value,
      description: this.videoForm.getDescription().value,
      title: this.videoForm.getVideoTitle().value,
      tags: this.videoForm.getTags().value,
    };
    return payload;
  }

  routeToVideos() {
    this.router.navigate([
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.videos.root,
    ]);
  }

  videoRecorder(err: boolean) {
    this.videoRecorderError = err;
  }
  onSubmit() {
    if (this.answerMode) {
      this.uploadAnswerEvent.emit();
    } else {
      this.uploading = true;
      this.messagingService.emitLoader(true);

      this.videoService
        .postVideoMetaData(this.makeMetaDataPayload())
        .subscribe({
          next: (res) => {
            this.videoService.postVideo(this.videoForm, res.id).subscribe({
              next: () => {
                this.notificationService.showNotification("Video Uploaded!");
                this.uploading = false;
                if (this.routeFromApp) {
                  this.router.navigate(
                    [
                      ROUTER_UTILS.candidate.root,
                      ROUTER_UTILS.candidate.dashboard.jobs.root,
                      ROUTER_UTILS.candidate.dashboard.jobs.jobApply,
                    ],
                    {
                      queryParams: {
                        id: encodeParam(this.appId as string),
                      },
                    }
                  );
                } else {
                  this.close();
                }
              },
              error: () => {
                this.notificationService.showNotification(
                  "Error occured, please try again."
                );
                this.uploading = false;
              },
            });
          },
          error: () => {
            this.notificationService.showNotification(
              "Error occured, please try again."
            );
            this.messagingService.emitLoader(false);
            this.uploading = false;
            this.close();
          },
        });
    }
  }

  close() {
    this.router.navigate([
      ROUTER_UTILS.candidate.root,
      ROUTER_UTILS.candidate.dashboard.videos.root,
    ]);
    this.videoForm.clear();
  }

  attemptEventHandler() {
    if (this.answerMode) {
      this.attempts++;
      if (this.attempts == this.question!.num_tries_max + 1) {
        this.attempts--;
        this.disableAttempts = true;
      }
    } else if (this.platformService.SAFARI && this.step == 0) {
      this.videoForm.getVideo().valueChanges.subscribe((res) => {
        this.videoThumb = unwrapSafeUrl(
          this.sanitize.bypassSecurityTrustUrl(
            URL.createObjectURL(this.videoForm.getThumbnail().value)
          )
        );

        this.videoSrc = this.sanitize.bypassSecurityTrustUrl(
          URL.createObjectURL(this.videoForm.getVideo().value)
        ) as string;
        this.step++;
      });
    }
  }

  recordEventHandler() {
    this.showPrepTime = false;
  }

  getStepTemplateRef(): TemplateRef<any> {
    if (this.step === 0) return this.videoRecTemplate;
    else if (this.step === 1) return this.videoInfoTemplate;
    else return this.videoUploadTemplate;
  }

  next() {
    if (this.step == 1) {
      if (this.videoForm.valid) {
        this.step++;
      } else {
        this.submitted = true;
        return;
      }
    }
    if (this.step < 2) {
      this.step++;
    }
    if (this.step === 1) {
      this.videoThumb = unwrapSafeUrl(
        this.sanitize.bypassSecurityTrustUrl(
          URL.createObjectURL(this.videoForm.getThumbnail().value)
        )
      );

      this.videoSrc = this.sanitize.bypassSecurityTrustUrl(
        URL.createObjectURL(this.videoForm.getVideo().value)
      ) as string;
    }
  }

  prev() {
    if (this.step > 0) {
      this.step--;
    }
  }

  playVideo() {
    this.dialogService.openVideoDialog(
      this.sanitize.bypassSecurityTrustUrl(
        URL.createObjectURL(this.videoForm.getVideo().value)
      ) as string
    );
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    const sizeInMB = Number((file.size / (1024 * 1024)).toFixed(2));
    if (sizeInMB > 2) {
      this.notificationService.showErrorNotification(
        "Only files less than 2MBs are supported."
      );
      return;
    }
    let self = this;
    if (file) {
      let img = new Image();
      var objectUrl = URL.createObjectURL(file);
      img.onload = function () {
        const imageName = `${Date.now()}.jpeg`;

        self.videoForm.setThumbnail(file);
        self.videoThumb = unwrapSafeUrl(
          self.sanitize.bypassSecurityTrustUrl(
            URL.createObjectURL(self.videoForm.getThumbnail().value)
          )
        );
        URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
    }
  }

  getAspectRatio(a, b) {
    return b == 0 ? a : this.getAspectRatio(b, a % b);
  }
}
