import { BehaviorSubject } from "rxjs";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { APP_ID, Inject, NgModule, PLATFORM_ID } from "@angular/core";
import { BrowserModule, TransferState } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { isPlatformBrowser } from "@angular/common";

import { AppComponent } from "./app.component";

import { AuthService } from "./core/services/auth/auth.service";
import { translateBrowserLoaderFactory } from "./core/i18n/translate-browser.loader";

import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { ShellModule } from "./shell/shell.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularMaterialModule } from "./angular-material.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "workstory.io" }),
    AppRoutingModule,
    CoreModule,
    SharedModule,
    ShellModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
  ],
  providers: [AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {
  static isBrowser = new BehaviorSubject<boolean | null>(null);
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string
  ) {
    const platform = isPlatformBrowser(platformId)
      ? "in the browser"
      : "on the server";
    console.debug(`Running ${platform} with appId=${appId}`);
    AppModule.isBrowser.next(isPlatformBrowser(platformId));
  }
}
