<div class="autocomplete-container">
  <input
    #inputField
    class="autocomplete-input"
    [class.test]="cityError"
    [placeholder]="placeholder"
    [formControl]="control"
    (click)="retrieveLocation()"
    type="text"
  />
</div>
