export const ApplicationStatusKeys = {
  Active: "A",
  Shortlisted: "S",
  Archived: "U",
};

export const applicationStatusMap = new Map([
  [ApplicationStatusKeys.Active, "Active"],
  [ApplicationStatusKeys.Shortlisted, "Shortlisted"],
  [ApplicationStatusKeys.Archived, "Archived"],
]);

export const ContactStatusKeys = {
  Contacted: "C",
  Replied: "R",
  Active: "A",
  Pending: "P",
  Dead: "D",
};
