import { Injectable } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";

const urlValidator: ValidatorFn = (control: AbstractControl) => {
  let validUrl = true;
  if (
    control.value === "" ||
    control.value === null ||
    control.value === undefined
  ) {
    return null;
  }
  try {
    new URL(control.value);
  } catch {
    validUrl = false;
  }

  return validUrl ? null : { invalidUrl: true };
};

const form = {
  firstName: new UntypedFormControl(
    "",
    Validators.compose([
      Validators.required,
      Validators.pattern(/^[A-Za-z\s]*$/),
    ])
  ),
  name: new UntypedFormControl(""),
  lastName: new UntypedFormControl(
    "",
    Validators.compose([
      Validators.required,
      Validators.pattern(/^[A-Za-z\s]*$/),
    ])
  ),
  email: new UntypedFormControl({ value: "", disabled: true }),
  phone: new UntypedFormControl(
    "",
    Validators.compose([
      Validators.minLength(5),
      Validators.pattern("[- +()0-9]+"),
    ])
  ),
  profilePicture: new UntypedFormControl(""),
  experience: new UntypedFormControl(
    "",
    Validators.compose([Validators.max(50)])
  ),
  industry: new UntypedFormControl("", Validators.compose([])),
  languages: new UntypedFormControl("", Validators.compose([])),
  profileSummary: new UntypedFormControl("", Validators.compose([])),
  linkedInUrl: new UntypedFormControl("", Validators.compose([urlValidator])),
};

@Injectable()
export class CandidateProfileForm extends UntypedFormGroup {
  constructor() {
    super(form);
  }

  getFirstName() {
    return this.controls["firstName"];
  }

  setFirstName(val: string) {
    this.controls["firstName"].setValue(val);
  }

  getProfileSummary() {
    return this.controls["profileSummary"];
  }

  setProfileSummary(val: string) {
    this.controls["profileSummary"].setValue(val);
  }

  getLinkedInUrl() {
    return this.controls["linkedInUrl"];
  }

  setLinkedInUrl(val: string) {
    this.controls["linkedInUrl"].setValue(val);
  }

  getName() {
    return this.controls["name"];
  }

  setName(val: string) {
    this.controls["name"].setValue(val);
  }

  getlastName() {
    return this.controls["lastName"];
  }

  setlastName(val: string) {
    this.controls["lastName"].setValue(val);
  }

  getEmail() {
    return this.controls["email"];
  }

  setEmail(val: string) {
    this.controls["email"].setValue(val);
  }

  getPhone() {
    return this.controls["phone"];
  }

  setPhone(val: string) {
    this.controls["phone"].setValue(val);
  }

  getProfilePicture() {
    return this.controls["profilePicture"];
  }

  setProfilePicture(val: Blob) {
    this.controls["profilePicture"].setValue(val);
  }

  getIndustry() {
    return this.controls["industry"];
  }

  setIndustry(val: string) {
    this.controls["industry"].setValue(val);
  }

  getExperience() {
    return this.controls["experience"];
  }

  setExperience(val: string) {
    this.controls["experience"].setValue(val);
  }

  getLanguages() {
    return this.controls["languages"];
  }

  setLanguages(val: string) {
    this.controls["languages"].setValue(val);
  }
}
