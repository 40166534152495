import { Injectable } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

const form = {
  firstName: new UntypedFormControl(
    "",
    Validators.compose([
      Validators.required,
      Validators.pattern(/^[A-Za-z\s]*$/),
    ])
  ),
  name: new UntypedFormControl(""),
  lastName: new UntypedFormControl(
    "",
    Validators.compose([
      Validators.required,
      Validators.pattern(/^[A-Za-z\s]*$/),
    ])
  ),
  email: new UntypedFormControl({ value: "", disabled: true }),
  phone: new UntypedFormControl(
    "",
    Validators.compose([
      Validators.minLength(5),
      Validators.pattern("[- +()0-9]+"),
    ])
  ),
  profilePicture: new UntypedFormControl(""),
  companyName: new UntypedFormControl(""),
  companyDescription: new UntypedFormControl(""),
};

@Injectable()
export class RecruiterProfileForm extends UntypedFormGroup {
  constructor() {
    super(form);
  }

  getFirstName() {
    return this.controls["firstName"];
  }

  setFirstName(val: string) {
    this.controls["firstName"].setValue(val);
  }

  getName() {
    return this.controls["name"];
  }

  setName(val: string) {
    this.controls["name"].setValue(val);
  }

  getlastName() {
    return this.controls["lastName"];
  }

  setlastName(val: string) {
    this.controls["lastName"].setValue(val);
  }

  getCompany() {
    return this.controls["companyName"];
  }

  setCompany(val: string) {
    this.controls["companyName"].setValue(val);
  }

  getCompanyDesc() {
    return this.controls["companyDescription"];
  }

  setCompanyDesc(val: string) {
    this.controls["companyDescription"].setValue(val);
  }

  getEmail() {
    return this.controls["email"];
  }

  setEmail(val: string) {
    this.controls["email"].setValue(val);
  }

  getPhone() {
    return this.controls["phone"];
  }

  setPhone(val: string) {
    this.controls["phone"].setValue(val);
  }

  getProfilePicture() {
    return this.controls["profilePicture"];
  }

  setProfilePicture(val: Blob) {
    this.controls["profilePicture"].setValue(val);
  }
}
