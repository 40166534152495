import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { TooltipPosition } from "@angular/material/tooltip";
import {
  LocationAutocompleteComponent,
  location,
} from "../location-autocomplete/location-autocomplete.component";

@Component({
  selector: "app-outline-input-box",
  templateUrl: "./outline-input-box.component.html",
  styleUrls: ["./outline-input-box.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OutlineInputBoxComponent {
  @Input() largeText = false;
  @Input() location = false;
  @Input() whiteBackground = true;
  @Input() submitted = false;
  @Input() infoText;
  @Input() title = "";
  @Input() hint = "";
  @Input() placeholder = "";
  @Input() tooltipText = "";
  @Input() tooltipPostion: TooltipPosition = "right";
  @Input() optional = "";
  @Input() type = "text";
  @Input() hasIcon = false;
  @Input() control: UntypedFormControl = new UntypedFormControl("");
  @Output() locationOutput = new EventEmitter<string>();

  retrieveLocation(location: location) {
    this.locationOutput.emit(location.oneline);
  }
}
