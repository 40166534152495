<div
  matBadge="1"
  matBadgeSize="small"
  matBadgeColor="warn"
  [matBadgeHidden]="
    isCandidate || !(!answer?.is_seen && answer?.status === 'R')
  "
  [class.new]="!isCandidate && !answer?.is_seen && answer?.status === 'R'"
  class="answer-card"
  fxLayout="column"
  fxLayoutAlign="space-between"
>
  <div class="answer-card-header">
    {{ answer?.question }}
  </div>
  <div>
    <mat-divider></mat-divider>
    <div
      *ngIf="answer?.status === 'R'; else unasweredState"
      class="answer-card-bottom-row"
      fxLayout="row"
      fxLayoutGap="24px"
      fxLayoutAlign="space-evenly"
    >
      <!-- <div class="icon-sub-text">
        <span class="material-icons-outlined"> history </span>
        {{ (answer?.response)!.attempts }}
        {{ (answer?.response)!.attempts > 1 ? "attempts" : "attempt" }}
      </div>
      <div *ngIf="false" class="icon-sub-text">
        <span class="material-icons-outlined"> schedule </span>
        01:12 minutes
      </div> -->
      <div class="sub-text">
        <a (click)="viewEvent.emit(answer)"> View Answer</a>
      </div>
    </div>
    <ng-template #unasweredState>
      <div
        *ngIf="answer?.status === 'P'"
        fxLayout="row"
        fxLayoutAlign="space-between"
        style="padding-top: 10px"
      >
        <span style="margin-left: unset !important">Pending</span>
        <span>{{ answer?.status_dt | date }}</span>
      </div>
      <div
        *ngIf="answer?.status === 'A'"
        fxLayout="row"
        fxLayoutAlign="center"
        style="padding-top: 10px"
      >
        <span class="error-text" style="text-align: center; font-size: smaller"
          >Candidate was not able to answer the question within the set
          constraints</span
        >
      </div>
    </ng-template>
  </div>
</div>
