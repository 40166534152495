<div class="app-navbar">
  <mat-toolbar class="upper-nav" color="white">
    <img class="logo" src="../../../../assets/img/workstory.io.png" />
    <div
      matBadge="beta"
      *ngIf="!prodStatus()"
      matBadgeOverlap="false"
      class="beta-badge"
    ></div>

    <div
      *ngIf="authService.isAuthenticated$ | async; else publicCTA"
      fxFlex
      fxLayoutAlign="end"
    >
      <div fxLayout fxLayoutGap="10px" class="navigation-items">
        <button *ngIf="isPublicRoute" (click)="goHome()" class="cta">
          Go home
        </button>
        <button mat-icon-button class="navbar-icon" (click)="goToHelp()">
          <mat-icon class="icon-a">help</mat-icon>
        </button>
        <button
          mat-icon-button
          class="navbar-icon"
          (click)="getNotifications()"
        >
          <mat-icon
            matBadge="{{ notificationNum }}"
            [matBadgeHidden]="badgehidden"
            matBadgeColor="warn"
            matBadgeSize="small"
            class="icon-a"
            >notifications</mat-icon
          >
        </button>

        <!-- button with image -->
        <button
          *ngIf="!isMobileWidth && loader.pfpLoaded"
          class="user-icon"
          [style.background-image]="'url(' + profilePicture + ')'"
          mat-mini-fab
          [matMenuTriggerFor]="menu"
        ></button>
        <button
          style="border-radius: 5px"
          color="white"
          *ngIf="isMobileWidth"
          mat-icon-button
          color="white"
          aria-label="Example icon button with a home icon"
          (click)="toggleMenu()"
        >
          <mat-icon>menu</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button (click)="logout()" mat-menu-item>
            <mat-icon>logout</mat-icon>
            <span translate>logout</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <ng-template #publicCTA>
      <div fxFlex fxLayoutAlign="end">
        <div fxLayout fxLayoutGap="10px" class="navigation-items">
          <button
            *ngIf="isPublicRoute && showLoginButton"
            (click)="login()"
            class="cta"
          >
            Login
          </button>
        </div>
      </div>
    </ng-template>
  </mat-toolbar>
</div>
