import { TranslateService } from "@ngx-translate/core";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { MatFabMenu } from "@angular-material-extensions/fab-menu";

import { AuthService } from "./core/services/auth/auth.service";
import { IdleTimeoutService } from "./core/services/idle-timeout/idle-timeout.service";
import { DialogService } from "./shared/services/dialog/dialog.service";
import { LocalstorageService } from "./core/services/local-storage/local-storage.service";

import { CountryFabButtons } from "./core/i18n/button-config";
import { STORAGE_ITEM } from "./core/utils/local-storage.utils";
import { SeoService } from "./core/services/seo/seo.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public loading?: boolean;
  public languageSelectorOptions = CountryFabButtons as MatFabMenu[];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private dialogService: DialogService,
    private idleTimeoutService: IdleTimeoutService,
    private authService: AuthService,
    private translateService: TranslateService,
    private localStorage: LocalstorageService,
    private seoService: SeoService
  ) {
    this.seoService.setDefaultTags();
  }

  ngOnInit(): void {
    this.intializeIdleTimeoutSettings();
    this.authService.localAuthSetup();
    // this.checkLanguageCookie();
  }

  private intializeIdleTimeoutSettings() {
    this.idleTimeoutService.startWatching().subscribe((isTimeOut: boolean) => {
      if (isTimeOut && this.authService.loggedIn) {
        this.dialogService.openIdleTimeOutDialog();
      }
    });
  }

  switchLanguage(lang: string | number) {
    this.translateService.use(lang as string);
    this.localStorage.setItem(STORAGE_ITEM.langauge, lang as string);
  }

  private checkLanguageCookie() {
    let lang = this.localStorage.getItem(STORAGE_ITEM.langauge);
    if ((lang as string) != "null") {
      this.translateService.use(lang as string);
    } else {
      this.localStorage.setItem(STORAGE_ITEM.langauge, "en");
      this.translateService.use("en");
    }
  }
}
