import { HttpParams } from "@angular/common/http";
import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  debounceTime,
  distinctUntilChanged,
  Subscription,
  switchMap,
} from "rxjs";
import { VideoService } from "src/app/candidate/services/video/video.service";
import { VideoPayload } from "src/app/core/models/video-api-payloads/video.model";

@Component({
  selector: "app-add-video-to-playlist",
  templateUrl: "./add-video-to-playlist.component.html",
  styleUrls: ["./add-video-to-playlist.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddVideoToPlaylistComponent implements OnInit {
  searchForm = new FormGroup({
    search: new FormControl(""),
  });
  uploading = false;
  progress?: number;
  videos: VideoPayload[] = [];
  selectedVideos: VideoPayload[] = [];
  jobId?: string;
  next?: string;
  loading = true;

  constructor(
    private videoService: VideoService,
    public dialogRef: MatDialogRef<AddVideoToPlaylistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { videos: VideoPayload[]; id: string }
  ) {}

  ngOnInit(): void {
    this.getVideos();
    this.search();
    this.selectedVideos = this.data.videos.map((v) => v);
  }

  getVideos() {
    this.loading = true;
    this.videoService.getVideos().subscribe((res) => {
      this.videos = res.results;
      this.next = res.next;
      this.loading = false;
    });
  }

  getMoreVideos() {
    if (this.next) {
      this.videoService.getPaginatedVideos(this.next).subscribe((res) => {
        this.videos = this.videos.concat(res.results);
        this.next = res.next;
      });
    }
  }

  selectVideo(video: VideoPayload) {
    if (this.selectedVideos.find((vid) => vid.id == video.id)) {
      this.selectedVideos = this.selectedVideos.filter(
        (vid) => vid.id != video.id
      );
    } else {
      this.selectedVideos.push(video);
    }
  }

  search() {
    this.searchForm
      .get("search")
      ?.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchTerm) => {
          return this.videoService.searchVideos(searchTerm as string);
        })
      )
      .subscribe((res) => {
        this.videos = res.results;
        this.next = res.next;
      });
  }

  save() {
    let videos = this.selectedVideos.map((v) => v.id);
    let payload = {
      playlistId: this.data.id,
      videos: videos,
    };

    this.videoService.addVideosToPlaylist(payload).subscribe((res) => {
      this.dialogRef.close(true);
    });
  }
}
